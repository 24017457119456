import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentInfo } from '@core/models/shares/modal.model';
// import { IOrderDetail } from '@core/models/stockist/order.model';
import { StockistPurchaseOrderPromotionDetail } from '@core/models/stockist/stockist.model';
import { StockistService } from '@core/services/stockist/purchase.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-order-processed-confirmed-new',
    templateUrl: './order-processed-confirmed-new.component.html',
    styleUrls: ['./order-processed-confirmed-new.component.scss'],
})
export class OrderProcessedConfirmedNewComponent implements OnInit {
    orderId = '';
    data!: StockistPurchaseOrderPromotionDetail;

    orderIsHavePromotionGift = false;

    constructor(
        private route: Router,
        private stockistService: StockistService,
        private activateRoute: ActivatedRoute,
        private translate: TranslateService
    ) { this.translate.setDefaultLang('th'); }

    ngOnInit(){
        this.orderId =
            this.activateRoute.snapshot.paramMap.get('order-number') ?? '';
        this.getOrderDetail();
    }

    navigateToLanding() {
        this.route.navigate(['/stockist']);
    }
    navigateToOrderInfoConfirmed() {
        this.route.navigate([`stockist/order/${this.orderId}`]);
    }

    sumTotalQuantity(): number {
        let totalQuantity = 0;
        if (this.data) {
            for (const data of this.data.items) {
                totalQuantity += data.quantity;
            }
        }
        return totalQuantity;
    }

    getOrderDetail() {
        this.stockistService.getPurchaseOrderV2(this.orderId).subscribe({
            next: (res) => {
                this.data = res;
            },
        });
    }

    getPaymentInfo(): PaymentInfo {
        return <PaymentInfo>{
            totalQuantity: this.getTotalQuantity(),
            totalAmount: this.getTotalAmount(),
            couponDiscount: this.data.couponDiscount,
            promotionDiscount: this.data.promotionDiscount,
            shippingCost: this.data?.shippingCost,
            netAmount: this.getNetAmount(),
        };
    }

    getNetAmount(): number {
        if (!this.data) return 0
        const netAmount = this.data.netAmount;
        return netAmount

    }

    getTotalAmount(): number {
        if (this.data && this.data.items) {
            const totalQuantity = this.data.items.reduce(
                (total, item) => total + item.amount,
                0
            );

            return totalQuantity;
        }

        return 0;
    }

    getTotalQuantity(): number {
        if (this.data && this.data.items) {
            const totalQuantity = this.data.items.reduce(
                (total, item) => total + item.quantity,
                0
            );

            return totalQuantity;
        }

        return 0;
    }

    formatTime(officeTime: string): string {
      if (officeTime) {
          const [startTime, endTime] = officeTime.split("-");
          const formattedStartTime = startTime.split(" ")[1].substring(0, 5);
          const formattedEndTime = endTime.split(" ")[1].substring(0, 5);
          return `${formattedStartTime} - ${formattedEndTime} น.`;
      }
      return '';

  }

  handleImageError(event: Event) {
    const target = event.target as HTMLImageElement;
    target.src = 'assets/images/image_error.jpg';
}
}
