import { Component, Input } from '@angular/core';
import { MonthlySummaryProductParams } from '@core/models/stockist/monthly-summary.model';
import { MonthlyPurchasePromotionResponse } from '@core/models/stockist/report.model';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Component({
  selector: 'app-purchase-promotion',
  templateUrl: './purchase-promotion.component.html',
  styleUrls: ['./purchase-promotion.component.scss']
})
export class PurchasePromotionComponent {
  @Input() monthlyPurchasePromotion?: MonthlyPurchasePromotionResponse[];

  constructor(
    private translate: TranslateService,
  ) { }

  sortingOptions = [
    { value: 'TotalUnit_desc', label: this.translate.instant('DASHBOARD.UNIT_DESC') },
    { value: 'TotalUnit_asc', label: this.translate.instant('DASHBOARD.UNIT_ASC') },
  ];

  paramsMonthProduct: MonthlySummaryProductParams = {
    month: moment().month() + 1,
    year: moment().year(),
    limit: 10,
    page: 1,
    orderBy: 'TotalUnit_desc',
  }
}
