<div *ngFor="let product of products" class="product-item-share ">
  <div>
    <div *ngIf="product.quantity !== 0" class="layout-product">

      <div class="layout-image">
        <img alt="Product image" class="product_image " width="60px" height="60px" [src]="product.coverImagePathThumb"
          onerror="this.src='assets/images/image_error.jpg'" />
        <!-- <div class="discount-label" *ngIf="(product.type === 'Tier' || product.type === 'Bundle') && product.imagePromotion">
            <img [src]="product.imagePromotion" width="20" height="20"/>
        </div> -->
      </div>

      <p class="layout-name">
        {{ currentLang === 'th' ? product.name : product.nameEN ? product.nameEN : product.name }}
      </p>

      <p *ngIf="product.promotionTierId === null" class=" layout-quantity product-name fs-sm fw-semibold mb-0">
        x{{ product.quantity }}
      </p>

    </div>
  </div>

  <div>
    <ng-contenainer *ngFor="let item of product.orderProducts">
      <div class="group-bundle">
        <p class="product-item fs-sm mb-0">
          {{ currentLang === 'th' ? item.name : item.nameEN ? item.nameEN : item.name }}
        </p>
        <span class="price fs-sm">
          x{{ item.quantity }}
        </span>
      </div>
    </ng-contenainer>
    <ng-contenainer *ngFor="let gift of product.privilegeGiveAways">
      <div class="group-bundle-gift">
        <p class="product-item fs-sm mb-0">
          {{ 'PRODUCT_PROMOTION_LIST.FREE' | translate }}! {{ gift.name }}
        </p>
        <span class="price fs-sm">
          x{{ gift.quantity }}
        </span>
      </div>
    </ng-contenainer>
  </div>

  <div>
    <div class="group-price">
      <p class="product-name fs-sm fw-semibold mb-0 pt-3">
        {{ 'PRODUCT_PROMOTION_LIST.SUM' | translate }}
      </p>
      <span class="price fs-6 fw-semibold">
        <div class="product-price" align="right">
          <p class="mb-0 fs-sm text-color-grey" [attr.data-cy]="product.id + '-label-unit-price'">
            {{ getFormattedCurrency(product.unitAmount) }}/{{ (product.productId !== null ?
            'PRODUCT_PROMOTION_LIST.UNIT' : 'PRODUCT_PROMOTION_LIST.SET') | translate }}
          </p>
          <span class="discount-price" *ngIf="product.privilegeDiscount"> {{ getFormattedCurrency(product.amount)
            }}</span>
          <span>{{ product.privilegeDiscount? (getFormattedCurrency(product.amount-product.privilegeDiscount))
            :getFormattedCurrency(product.amount) }} </span>
        </div>
      </span>
    </div>
  </div>

</div>