import { HttpParams } from '@angular/common/http';

export function ClearParams(params: object) {
    let httpParams = new HttpParams();
    Object.entries(params).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== '') {
            httpParams = httpParams.append(key, String(value));
        }
    });

    return httpParams;
}
