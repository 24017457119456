<nav class="navbar bg-body-tertiary fs-sm fw-md">
    <div class="container-fluid" style="cursor: pointer;" (click)="navigateToOrders()">
        <i class="fa fa-arrow-left"></i>
        <div>{{ "CONFIRM_ORDER.GO_BACK" | translate }}</div>
    </div>
    <div class="container-fluid">
        <div class="navbar-brand d-flex justify-content-center justify-content-lg-center" style="font-size: 1.125rem">{{
            "CONFIRM_ORDER.CHECK_ORDER" | translate }}</div>
    </div>
</nav>

<div *ngIf="isLoading" class="overlay">
    <ngx-spinner bdColor="rgb(0,0,0,0.07)" size="medium" color="#21C063" type="ball-clip-rotate" [fullScreen]="false"
        name="spinner" style="margin-top: 0px !important;"></ngx-spinner>
</div>

<div class="aura-container-custom">
    <div class="page">
        <div class="note" data-cy="label-warning-when-update-quantity">
            <div class="tab"></div>
            <div class="icon"><img src="../../../../../../../assets/icons/clipboard.svg"></div>
            <p class="text">{{ "CONFIRM_ORDER.WARNING" | translate }}</p>
        </div>

        <div class="heading" [style.color]="isResetActive ? '#21c063' : '#B3E3C7'">
            <div class="title">{{ "CONFIRM_ORDER.PRODUCT_LIST" | translate }}</div>
            <div class="reset" [ngStyle]="{'cursor': isResetActive ? 'pointer' : 'default'}">
                <i class="fas fa-rotate-left me-2"></i>
                <p class="reset-wording" data-cy="reset-button" (click)="isResetActive ? reStateaVlidateStockistOrder() : null"
                    aria-hidden="true">
                    {{ "CONFIRM_ORDER.RESTORE_ORDER" | translate }}
                </p>
            </div>
        </div>


        
        <div class="layout">
            <!-- ------------------------------------------------------------------------------------------------------- -->
            <!-- ------------------------------------- Order Promotion Tier Display ------------------------------------ -->
            <!-- ------------------------------------------------------------------------------------------------------- -->
            <div *ngIf="orderData.promotionTiers.length > 0">
                <ng-container *ngFor="let tier of orderData.promotionTiers">
                    <div data-id="promotion-tier" class="additional-bundle-card" style="border-radius: 8px 8px 0px 0px;">
                        <div data-id="tier-header" class="additional-header">
                            <span>{{tier.name}}</span>
                        </div>
                    </div>
                    <ng-container *ngFor="let product of tier.orderProducts">
                        <div class="order-card" style="margin-top: 0%; border-radius: 0%;">
                            <div class="card-header">
                                <img class="product-image" [src]="product.coverImagePathThumb === null ? 'assets/images/image_error.jpg' : product.coverImagePathThumb">
                                <span class="product-name">{{currentLang === 'th' ? product.name :
                                    product.nameEN}}</span>
                            </div>
                            <div class="price-per-unit" style="margin-top: 0%;"> <!-- unit -->
                                <span>{{ product.unitAmount | currency : "THB" : "" }}฿/{{ 'PRODUCT_PROMOTION_LIST.UNIT' | translate }}</span>

                            </div>
                            <div class="card-footer">
                                <div class="input-amount" >
                                    <button class="btn-qty-hide" [ngClass]="{'faded': product.quantity <= 0}" 
                                        [disabled]="product.quantity <= 0" (click)="editTier(tier.purchaseOrderItemId,product.id, (product.quantity-1))">
                                        <img src="assets/icons/MinusIcon.svg" />
                                    </button>
                                    <app-input class="amount fs-sm fw-rg" data-cy="product-quantity" type="text"
                                        [isDisabled]="true" [min]="0" [labelMode]="LabelMode" [(ngModel)]="product.quantity"
                                        customMask="0*" />
                                    <button class="btn-qty-hide" [ngClass]="{'faded': product.quantity >= (product.baseQuantity || 0)}" [disabled]="product.quantity === product.baseQuantity" 
                                        (click)="editTier(tier.purchaseOrderItemId,product.id, (product.quantity + 1))">
                                        <img [src]="'assets/icons/PlusIcon.svg'" />
                                    </button>
                                </div>
                                <span *ngIf="!tier.privilegeDiscount"
                                class="total-amount">{{getFormattedCurrency(product.unitAmount * product.quantity)}} </span>
                            <!-- If have privilegeDiscount -->
                            <div *ngIf="tier.privilegeDiscount" class="d-flex">
                                <span class="before-discount-price">
                                    {{getFormattedCurrency(product.unitAmount * product.quantity)}} </span>
                                <span class="net-amount">{{ getFormattedCurrency(product.totalAfterDiscount) }}</span>
                            </div>
                            </div>
                        </div>
                    </ng-container>
                    <!-- Tier discount footer -->
                    <div *ngIf="tier.privilegeDiscount > 0" data-id="tier-discount-footer" [ngClass]="tier.privilegeValid ? 'additional-footer' : 'additional-footer-error'" style="margin-top: 0%;">
                        <div class="privilege-title">{{currentLang === 'th' ? tier.tierMessage.th : tier.tierMessage.en}}</div>
                        <div class="privilege-title">-{{tier.privilegeDiscount}}฿
                            <span *ngIf="tier.tierType === 'Unit'" class="privilege-title">{{'ORDER_INFO.ADDITIONAL.PER_UNIT' | translate}}</span>
                        </div>
                    </div>
                    <!-- Tier giveaway footer -->
                    <div *ngIf="tier.privilegeGiveAways.length > 0" data-id="tier-giveaway-footer" class="additional-footer flex-column" style="margin-top: 0%;">
                        <div class="privilege-title mb-2">{{currentLang === 'th' ? tier.tierMessage.th : tier.tierMessage.en}}</div>
                        <ng-container *ngFor="let additionalGiveaway of tier.privilegeGiveAways">
                            <div class="card-body">
                                <span class="card-item">{{currentLang === 'th' ? additionalGiveaway.name : additionalGiveaway.nameEN}} </span>
                                <span>x{{ additionalGiveaway.quantity }}</span>
                            </div>
                        </ng-container>
                    </div>
                    <!-- additional error not valid -->
                    <div *ngIf="tier.privilegeGiveAways.length === 0 && tier.privilegeDiscount === 0 && !tier.privilegeValid" data-id="tier-discount-footer" class="additional-footer-error" style="margin-top: 0%;">
                        <div class="privilege-title">{{currentLang === 'th' ? tier.tierMessage.th : tier.tierMessage.en}}</div>
                    </div>
                </ng-container>
            </div>

            <!-- ------------------------------------------------------------------------------------------------------- -->
            <!-- ------------------------------------ Order Promotion Bundle Display ----------------------------------- -->
            <!-- ------------------------------------------------------------------------------------------------------- -->
            <!--  additional privilege bundle -->
            <div *ngIf="orderData.promotionBundles.length > 0">
                <ng-container *ngFor="let bundle of orderData?.promotionBundles">
                        <div data-id="additional-bundle" class="additional-bundle-card"
                            *ngIf="bundle.additionalAmount > 0">
                            <div data-id="additional-header" class="additional-header">
                                {{'ORDER_INFO.ADDITIONAL.ORDER_TITLE' | translate}}
                                <span >&nbsp;{{bundle.additionalAmount}}&nbsp;{{bundle.additionalType === 'Unit' ? ('PRODUCT_PROMOTION_LIST.UNIT' | translate) : '฿' }}</span>
                            </div>
                            <div *ngFor="let item of bundle.items" class="additional-bundle-card">
                                <div class="card-header px-3">
                                    <img class="product-image" [src]="item.coverImagePathThumb === null ? 'assets/images/image_error.jpg' : item.coverImagePathThumb">
                                    <span class="product-name">{{currentLang === 'th' ? item.name :
                                        item.nameEN}}</span>
                                </div>
                                <ng-container data-id="additional-bundle-body" *ngFor="let item of item.orderProducts">
                                    <div class="card-body px-3">
                                        <span class="card-item">{{currentLang === 'th' ? item.name : item.nameEN}} </span>
                                        <span>x{{ item.quantity }}</span>
                                    </div>
                                </ng-container>
                                <!-- If have privilegeGiveAways -->
                                <ng-container *ngIf="bundle.items?.[0]?.privilegeGiveAways?.length">
                                    <ng-container *ngFor="let giveaway of bundle.items?.[0]?.privilegeGiveAways">
                                        <div class="giveaway-body px-3">
                                            <span class="card-item">{{"ORDER_INFO.ADDITIONAL.FREE" | translate}} {{currentLang
                                                === 'th' ? giveaway.name : giveaway.nameEN}}</span>
                                            <span>x{{giveaway.quantity}}</span>
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <div class="price-per-unit px-3">
                                    <span>{{ item.unitAmount | currency : "THB" : "" }}฿/{{ 'PRODUCT_PROMOTION_LIST.UNIT' | translate }}</span>
                                </div>
                                <div class="card-footer  px-3">
                                    <div class="input-amount" >
                                        <button class="btn-qty-hide" [ngClass]="{'faded': item.quantity <= 0}" 
                                            [disabled]="item.quantity <= 0" (click)="editBundle(bundle.promotionId,item.id, (item.quantity-1))">
                                            <img src="assets/icons/MinusIcon.svg" />
                                        </button>
                                        <app-input class="amount fs-sm fw-rg" data-cy="product-quantity" type="text"
                                            [isDisabled]="true" [min]="0" [labelMode]="LabelMode" [(ngModel)]="item.quantity"
                                            customMask="0*" />
                                        <button class="btn-qty-hide" [ngClass]="{'faded': item.quantity >= (item.baseQuantity || 0)}" [disabled]="item.quantity === item.baseQuantity" 
                                            (click)="editBundle(bundle.promotionId,item.id, (item.quantity + 1))">
                                            <img [src]="'assets/icons/PlusIcon.svg'" />
                                        </button>
                                    </div>
                                    <span *ngIf="!item.privilegeDiscount"
                                        class="total-amount">{{getFormattedCurrency(item.totalAmount)}} </span>
                                    <!-- If have privilegeDiscount -->
                                    <div *ngIf="bundle.items && bundle.items.length > 0 && item.privilegeDiscount" class="d-flex">
                                        <span class="before-discount-price">
                                            {{getFormattedCurrency(item.totalAmount)}} </span>
                                        <span class="net-amount">{{ getFormattedCurrency((item.totalAmount)
                                            - (item.privilegeDiscount)) }}</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="bundle.additionalPrivilegeDiscount > 0 && bundle.additionalPrivilegeValid" data-id="additional-footer" class="additional-footer">
                                <span class="privilege-title">{{'ORDER_INFO.ADDITIONAL.DISCOUNT' | translate}}</span>
                                <span class="privilege-title">-{{bundle.additionalPrivilegeDiscount}}฿</span>
                            </div>
                            <div *ngIf="!bundle.additionalPrivilegeValid" data-id="additional-footer-error" class="additional-footer-error">
                                <span class="privilege-title">{{'ORDER_INFO.ADDITIONAL.TITLE_ERROR' | translate}}</span>
                            </div>
                            <div *ngIf="bundle.additionalGiveAways.length > 0 && bundle.additionalPrivilegeValid" data-id="additional-footer" class="additional-footer flex-column">
                                <div class="privilege-title">{{'ORDER_INFO.ADDITIONAL.GIVEAWAY' | translate}}</div>
                                <ng-container *ngFor="let additionalGiveaway of bundle.additionalGiveAways">
                                    <div class="card-body">
                                        <span class="card-item">{{currentLang === 'th' ? additionalGiveaway.name : additionalGiveaway.nameEN}} </span>
                                        <span>x{{ additionalGiveaway.quantity }}</span>
                                    </div>
                                </ng-container>
                            </div>
                    </div>
                </ng-container>
            </div>

            <!-- bundle no additional  -->
            <div *ngIf="orderData.promotionBundles.length > 0">
                <ng-container *ngFor="let bundle of orderData.promotionBundles">
                    <div data-id="no-additional-bundle"
                        *ngIf="!bundle.additionalAmount">
                        <div *ngFor="let item of bundle.items" class="order-card">
                            <div class="card-header">
                                <img class="product-image" [src]="item.coverImagePathThumb === null ? 'assets/images/image_error.jpg' : item.coverImagePathThumb">
                                <span class="product-name">{{currentLang === 'th' ? item.name :
                                    item.nameEN}}</span>
                            </div>

                            <ng-container *ngFor="let item of item.orderProducts">
                                <div class="card-body">
                                    <span class="card-item">{{currentLang === 'th' ? item.name : item.nameEN}} </span>
                                    <span>x{{ item.quantity }}</span>
                                </div>
                            </ng-container>

                            <!-- If have privilegeGiveAways -->
                            <ng-container *ngIf="bundle.items?.[0]?.privilegeGiveAways?.length">
                                <ng-container *ngFor="let giveaway of bundle.items?.[0]?.privilegeGiveAways">
                                    <div class="giveaway-body">
                                        <span class="card-item">{{"ORDER_INFO.ADDITIONAL.FREE" | translate}} {{currentLang
                                            === 'th' ? giveaway.name : giveaway.nameEN}}</span>
                                        <span>x{{giveaway.quantity}}</span>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <div class="price-per-unit">
                                <span>{{ item.unitAmount | currency : "THB" : "" }}฿/{{ 'PRODUCT_PROMOTION_LIST.SET' | translate }}</span>
                            </div>

                            <div class="card-footer">
                                <div class="input-amount" >
                                    <button class="btn-qty-hide" [ngClass]="{'faded': item.quantity <= 0}" 
                                        [disabled]="item.quantity <= 0" (click)="editBundle(bundle.promotionId,item.id, (item.quantity-1))">
                                        <img src="assets/icons/MinusIcon.svg" />
                                    </button>
                                    <app-input class="amount fs-sm fw-rg" data-cy="product-quantity" type="text"
                                        [isDisabled]="true" [min]="0" [labelMode]="LabelMode" [(ngModel)]="item.quantity"
                                        customMask="0*" />
                                    <button class="btn-qty-hide" [ngClass]="{'faded': item.quantity >= (item.baseQuantity || 0)}" [disabled]="item.quantity === item.baseQuantity" 
                                        (click)="editBundle(bundle.promotionId,item.id, (item.quantity + 1))">
                                        <img [src]="'assets/icons/PlusIcon.svg'" />
                                    </button>
                                </div>
                                <span *ngIf="!item.privilegeDiscount"
                                    class="total-amount">{{getFormattedCurrency(item.totalAmount)}} </span>
                                <!-- If have privilegeDiscount -->
                                <div *ngIf="bundle.items && bundle.items.length > 0 && item.privilegeDiscount" class="d-flex">
                                    <span class="before-discount-price">
                                        {{getFormattedCurrency(item.totalAmount)}} </span>
                                    <span class="net-amount">{{ getFormattedCurrency((item.totalAmount)
                                        - (item.privilegeDiscount)) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>

            <!-- ------------------------------------------------------------------------------------------------------- -->
            <!-- ----------------------------------------  Basic Product Display --------------------------------------- -->
            <!-- ------------------------------------------------------------------------------------------------------- -->
            <div *ngIf="orderData.products.length > 0">
                <div *ngFor="let product of orderData.products">
                    <div class="order-card">
                        <div class="card-header">
                            <img class="product-image" [src]="product.coverImagePathThumb === null ? 'assets/images/image_error.jpg' : product.coverImagePathThumb" >
                            <span class="product-name">{{currentLang === 'th' ? product.name : product.nameEN}}</span>
                        </div>
                        <div class="price-per-unit" style="margin-top: 0%;">
                            <span>{{ product.unitAmount | currency : "THB" : "" }}฿/{{ 'PRODUCT_PROMOTION_LIST.UNIT' | translate }}</span>
                        </div>
                        <div class="card-footer" style="margin-top: 0%;">
                            <div class="input-amount" >
                                <button class="btn-qty-hide" [ngClass]="{'faded': product.quantity <= 0}" 
                                    [disabled]="product.quantity <= 0" (click)="editProduct(product.id, (product.quantity-1))">
                                    <img src="assets/icons/MinusIcon.svg" />
                                </button>
                                <app-input class="amount fs-sm fw-rg" data-cy="product-quantity" type="text"
                                    [isDisabled]="true" [min]="0" [labelMode]="LabelMode" [(ngModel)]="product.quantity"
                                    customMask="0*" />
                                <button class="btn-qty-hide" [ngClass]="{'faded': product.quantity >= (product.baseQuantity || 0)}" [disabled]="product.quantity === product.baseQuantity" 
                                    (click)="editProduct(product.id, (product.quantity + 1))">
                                    <img [src]="'assets/icons/PlusIcon.svg'" />
                                </button>
                            </div>
                            <span class="total-amount"> {{getFormattedCurrency(product.totalAmount)}} </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="layout2">
            <!-- ------------------------------ Delivery Date ------------------------------ -->
            <div class="deliver-information">
                <div data-cy="label-open-day" class="gap-3 mb-2 fw-semibold">{{ "CONFIRM_ORDER.STOCKIST_OPEN" |
                    translate }}</div>
                <div class="open-day">
                    <div *ngFor="let day of days">
                        <span [ngStyle]="{'color': isDayActive(day, orderData.officeDays) ? colors[1] : 'black'}">{{
                            day }}</span>
                    </div>
                </div>
                <div data-cy="label-estimate-date-delivery" class="gap-3 mb-2 fw-semibold mt-3">
                    {{ "CONFIRM_ORDER.EXPECTED_DELIVERY_ORDER" | translate }}
                </div>
                <div class="flex mb-3 red-color">
                    <i class="fa-solid fa-triangle-exclamation"></i>
                    <span data-cy="label-pls-delivery" class="space-block gap-3 mb-3 fw-light ms-2">{{
                        "CONFIRM_ORDER.VALIDATE" | translate }}
                    </span>
                </div>

                <!-- <ng-template #inputContent> -->
                <div class="input-group validated">
                    <div class="input-group-append">
                        <ng-container>
                            <span class="input-group-text " (click)="picker.open()"><img id="btn-click"
                                    src="../../../../../../../assets/icons/calendar.svg">
                            </span>

                        </ng-container>
                    </div>
                    <input class="form-control" [matDatepicker]="picker" (click)="picker.open()"
                        placeholder="{{'CONFIRM_ORDER.EXPECTED_DELIVERY' | translate }}" [(ngModel)]="dateModel"
                        autocomplete="off" [readonly]="true" [min]="currentDate" (dateChange)="dateSelect($event)" />
                    <mat-datepicker #picker></mat-datepicker>
                </div>
                <!-- </ng-template> -->

                <div class="gap-3 mb-3 mt-3">
                    <button data-cy="estimate-time-delivery'" type="button" class="btn office-time icon-color">
                        <img style="margin-left: 5px;" src="../../../../../../../assets/icons/clock.svg">
                        <span style="margin-left: 15px;"
                            class="placeholder-text">{{this.formatTime(this.orderData.officeTime)}}</span>
                    </button>
                </div>
            </div>

            <!-- ------------------------------ Confirm Button ------------------------------ -->
            <div class="d-flex flex-column gap-3 mb-3">
                <button data-cy="confirm-order-button" type="button" class="btn btn-block confirm-order-btn"
                    (click)="navigateToOrderConfirm()" [disabled]="false">
                    {{ "CONFIRM_ORDER.CONFIRM_ORDER" | translate }}
                </button>
            </div>
            <div class="d-flex flex-column gap-3 mb-3">
                <button data-cy="cancel-order-button" type="button" class="btn btn-block cancel-order-btn"
                    (click)="onClickOpenCancelModal()">
                    {{ "CONFIRM_ORDER.CANCEL_ORDER" | translate }}
                </button>
            </div>
        </div>
    </div>
    <app-reason-cancellation-modal *ngIf="openCancelModal" [isOpenModal]="openCancelModal" [orderNumber]="orderNumber"
        (isOpenModalChange)="onClickCloseModal()" />
</div>