import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StockistPurchaseOrderDetail, StockistPurchaseOrderPromotionDetail } from '@core/models/stockist/stockist.model';
import { StockistService } from '@core/services/stockist/purchase.service';
import { ConfigService } from '@core/services/shared/config.service';

// const mockData: StockistPurchaseOrderDetail = {
//   id: '000001',
//   status: 'Pending',
//   stockistName: 'John',
//   retailerName: 'AAAA',
//   retailerAddress: '122 บ้านสุดทาง แมวน้ำ แมวน้ำ กรุงเทพ 10022',
//   retailerProvince: 'กรุงเทพ',
//   retailerDistrict: 'แมวน้ำ',
//   retailerSubdistrict: 'แมวน้ำ',
//   retailerPostCode: '10022',
//   billingAddress: '122 บ้านสุดทาง แมวน้ำ แมวน้ำ กรุงเทพ 10022',
//   billingProvince: 'กรุงเทพ',
//   billingDistrict: 'แมวน้ำ',
//   billingSubdistrict: 'แมวน้ำ',
//   billingPostCode: '10022',
//   createdAt: '2024-04-30 15:54:06.3731160',
//   cancelledDate: '',
//   cancelRequestedDate: '',
//   confirmedDate: '',
//   deliveredDate: '',
//   cancelReasonOrder: '',
//   cancelRejectedDate: '',
//   amount: 1000,
//   discount: 0,
//   shippingCost: 100,
//   netAmount: 1100,
//   taxid: '12345678912',
//   mobilePhoneNumber: '0911111111',
//   secondaryPhoneNumber: null,
//   cancelRequest: false,
//   refOrderId: null,
//   deliveryDate: '2024-06-30 15:54:06.3731160',
//   officeDays: 'จ | อ | พ',
//   officeTime: '',
//   items: [{
//     coverImagePathThump: 'https://auras.z23.web.core.windows.net/preview/Product/4/CoverImage/cbf4515b-3c38-4414-8a76-0a48a38041c6.jpeg',
//     productName: 'คริสตัล น้ำดื่ม 1.5 ลิตร แพ็ค 6 ขวด',
//     quantity: 100,
//     unitAmount: 10,
//     amount: 1000,
//     id: 1
//   },{
//     coverImagePathThump: 'https://auras.z23.web.core.windows.net/preview/Product/5/CoverImage/5d60ebb4-7730-4ad9-8aec-c2c80fa1f369.jpeg',
//     productName: 'ออร่า น้ำดื่ม 500 มล. แพ็ค 12',
//     quantity: 1,
//     unitAmount: 10,
//     amount: 10,
//     id: 2
//   }]
// }

@Component({
  selector: 'app-order-info-main',
  templateUrl: './order-info-main.component.html',
  styleUrls: ['./order-info-main.component.scss']
})
export class OrderInfoMainComponent implements OnInit{
  orderDetail:  StockistPurchaseOrderDetail | undefined
  orderDetailv2!:  StockistPurchaseOrderPromotionDetail

  appVersion = ''

  constructor(
    private purchaseService: StockistService,
    private route: ActivatedRoute,
    private configService: ConfigService,
  ){}

  ngOnInit(): void {
      this.appVersion = this.configService.getAppVersion();
      this.appVersion === 'v1' ? this.getOrderData() : this.getOrderDataV2();
  }

  getOrderData(){
    const orderId = this.route.snapshot.params['order-number'];
    if (orderId){
      this.purchaseService.getPurchaseOrder(orderId).subscribe((res) => {
        this.orderDetail = res
      })
    }

  }

  getOrderDataV2(){
    const orderId = this.route.snapshot.params['order-number'];
    if (orderId){
      this.purchaseService.getPurchaseOrderV2(orderId).subscribe((res) => {
        this.orderDetailv2 = res
      })
    }

  }

}
