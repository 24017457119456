<div class="background-order-confirm margin-btm" *ngIf="data">
    <div class="d-flex justify-content-between fs-5 background-order-head">
        <div>
            <p data-cy="label-page-info" class="me-auto fw-semibold text-head-first">
                {{ "PROCESSED_CONFIRMED.CONFIRM_ORDER" | translate }}
            </p>
            <p class="fw-ligh text-head-mid">{{ "PROCESSED_CONFIRMED.ORDER_ID" | translate }} {{data.id}}</p>
            <p class="fw-light text-head-last">
                {{ "PROCESSED_CONFIRMED.CONFIRMED_AT" | translate }} {{data.confirmedDate | displayDate}}
            </p>
        </div>

        <div>
            <img class="" src="assets/images/Order Confirmed.svg" width="52px" height="52px" alt="Not found" />
        </div>
    </div>

    <div class="d-flex gap-2 fs-6 text-wrap px-3 py-2 top-border bg-white">
        <i class="fa-solid fa-calendar pt-1" style="color: #93f9bc"></i>
        <div>
            <p class="fs-sm fw-rg mb-2">{{ "PROCESSED_CONFIRMED.EXPECTED_DELIVERY" | translate }}</p>
            <div>
                <span class="fs-md fw-md">{{data.deliveryDate | displayDeliveryDate}},
                    {{this.formatTime(data.officeTime)}}<br /></span>
            </div>
        </div>
    </div>

    <div class="d-flex gap-2 fs-6 py-2 text-wrap align-item-center top-border px-3">
        <img src="\assets\icons\Store_Icon.svg" alt="notFound" />
        <p class="store-name">{{data.retailerName}}</p>
    </div>
    <div class="bottom-border"></div>

    <div class="mx-3">
        <app-product-list [products]="data.items"></app-product-list>

    </div>

    <app-additional-privilege *ngIf="data.additionalDiscounts.length > 0 || data.additionalGiveAways.length > 0"
      [additionalGiveAways]="data.additionalGiveAways"
      [additionalDiscounts]="data.additionalDiscounts"></app-additional-privilege>

    <div class="mx-3">
        <app-payment [paymentInfo]="getPaymentInfo()"></app-payment>
    </div>


</div>

<div id="footer-page" class="footer-position">
    <div class="d-flex flex-column w-100 px-3 pt-3 background-order-confirm">
        <!-- <div class="d-flex flex-column w-100 px-3 pt-3  footer-top-border background-order-confirm"> -->

        <div class="d-flex flex-column gap-3 mb-3">
            <button type="button" class="btn detail-order-btn" (click)="navigateToOrderInfoConfirmed()">
                {{ "PROCESSED_CONFIRMED.VIEW_ORDER_DETAIL" | translate }}
            </button>
            <button type="button" class="btn btn-secondary-button" (click)="navigateToLanding()">
                {{ "PROCESSED_CONFIRMED.BACK_TO_HOME" | translate }}
            </button>
        </div>

    </div>
</div>
