import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentInfo } from '@core/models/shares/modal.model';
import { StockistPurchaseOrderPromotionDetail } from '@core/models/stockist/stockist.model';
import { StockistService } from '@core/services/stockist/purchase.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-order-processed-reject-cancel-request-new',
  templateUrl: './order-processed-reject-cancel-request-new.component.html',
  styleUrls: ['./order-processed-reject-cancel-request-new.component.scss']
})
export class OrderProcessedRejectCancelRequestNewComponent implements OnInit {

  data: StockistPurchaseOrderPromotionDetail | undefined;
  orderId = '';

  orderIsHavePromotionGift = false;

  constructor(
    private route: Router,
    private activateRoute: ActivatedRoute,
    private translate: TranslateService,
    private purchaseService: StockistService,
  ) { this.translate.setDefaultLang('th'); }

  ngOnInit(): void {
    this.orderId =
      this.activateRoute.snapshot.paramMap.get('order-number') ?? '';
    this.getOrderDetail();
  }

  navigateToLanding() {
    this.route.navigate(['/stockist']);
  }
  navigateToOrderInfoCancelRequested() {
    this.route.navigate([`stockist/order/${this.orderId}`]);
  }

  totalQuantity(): number {
    return (this.data?.items.reduce((total, item) => total + item.quantity, 0) || 0);
  }

  getOrderDetail() {
    if (this.orderId) {
      this.purchaseService.getPurchaseOrderV2(this.orderId).subscribe({
        next: (res) => {
          this.data = res
        },
        error: (err) => {
          console.error(err)
        }
      })
    }
  }

  getPaymentInfo(): PaymentInfo {
    return <PaymentInfo>{
      totalQuantity: this.getTotalQuantity(),
      totalAmount: this.getTotalAmount(),
      couponDiscount: this.data?.couponDiscount,
      promotionDiscount: this.data?.promotionDiscount,
      shippingCost: this.data?.shippingCost,
      netAmount: this.getNetAmount(),
    };
  }

  getNetAmount(): number {
    if (!this.data) return 0
    const netAmount = this.data.netAmount;
    return netAmount

  }

  getTotalAmount(): number {
    if (this.data && this.data.items) {
      const totalQuantity = this.data.items.reduce(
        (total, item) => total + item.amount,
        0
      );

      return totalQuantity;
    }

    return 0;
  }

  getTotalQuantity(): number {
    if (this.data && this.data.items) {
      const totalQuantity = this.data.items.reduce(
        (total, item) => total + item.quantity,
        0
      );

      return totalQuantity;
    }

    return 0;
  }

  handleImageError(event: Event) {
    const target = event.target as HTMLImageElement;
    target.src = 'assets/images/image_error.jpg';
  }
}
