import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { StockistRoutingModule } from './stockist-routing.module';
import { OrdersComponent } from './components/orders/orders.component';
import { OrderInfoConfirmedComponent } from './components/order-info-confirmed/order-info-confirmed.component';
import { OrderInfoPendingComponent } from './components/order-info-pending/order-info-pending.component';
import { OrderInfoCancelledComponent } from './components/order-info-cancelled/order-info-cancelled.component';
import { OrderInfoDeprecatedComponent } from './components/order-info-deprecated/order-info-deprecated.component';
import { OrderInfoCancelRequestedComponent } from './components/order-info-cancel-requested/order-info-cancel-requested.component';
import { OrderInfoDeliveredComponent } from './components/order-info-delivered/order-info-delivered.component';
import { OrderInfoConfirmedMessageComponent } from './components/order-info-confirmed-message/order-info-confirmed-message.component';
import { ConfirmOrderComponent } from './components/confirm-order/confirm-order.component';
import {
    NgbActiveModal,
    NgbDatepickerModule,
    NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SuccessfulOrderCancellationComponent } from './components/successful-order-cancellation/successful-order-cancellation.component';
import { CancellationComponent } from './components/cancellation/cancellation.component';
import { ViewAllOrdersComponent } from './components/view-all-orders/view-all-orders.component';
import { CancelRejectComponent } from './components/cancel-reject/cancel-reject.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DisplayDatePipe } from '@core/pipe/display-date.pipe';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { ShipToAddressComponent } from './components/ship-to-address/ship-to-address.component';
import { ShowProductListComponent } from './components/show-product-list/show-product-list.component';
import { PaymentComponent } from './components/payment/payment.component';
import { ModalMessageComponent } from './components/modal-message/modal-message.component';
import { SharedModule } from '@modules/shared/shared.module';
import { OrderHistoryComponent } from './components/order-history/order-history.component';
import { OrderProcessedConfirmedComponent } from './components/order-processed-confirmed/order-processed-confirmed.component';
import { OrderProcessedCancelledComponent } from './components/order-processed-cancelled/order-processed-cancelled.component';
import { PaymentToAddressComponent } from './components/payment-to-address/payment-to-address.component';
import { ShipDateComponent } from './components/ship-date/ship-date.component';
import { OrderInfoMainComponent } from './components/order-info-main/order-info-main.component';
import { OrderProcessedRejectCancelRequestComponent } from './components/order-processed-reject-cancel-request/order-processed-reject-cancel-request.component';
import { DeliveredDateComponent } from './components/delivered-date/delivered-date.component';
import { DisplayDateDelivedPipe } from '@core/pipe/display-date-delivedcase';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { NgApexchartsModule } from 'ng-apexcharts'
import { NgxSpinnerModule } from 'ngx-spinner';
import { MyNotificationComponent } from './components/my-notification/my-notification.component';
import { ShowGiftListComponent } from './components/show-gift-list/show-gift-list.component';
import { ProfileComponent } from './components/profile/profile.component';
import { TranslateModule } from '@ngx-translate/core';
import { LanguagePageComponent } from './components/language-page/language-page.component';
import { FilterProductModalComponent } from './components/filter-product-modal/filter-product-modal.component';
import { SearchComponent } from './components/search/search.component';
import { ProductShelfListComponent } from './components/product-shelf-list/product-shelf-list.component';
import { ToggleVersionModule } from 'version-toggle';
import { AdditionalPrivilegeComponent } from './components/additional-privilege/additional-privilege.component';

@NgModule({
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        OrdersComponent,
        OrderInfoConfirmedComponent,
        OrderInfoPendingComponent,
        OrderInfoCancelledComponent,
        OrderInfoDeprecatedComponent,
        OrderInfoCancelRequestedComponent,
        OrderInfoDeliveredComponent,
        OrderInfoConfirmedMessageComponent,
        ConfirmOrderComponent,
        SuccessfulOrderCancellationComponent,
        CancellationComponent,
        ViewAllOrdersComponent,
        CancelRejectComponent,
        SideBarComponent,
        ShipToAddressComponent,
        ShowProductListComponent,
        PaymentComponent,
        ModalMessageComponent,
        OrderHistoryComponent,
        DisplayDatePipe,
        DisplayDateDelivedPipe,
        OrderProcessedConfirmedComponent,
        OrderProcessedCancelledComponent,
        PaymentToAddressComponent,
        ShipDateComponent,
        OrderProcessedConfirmedComponent,
        OrderProcessedCancelledComponent,
        OrderInfoMainComponent,
        OrderProcessedRejectCancelRequestComponent,
        DeliveredDateComponent,
        DashboardComponent,
        MyNotificationComponent,
        ShowGiftListComponent,
        ProfileComponent,
        LanguagePageComponent,
        FilterProductModalComponent,
        SearchComponent,
        ProductShelfListComponent,
        AdditionalPrivilegeComponent
    ],
    providers: [
        NgbActiveModal,
        { provide: LOCALE_ID, useValue: "th-TH" },
    ],
    imports: [
        CommonModule,
        StockistRoutingModule,
        NgbModule,
        NgbDatepickerModule,
        FormsModule,
        FontAwesomeModule,
        SharedModule,
        ReactiveFormsModule,
        NgApexchartsModule,
        InfiniteScrollModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgxSpinnerModule,
        TranslateModule,
        ToggleVersionModule
    ],
    exports: [DisplayDatePipe, DisplayDateDelivedPipe, SideBarComponent, ShipToAddressComponent],
})
export class StockistModule { }
