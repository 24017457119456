import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductInfo } from '@core/models/retailer/purchase-order.model';
import { ButtonTag } from '@core/models/shares/enum';
import { OrderUpdateAndConfirm } from '@core/models/stockist/order.model';
import { PurchaseOrderItem } from '@core/models/stockist/preview-order.model';
import { StockistPurchaseOrderDetail } from '@core/models/stockist/stockist.model';
import { StockistService } from '@core/services/stockist/purchase.service';
import {
    NgbDateStruct,
    NgbModal,
    NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { ModalMessageComponent } from '../modal-message/modal-message.component';
import { PaymentInfo } from '@core/models/shares/modal.model';
import { ChangeDetectorRef } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { CustomDateAdapter } from '../../../../../../_helpers/custom-datepicker';
import { APP_DATE_FORMATS } from '../../../../../../_helpers/format-datepicker';
import { PromotionsService } from '@core/services/promotions/promotions.service';
import { PromotionOrderItem, PromotionValidate } from '@core/models/stockist/promotion.validate';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
class SelectedPurchaseItem extends PurchaseOrderItem {
    selected = true;
}
@Component({
    selector: 'app-confirm-order',
    templateUrl: './confirm-order.component.html',
    styleUrls: ['./confirm-order.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE, TranslateService] },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
    ]
})
export class ConfirmOrderComponent implements OnInit {
    isDatePickerVisible = false;

    selectedTime: string = new Date().toTimeString();
    orderNumber = '';
    stockistOrder: StockistPurchaseOrderDetail =
        new StockistPurchaseOrderDetail({});
    purchaseOrderItem: SelectedPurchaseItem[] = [];
    cloneOrder: SelectedPurchaseItem[] = [];
    sumAmount: number | undefined;
    stockistId = 0;

    today: Date = new Date();
    modelToday: { year: number, month: number, day: number } = {
        year: this.today.getFullYear(),
        month: this.today.getMonth() + 1,
        day: this.today.getDate()
    };

    model: NgbDateStruct = {
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
        day: new Date().getDay(),
    };
    isZero: string[] = [];
    isZeroX: string[] = [];
    selectedDate: Date | null = null;
    retailerId = '001';
    pricePerUnit: number[] = [];
    days: string[] = []
    thDays: string[] = ['จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส', 'อา'];
    enDays: string[] = [
        'M',
        'T',
        'W',
        'Th',
        'F',
        'S',
        'Su',
    ]
    colors: string[] = ['#21C063', '#21C063', '#21C063', '#21C063', '#21C063', '#21C063', '#21C063'];
    isResetActive = false;

    openCancelModal = false

    currentDate: string;
    isLoading = false;

    constructor(
        private activeRoute: ActivatedRoute,
        private stockistService: StockistService,
        private promotionsService: PromotionsService,
        private router: Router,
        private _eref: ElementRef,
        private modalService: NgbModal,
        private cdr: ChangeDetectorRef,
        private translate: TranslateService,
        private spinner: NgxSpinnerService
    ) {
        const date = new Date();
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = date.getDate().toString().padStart(2, '0');
        this.currentDate = `${year}-${month}-${day}`;
    }
    private modalRef: NgbModalRef | undefined;
    showModal = false;

    ngOnInit() {
        const isThai = this.translate.currentLang === 'th';
        this.days = isThai ? this.thDays : this.enDays
        this.activeRoute.params.subscribe((params) => {
            this.orderNumber = params['order-number'];
        });
        this.getStockistOrderDetail();
    }
    toggleDatePicker() {
        this.isDatePickerVisible = !this.isDatePickerVisible;
    }
    onClickOpenCancelModal() {
        this.openCancelModal = true
    }

    onClickCloseModal() {
        this.openCancelModal = false
    }

    transformItem(orderItem: PurchaseOrderItem[]): SelectedPurchaseItem[] {
        return orderItem.map((item) => {
            return { ...item, selected: true };
        });
    }

    checkDisplay(item: SelectedPurchaseItem): boolean {
        return item.quantity >= 0;
    }

    increaseQuantity(index: number): void {
        this.cdr.detectChanges();

        if (
            this.cloneOrder[index].quantity <
            this.purchaseOrderItem[index].quantity
        ) {
            this.cloneOrder[index].quantity++;
            this.updatePromotionDiscount();
            this.cloneOrder[index].selected = true;
            if (this.cloneOrder[index].quantity === 1) {
                this.isZeroX.pop();
            }
        }

        this.checkOrderIsChange();
    }

    reduceQuantity(index: number): void {

        if (this.cloneOrder[index].quantity > 0) {
            this.cloneOrder[index].quantity--;
            this.updatePromotionDiscount();
            if (this.cloneOrder[index].quantity === 0) {
                this.cloneOrder[index].selected = false;
                const itemIdString = this.cloneOrder[index].id.toString();
                if (!this.isZeroX.includes(itemIdString)) {
                    this.isZeroX.push(itemIdString);
                }
            }
            this.cdr.detectChanges();
            setTimeout(() => {
                this.checkOrderIsChange();
            }, 0);
        }
    }

    updatePromotionDiscount() {

        const orderItems: PromotionOrderItem[] = this.cloneOrder.map(order => ({
            productId: order.productId,
            price: order.unitAmount * order.quantity,
            quantity: order.quantity,
            quantityPromotion: 0,
            promotionDiscount: 0
        }));

        const order: PromotionValidate = {
            stockistId: this.stockistId,
            items: orderItems
        };

        this.promotionsService.validatePromotion(order).subscribe(res => {
            const totalPromotionDiscount = res.items.reduce((sum, item) => sum + (item.promotionDiscount || 0), 0);
            this.stockistOrder.promotionDiscount = totalPromotionDiscount;
        });

    }





    selectOrder(index: number): void {
        this.cloneOrder[index].selected = !this.cloneOrder[index].selected;
    }

    checkOrderIsChange = () => {
        this.cdr.detectChanges();
        const selectedCloneItems = this.cloneOrder.filter(item => item.selected);
        const selectedOriginalItems = this.purchaseOrderItem.filter(item => item.selected);
        const totalCloneOrderQuantity = selectedCloneItems.reduce((all, item) => all + item.quantity, 0);
        const totalOriginalOrderQuantity = selectedOriginalItems.reduce((all, item) => all + item.quantity, 0);
        this.isResetActive = totalCloneOrderQuantity < totalOriginalOrderQuantity;

        return totalCloneOrderQuantity < totalOriginalOrderQuantity;
    };



    resetToDefault() {
        this.cloneOrder = this.deepCopy(this.purchaseOrderItem);
        this.checkOrderIsChange();
        this.cdr.detectChanges();
    }

    dateSelect(event: { value: Date | null; }): void {
        this.selectedDate = event.value
        this.isDatePickerVisible = false;
    }

    showDatePicker(): void {
        this.isDatePickerVisible = !this.isDatePickerVisible;
    }

    isDayActive(day: string, officeDays: string | undefined): boolean {

        if (!officeDays) {
          return false;
        }
        let d = ''
        if (day === 'Su' || day === 'อา'){
          d = 'อา'
        }
        if (day === 'M' || day === 'จ'){
          d = 'จ'
        }
        if (day === 'T' || day === 'อ'){
          d = 'อ'
        }
        if (day === 'W' || day === 'พ'){
          d = 'พ'
        }
        if (day === 'Th' || day === 'พฤ'){
          d = 'พฤ'
        }
        if (day === 'F' || day === 'ศ'){
          d = 'ศ'
        }
        if (day === 'S' || day === 'ส'){
          d = 'ส'
        }
        return new Set(officeDays.split('|')).has(d);
    }

    getTotalQuantity(): number {
        return this.cloneOrder
            .filter((item) => item.selected)
            .reduce((all, item) => all + item.quantity, 0);

    }

    getTotalAmount(): number {
        let sumAmount = 0;
        this.cloneOrder
        .map((item, index) => ({ item, pricePerUnit: this.pricePerUnit[index] }))
        .filter(({ item }) => item.selected)
        .forEach(({ item, pricePerUnit }) => {
                if (item.quantity > 0 && pricePerUnit > 0) {
                    sumAmount += pricePerUnit * item.quantity;
                }
            });

        return sumAmount;
    }

    getNetAmount(): number {
        const netAmount = this.stockistOrder.netAmount;

        return netAmount;
    }

    getPaymentInfo(): PaymentInfo {
        return <PaymentInfo>{
            totalQuantity: this.getTotalQuantity(),
            totalAmount: this.getTotalAmount(),
            couponDiscount: this.stockistOrder.couponDiscount,
            promotionDiscount: this.stockistOrder.promotionDiscount,
            shippingCost: this.stockistOrder.shippingCost,
            netAmount: this.getNetAmount(),
        };
    }

    formatTime(officeTime: string): string {
        if (officeTime) {
            const [startTime, endTime] = officeTime.split("-");
            const formattedStartTime = startTime.split(" ")[1].substring(0, 5);
            const formattedEndTime = endTime.split(" ")[1].substring(0, 5);
            return `${formattedStartTime}-${formattedEndTime}`;
        }
        return '';

    }

    resolveValue(event: Event, i: number) {
        const inputValue = (event.target as HTMLInputElement).value;
        const currentQuantity = this.purchaseOrderItem[i].quantity;
        const cleanedInputValue = inputValue.replace(/^0+|[^0-9]|\b0+/g, '');
        let newQuantity = 0;
        if (parseInt(inputValue) === 0 || cleanedInputValue === '') {
            if (!this.isZero.includes(i.toString())) {
                this.isZero.push(i.toString());
            }
            newQuantity = 0;
        } else if (parseInt(cleanedInputValue) > currentQuantity) {
            (event.target as HTMLInputElement).value = currentQuantity.toString();
            newQuantity = currentQuantity;
            this.isZero = this.isZero.filter(item => item !== i.toString());
        } else if (!Number.isNaN(parseInt(cleanedInputValue)) && parseInt(cleanedInputValue) <= currentQuantity) {
            newQuantity = parseInt(cleanedInputValue);
            (event.target as HTMLInputElement).value = cleanedInputValue;
            this.isZero = this.isZero.filter(item => item !== i.toString());
        }

        this.cloneOrder[i].quantity = newQuantity;
        this.cloneOrder[i].selected = newQuantity > 0;
        this.cdr.detectChanges();
        setTimeout(() => {
            this.checkOrderIsChange();
        }, 0);
    }




    @HostListener('document:click', ['$event'])
    handleDocumentClick(event: MouseEvent) {
        const buttonElement =
            this._eref.nativeElement.querySelector('#btn-click');
        const datePickerElement =
            this._eref.nativeElement.querySelector('#ngb-datepicker');
        const clickedInsideButton = buttonElement
            ? buttonElement.contains(event.target)
            : null;
        const clickedInsideDatepicker = datePickerElement
            ? datePickerElement.contains(event.target)
            : null;

        if (
            !clickedInsideButton &&
            !clickedInsideDatepicker &&
            this.isDatePickerVisible
        ) {
            this.isDatePickerVisible = false;
        }
    }

    private deepCopy(source: SelectedPurchaseItem[]): SelectedPurchaseItem[] {
        return JSON.parse(JSON.stringify(source));
    }

    navigateToOrders() {
        this.router.navigate(['/stockist/order', this.orderNumber]);
    }
    dateFormat(): string {
        const selectedDate = this.selectedDate ?? new Date();
        const year = selectedDate.getFullYear();
        const month = ("0" + (selectedDate.getMonth() + 1)).slice(-2);
        const day = ("0" + selectedDate.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }
    navigateToOrderConfirm() {
        const isOrderChange = this.checkOrderIsChange();
        if (isOrderChange) {
            this.openModal();
        } else {
            this.isLoading = true;
            this.spinner.show('spinner');
            this.stockistService
                .confirmOrder(this.orderNumber, {
                    deliveryDate: this.dateFormat(),
                })
                .subscribe({
                    next: () => {
                        this.router.navigate([`stockist/order/${this.orderNumber}/processed/confirmed`]);
                        this.isLoading = false;
                        this.spinner.hide('spinner');
                    },
                    error: (err) => {
                        console.error('Error updating order status to confirmed', err);
                        this.isLoading = false;
                        this.spinner.hide('spinner');
                    },
                });
        }
    }

    getStockistOrderDetail() {
        this.stockistService
            .getPurchaseOrder(this.orderNumber)
            .subscribe((res) => {
                this.stockistId = res.stockistId;
                const stockistOrder = res
                this.stockistOrder = stockistOrder;
                this.purchaseOrderItem = this.transformItem(
                    stockistOrder.items
                );
                this.cloneOrder = this.deepCopy(this.purchaseOrderItem);
                this.cloneOrder
                    .filter((item) => item.selected)
                    .forEach((item) => {
                        this.pricePerUnit.push(item.amount / item.quantity);
                    });
            });
    }

    // TODO: waiting for write test
    openModal() {
        this.modalRef = this.modalService.open(ModalMessageComponent, {
            centered: true,
            windowClass: 'slide-from-top'
        });
        this.modalRef.componentInstance.modalConfig = {
            colorButton: ButtonTag.Success,
            title: this.translate.instant('ORDER_INFO.CONFIRMED.MODAL.TITLE'),
            message: this.translate.instant('ORDER_INFO.CONFIRMED.MODAL.MESSAGE'),
            confirmLabel: this.translate.instant('ORDER_INFO.CONFIRMED.MODAL.CONFIRM'),
            cancelLabel: this.translate.instant('ORDER_INFO.CONFIRMED.MODAL.CANCEL'),
        };
        this.modalRef.result.then(
            (confirm) => {
                if (confirm) {
                    const products: ProductInfo[] = [];
                    this.cloneOrder.forEach((x) => {
                        products.push({
                            id: x.id,
                            quantity: x.quantity,
                        });
                    });
                    this.isLoading = true;
                    this.spinner.show('spinner');
                    this.stockistService
                        .updateAndConfirmPurchaseOrder(this.orderNumber, {
                            deliveryDate: this.dateFormat(),
                            items: products,
                        } as OrderUpdateAndConfirm)
                        .subscribe({
                            next: (res) => {
                                this.router.navigate([`stockist/order/${res.orderId}/processed/confirmed`],);
                                this.isLoading = false;
                                this.spinner.hide('spinner');
                            }, error: (err) => {
                                console.error(err)
                                this.isLoading = false;
                                this.spinner.hide('spinner');
                            }
                        });
                }
            },
            (error) => {
                console.error('Error approving order cancellation', error);
            }
        );
        this.showModal = true;
    }

    isConfirmButtonDisabled(): boolean {
        const isSelectedDate = !!this.selectedDate;
        const isSelectedCountValid =
            this.cloneOrder.filter((item) => item.selected).length >= 1;
        return (!isSelectedDate || !isSelectedCountValid) || this.isZero.length >= this.cloneOrder.length;
    }

    handleImageError(event: Event) {
        const target = event.target as HTMLImageElement;
        target.src = 'assets/images/image_error.jpg';
    }
}
