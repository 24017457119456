import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-word',
  templateUrl: './header-word.component.html',
  styleUrls: ['./header-word.component.scss']
})
export class HeaderWordComponent {
  @Input() currentStep = 0
  @Input() step = 0
  @Input() word = ""

}
