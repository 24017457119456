import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-promotion-type-toggle',
  templateUrl: './promotion-type-toggle.component.html',
  styleUrls: ['./promotion-type-toggle.component.scss']
})
export class PromotionTypeToggleComponent implements OnInit{
  @Output() promotionTypeChange = new EventEmitter<string>();
  promotionType: "bundle" | "tier" = "bundle";

  ngOnInit(): void {
    this.promotionTypeChange.emit(this.promotionType)
  }


  switchPromotionType() {
    // Toggle between 'bundle' and 'tier'
    this.promotionType = this.promotionType === 'bundle' ? 'tier' : 'bundle';
    this.promotionTypeChange.emit(this.promotionType);
  }
}
