<div class="purchase-promotion">
    <div class="underline">
        <h5>{{ "DASHBOARD.PURCHASE_PROMOTION.TITLE" | translate }}</h5>
        <div class="pt-2 pb-2">
            <app-select-dropdown [options]="sortingOptions" [bindValue]="paramsMonthProduct.orderBy">
            </app-select-dropdown>
        </div>
    </div>
    <div>
        <table class="purchase-promotion-list">
            <tr class="header-row">
                <th class="promotion-image">{{ "DASHBOARD.PURCHASE_PROMOTION.IMAGE_HEADER" | translate }}</th>
                <th class="name">{{ "DASHBOARD.PURCHASE_PROMOTION.NAME_HEADER" | translate }}</th>
                <th class="quantity">{{ "DASHBOARD.PURCHASE_PROMOTION.QUANTITY_HEADER" | translate }}</th>
            </tr>
            <ng-container *ngFor="let promotion of monthlyPurchasePromotion">
                <tr>
                    <td class="promotion-image">
                        <img [src]="promotion.coverImagePathThump" alt="">
                    </td>
                    <td class="name">
                        <div class="truncated-text">{{ promotion.name }}</div>
                    </td>
                    <td class="quantity">
                        <div>{{ promotion.netAmount.toFixed(2) }}฿</div>
                        <div *ngIf="promotion.quantity" class="gray-text">{{ "DASHBOARD.PURCHASE_PROMOTION.QUANTITY_HEADER" | translate }} : {{ promotion.quantity }}</div>
                    </td>
                </tr>
                <tr *ngFor="let item of promotion.items" class="product-row">
                    <td class="product-image">
                        <img [src]="item.coverImagePathThump" alt="">
                    </td>
                    <td class="name">
                        <div class="mb-1 truncated-text">{{ item.name }}</div>
                        <div class="gray-text truncated-text">EAN : {{ item.ean }}</div>
                    </td>
                    <td class="quantity">
                        <div class="gray-text">
                            {{ "DASHBOARD.PURCHASE_PROMOTION.QUANTITY_HEADER" | translate }} : {{ item.quantity }}
                        </div>
                    </td>
                </tr>
            </ng-container>
            
            <!-- display items list row under promotion -->
        </table>
    </div>
</div>