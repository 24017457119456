<div class="toggle-button p-3">
    <button class=" button-switch w-100" style="height: 2.25rem;"
    [ngClass]="{'active-toggle': promotionType === 'bundle', 'inactive-toggle':promotionType === 'tier'}"
    (click)="switchPromotionType()">
    {{'PROMOTION.BUNDLE' | translate}}</button>

    <button class=" button-switch w-100" style="height: 2.25rem;"
    [ngClass]="{'active-toggle': promotionType === 'tier', 'inactive-toggle': promotionType === 'bundle'}"
    (click)="switchPromotionType()">
        {{'PROMOTION.TIER' | translate}}</button>
</div>
