export enum StockistOrderStatus {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  CANCELREQUESTED = 'CANCELREQUESTED',
  CANCELLED = 'CANCELLED',
  DELIVERED = 'DELIVERED',
  DEPRECATED = 'DEPRECATED',

}
export enum StockistOrderStatusBar {
  All = "All",
  Pending = "Pending",
  Confirmed = "Confirmed",
  CancelRequested = "CancelRequested",
  Cancelled = "Cancelled",
  Delivered = 'Delivered',
}

export enum CancelStatus {
  RETAILER_CANCEL = "RetailerCancel",
  STOCKIST_CANCEL = "StockistCancel",
}

export enum TabProductShelfStatus {
  All = 'All',
  Active = 'Active',
  InActive = 'Inactive',
}

export enum TabStatus {
  All = 'All',
  Active = 'Active',
  InActive = 'Inactive',
}




