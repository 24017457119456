import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'displayDate',
})
export class DisplayDatePipe implements PipeTransform {
    constructor( private translate: TranslateService) {}
    transform(value: string, noDayName?: boolean): string {

        if (!this.translate) {
            return '';
        }

        const local = this.translate.currentLang;
        const datePipe = new DatePipe(local, 'GMT+7');
        const formattedDate = datePipe.transform(value, 'EEEEE dd MMM yy HH:mm');

        if (!formattedDate) {
        return '';
        }

        const thaiMonths = [
        'ม.ค.',
        'ก.พ.',
        'มี.ค.',
        'เม.ย.',
        'พ.ค.',
        'มิ.ย.',
        'ก.ค.',
        'ส.ค.',
        'ก.ย.',
        'ต.ค.',
        'พ.ย.',
        'ธ.ค.',
        ];

        const thaiDay = [
            'อา',
            'จ',
            'อ',
            'พ',
            'พฤ',
            'ศ',
            'ส',
        ]

        const EngDay = [
            'Sun',
            'Mon',
            'Tue',
            'Wed',
            'Thu',
            'Fri',
            'Sat',
        ]

        const EngMonths = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        const isThai = this.translate.currentLang === 'th';
        const parts = formattedDate.split(' ');
        const day = new Date(value);
        const dayOfWeek = day.getDay();
        const DayName = isThai ? thaiDay[dayOfWeek] : EngDay[dayOfWeek];
        const Month = isThai ? thaiMonths[new Date(value).getMonth()] : EngMonths[new Date(value).getMonth()];
        const thaiYear = isThai ? (+parts[3] + 43).toString() : (+parts[3]).toString();
        const textTime = isThai ? ' น.' : ''

        return `${(!noDayName ? DayName : '')}  ${parts[1]} ${Month} ${thaiYear} ${','} ${parts[4]} ${textTime}`;
    }
}
