import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIResponse } from '@core/models/shares/http.model';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { ConfigService } from '../shared/config.service';
import { PromotionListResponse, PromotionParams } from '@core/models/stockist/promotion.model';
import { ClearParams } from '@modules/shared/clear-params';

@Injectable({
    providedIn: 'root',
})
export class PromotionService {
    baseURL = `${environment.apiAdminUrlv2}/v2/promotion/promotions`;

    constructor(private http: HttpClient,
        private configService: ConfigService
    ) { }

    getPromotionList(params: PromotionParams): Observable<APIResponse<PromotionListResponse[]>> {
        return this.http.get<APIResponse<PromotionListResponse[]>>(this.baseURL, {
            params: ClearParams(params),
        })
    }
}
