<div class="additional-privilege">
    <button class="accordion" (click)="toggle()" [class.active]="activePanels">
        <span class="title fw-semibold">{{ "ORDER_INFO.ADDITIONAL.TITLE" | translate }}</span>
        <span class="symbol">
            <i class="fa-regular fa-angle-down down" [style.transform]="activePanels ? 'rotate(180deg)' : 'none'"></i>
        </span>
    </button>
    <div #panelContent class="panel" [ngStyle]="{ maxHeight: activePanels ? panelScrollHeight + 'px' : '0px' }">
        <div>
            <div class="mb-3" *ngIf="additionalGiveAways?.length">
                <div class="title mb-2">{{ "ORDER_INFO.ADDITIONAL.GIVEAWAY_TITLE" | translate }}</div>
                <div *ngFor="let giveAway of additionalGiveAways">
                    <div class="give-away-item">
                        <span class="name">
                            {{currentLang === 'th' ? giveAway.name : giveAway.nameEN}}
                        </span>
                        <span>x{{giveAway.quantity}}</span>
                    </div>
                </div>
            </div>
            <div class="mb-3" *ngIf="additionalDiscounts?.length">
                <div class="title mb-2">{{ "ORDER_INFO.ADDITIONAL.DISCOUNT_TITLE" | translate }}</div>
                <div *ngFor="let discount of additionalDiscounts">
                    <div class="give-away-item">
                        <span>{{ "ORDER_INFO.ADDITIONAL.FROM_PROMOTION" | translate }}{{discount.name}}</span>
                        <span>-{{discount.discount.toFixed(2)}}฿</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>