<div *ngIf="stockistOrder" style="padding-top: 1.5rem; overflow-x: hidden;" >
    <nav class="navigate-bar ">
        <div class="back-container" (click)="goBack()">
            <i class="fa-solid fa-arrow-left" aria-hidden="true"></i>
            <label for="backforward">{{ "ORDER_INFO.GO_BACK" | translate }}</label>
        </div>
    </nav>
        <div class="bg-gradient-order-pending mt-2">
            <div class="px-3 mb-1 d-flex justify-content-between align-items-center">
                <div class="row">
                    <label class="fs-sm order-title">{{ "ORDER_INFO.PENDING.ORDER_ID" | translate }}</label>
                    <label class="fw-bold">{{stockistOrder.id}}</label>
                </div>
                <div class="fs-sm fw-semibold order-status">
                    {{ "ORDER_INFO.PENDING.STATUS_PENDING" | translate}}
                </div>
            </div>
            <div class="pb-3 px-3 text-base font-black">
                {{ "ORDER_INFO.PENDING.ORDER_AT" | translate}} {{stockistOrder.createdAt | displayDate}}
            </div>
        </div>
        <div class="d-flex flex-column mx-3 pb-2">
            <app-ship-to-address [stockistOrder]="stockistOrder"/>
            <app-payment-to-address [stockistOrder]="stockistOrder"/>
            <div class="d-flex" [class.more-detail]="!expandDetails" [class.more-detail-active]="expandDetails"
                (click)="expandDetails = !expandDetails">
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                        <img class="detail-icon" [src]="detailIcon" alt="wallet-icon" />
                        {{ "ORDER_INFO.PENDING.ADDITIONAL_DETAILS" | translate}}
                    </div>
                    <i class="fa-regular fa-angle-down down"
                        [style.transform]="expandDetails ? 'rotate(180deg)' : 'none'"></i>
                </div>
                <div *ngIf="expandDetails" class="additional">
                    <div *ngFor="let detail of additionalDetails" class="additional-item">
                        <div class="text-xs">{{ detail.label }}</div>
                        <div style="font-weight: 500;">{{ detail.value }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="fw-semibold align-items-end">
                <div class="border-line-top"></div>
                <div class="mx-3">
                    <p>{{ "ORDER_INFO.PENDING.PRODUCT_LIST" | translate }}</p>
                    <app-product-list [products]="stockistOrder.items"></app-product-list>
                </div>
            </div>
            <div *ngIf="stockistOrder.additionalDiscounts.length > 0 || stockistOrder.additionalGiveAways.length > 0">
                <app-additional-privilege [additionalDiscounts]="stockistOrder.additionalDiscounts" [additionalGiveAways]="stockistOrder.additionalGiveAways"></app-additional-privilege>
            </div>
            <div class="mx-3">
                <app-payment [paymentInfo]="getPaymentInfo()"></app-payment>
            </div>
        </div>
        <div class="d-flex flex-column m-3 mb-4">
            <button type="button" class="btn confirm-order-btn" data-cy="confirm-order-btn"
                (click)="navigateToConfirmOrder()">
                {{ "ORDER_INFO.PENDING.CHECK_ORDER" | translate }}
            </button>
        </div>
</div>
