import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IOrderItemPromotionDetail, OrderUpdateAndConfirm } from '@core/models/stockist/order.model';
import { IEditTier, IValidateOrderItem, ValidateOrderResponse } from '@core/models/stockist/stockist.model';
import { StockistService } from '@core/services/stockist/purchase.service';
import { NgbDateStruct, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalMessageComponent } from '../modal-message/modal-message.component';
import { ButtonTag } from '@core/models/shares/enum';
import { TranslateService } from '@ngx-translate/core';
import { LabelMode } from '@modules/shared/_enums/label-mode.enum';
import { OrderDetailPromotionBundle } from '@core/models/stockist/order-detail.model';

@Component({
    selector: 'app-confirm-order-new',
    templateUrl: './confirm-order-new.component.html',
    styleUrls: ['./confirm-order-new.component.scss'],
})
export class ConfirmOrderNewComponent implements OnInit {
    orderNumber = '';
    isLoading = false;
    isResetActive = false;
    openCancelModal = false
    stockistId = '';
    orderData: ValidateOrderResponse = {
        id: '',
        totalAmount: 0,
        promotionDiscount: 0,
        couponDiscount: 0,
        shippingCost: 0,
        netAmount: 0,
        officeDays: '',
        officeTime: '',
        products: [],
        promotionBundles: [],
        promotionTiers: []
    };
    isDirty = false;
    private modalRef: NgbModalRef | undefined;
    showModal = false;
    selectedDate: Date | null = null;
    days: string[] = []
    thDays: string[] = ['จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส', 'อา'];
    enDays: string[] = ['M','T','W','Th','F','S','Su']
    colors: string[] = ['#21C063', '#21C063', '#21C063', '#21C063', '#21C063', '#21C063', '#21C063'];
    dateModel: NgbDateStruct = {
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
        day: new Date().getDay(),
    };
    currentDate: string;
    isDatePickerVisible = false;
    promotionBundlesRender: OrderDetailPromotionBundle[] = [];
    basicProductsRender: IOrderItemPromotionDetail[] = [];
    LabelMode: LabelMode = LabelMode.NO_LABEL;
    currentLang = 'th'
    
    constructor(
        private router: Router, 
        private activeRoute: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private stockistService: StockistService,
        private spinner: NgxSpinnerService,
        private modalService: NgbModal,
        private translate: TranslateService,
    ) {
        const date = new Date();
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = date.getDate().toString().padStart(2, '0');
        this.currentDate = `${year}-${month}-${day}`;
    }

    ngOnInit(): void {
        this.currentLang = this.translate.currentLang
        const isThai = this.translate.currentLang === 'th';
        this.days = isThai ? this.thDays : this.enDays
        this.activeRoute.params.subscribe((params) => {
            this.orderNumber = params['order-number'];
        });
        this.reStateaVlidateStockistOrder();
    }

    handleImageError(event: Event) {
        const target = event.target as HTMLImageElement;
        target.src = 'assets/images/image_error.jpg';
    }

    navigateToOrders() {
        this.router.navigate(['/stockist/order', this.orderNumber]);
    }

    resetToDefault() {
        window.location.reload();
    }
    onClickOpenCancelModal() {
        this.openCancelModal = true
    }
    onClickCloseModal() {
        this.openCancelModal = false
    }
    navigateToOrderConfirm() {
        // if (this.isDirty) {
        //     this.openEditOrderModal();
        // } 
        this.showLoading(true)
        this.stockistService.confirmOrder(this.orderNumber, {
            deliveryDate: this.dateFormat(),
            })
            .subscribe({
                next: () => {
                    this.router.navigate([`stockist/order/${this.orderNumber}/processed/confirmed`]);
                    this.showLoading(false)
                },
                error: (err) => {
                    console.error('Error updating order status to confirmed', err);
                    this.showLoading(false)
                },
            });
    }
    openEditOrderModal() {
        this.modalRef = this.modalService.open(ModalMessageComponent, {
            centered: true,
            windowClass: 'slide-from-top'
        });
        this.modalRef.componentInstance.modalConfig = {
            colorButton: ButtonTag.Success,
            title: this.translate.instant('ORDER_INFO.CONFIRMED.MODAL.TITLE'),
            message: this.translate.instant('ORDER_INFO.CONFIRMED.MODAL.MESSAGE'),
            confirmLabel: this.translate.instant('ORDER_INFO.CONFIRMED.MODAL.CONFIRM'),
            cancelLabel: this.translate.instant('ORDER_INFO.CONFIRMED.MODAL.CANCEL'),
        };
        this.modalRef.result.then(
            (confirm) => {
                if (confirm) {
                    // const products: ProductInfo[] = [];
                    // this.cloneOrder.forEach((x) => {
                    //     products.push({
                    //         id: x.id,
                    //         quantity: x.quantity,
                    //     });
                    // });
                    this.showLoading(true)
                    this.stockistService
                        .updateAndConfirmPurchaseOrder(this.orderNumber, {
                            deliveryDate: this.dateFormat(),
                            // items: products,
                        } as OrderUpdateAndConfirm)
                        .subscribe({
                            next: (res) => {
                                this.router.navigate([`stockist/order/${res.orderId}/processed/confirmed`],);
                                this.isLoading = false;
                                this.spinner.hide('spinner');
                            }, error: (err) => {
                                console.error(err)
                                this.isLoading = false;
                                this.spinner.hide('spinner');
                            }
                        });
                }
            },
            (error) => {
                console.error('Error approving order cancellation', error);
            }
        );
        this.showModal = true;
    }
    dateFormat(): string {
        const selectedDate = this.selectedDate ?? new Date();
        const year = selectedDate.getFullYear();
        const month = ("0" + (selectedDate.getMonth() + 1)).slice(-2);
        const day = ("0" + selectedDate.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }
    isDayActive(day: string, officeDays: string | undefined): boolean {
        if (!officeDays) {
          return false;
        }
        let d = ''
        if (day === 'Su' || day === 'อา'){ d = 'อา' }
        if (day === 'M' || day === 'จ'){ d = 'จ'}
        if (day === 'T' || day === 'อ'){ d = 'อ' }
        if (day === 'W' || day === 'พ'){ d = 'พ' }
        if (day === 'Th' || day === 'พฤ'){ d = 'พฤ' }
        if (day === 'F' || day === 'ศ'){ d = 'ศ' }
        if (day === 'S' || day === 'ส'){ d = 'ส' }
        return new Set(officeDays.split('|')).has(d);
    }
    dateSelect(event: { value: Date | null; }): void {
        this.selectedDate = event.value
        this.isDatePickerVisible = false;
    }
    formatTime(officeTime: string): string {
        if (officeTime) {
            const [startTime, endTime] = officeTime.split("-");
            const formattedStartTime = startTime.split(" ")[1].substring(0, 5);
            const formattedEndTime = endTime.split(" ")[1].substring(0, 5);
            return `${formattedStartTime}-${formattedEndTime}`;
        }
        return '';
    }
    getFormattedCurrency(amount: number | undefined): string {
        return amount !== undefined
            ? `${amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}฿`
            : '';
    }

    reStateaVlidateStockistOrder() {
        this.isResetActive = false
        this.stockistService.validateOrder({
            orderId: this.orderNumber,
            products: [],
            promotionBundles: [],
            promotionTiers: []
        } ).subscribe((res) => {
            this.stockistId = res.id
            this.orderData = res
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private editTimeout: any;
    editProduct(id: number, qty: number) {
        if (this.editTimeout) {
            clearTimeout(this.editTimeout);
        }

        const product = this.orderData.products.find(p => p.id === id);
        if (!product) {
            console.error(`Product with id ${id} not found.`);
            return
        }
        product.quantity = qty; 
        this.validateOrder()
    }

    editBundle(promotionId: number,itemId: number, qty: number) {
        if (this.editTimeout) {
            clearTimeout(this.editTimeout);
        }
        const bundle = this.orderData.promotionBundles.find(bundle => bundle.promotionId == promotionId)
        if (!bundle) {
            console.error(`promotionId with id ${promotionId} not found.`);
            return
        } 
        const item = bundle.items.find(item => item.id === itemId)
        if (!item) {
            console.error(`item with id ${itemId} not found.`);
            return
        }
        item.quantity = qty
        this.validateOrder()
    }

    editTier(itemId: number, productId: number, qty: number) {
        if (this.editTimeout) {
            clearTimeout(this.editTimeout);
        }
        const items = this.orderData.promotionTiers.find(tier => tier.purchaseOrderItemId == itemId)
        if (!items) {
            console.error(`item with id ${itemId} not found.`);
            return
        }
        const product = items.orderProducts.find(product => product.id == productId)
        if (!product) {
            console.error(`product with id ${itemId} not found.`);
            return
        }
        product.quantity = qty
        this.validateOrder()
    }

    validateOrder() {
        const products: IValidateOrderItem[] = this.orderData.products.flatMap(product => {
            return { purchaseOrderItemId: product.purchaseOrderItemId, quantity: product.quantity}
        })

        const promotionBundlesItems: IValidateOrderItem[] = this.orderData.promotionBundles.flatMap(bundle => {
            return bundle.items.map(item => ({
                purchaseOrderItemId: item.id, // Ensure `item.id` exists and matches
                quantity: item.quantity,     // Ensure `item.quantity` exists and matches
            }));
        });

        const promotionTierItems: IEditTier[] = this.orderData.promotionTiers.flatMap(tier => {
            return { 
                purchaseOrderItemId: tier.purchaseOrderItemId,
                products: tier.orderProducts.map(product => { return { orderProductId: product.id, quantity: product.quantity }}) 
            }
        })
        
        this.editTimeout = setTimeout(() => {
            this.isResetActive = true
            this.stockistService.validateOrder({
                orderId: this.orderNumber,
                products: products,
                promotionBundles: promotionBundlesItems,
                promotionTiers: promotionTierItems
            }).subscribe((res) => {
                this.showLoading(false)
                this.orderData = res
            });
        }, 500); // Adjust the delay time here bro
    }

    showLoading(show: boolean) {
        this.isLoading = show;
        if (show) {
            this.spinner.show('spinner');
            return
        }
        this.spinner.hide('spinner');
    }
}
