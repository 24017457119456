import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-add-promotion-main-form',
  templateUrl: './add-promotion-main-form.component.html',
  styleUrls: ['./add-promotion-main-form.component.scss']
})
export class AddPromotionMainFormComponent {
  currentPromotionType: 'bundle' | 'tier' = 'bundle';
  step = 0

  form: FormGroup

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({})
  }

  handlePromotionTypeChange(type: string) {
    this.currentPromotionType = type as 'bundle' | 'tier';
  }
}
