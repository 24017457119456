<div *ngFor="let productItem of productList?.items" class="main-container">
    <div class="container-title">
        <div class="product-image">
            <img alt="productImage" [src]="productItem.coverImagePathThumb" />
        </div>

        <div class="text-product-name">
            <p>{{productItem.name}}</p>
        </div>

        <div class="text-bundle-quantity">
            <p>x{{productItem.quantity}}</p>
        </div>
    </div>

    <div>
        <div *ngFor="let orderDetail of productItem?.orderProducts" class="container-detail">
            <div class="d-flex justify-content-between">
                <div class="item-detail">
                    <p>{{orderDetail.name}}</p>
                </div>
                
                <div class="item-detail">
                    <p>x{{orderDetail.quantity}}</p>
                </div>
            </div>
        </div>
    
        <div *ngFor="let orderDetailGift of productItem?.privilegeGiveAways">
            <div *ngIf="productItem.privilegeDiscount === null" class="d-flex justify-content-between">
                <div class="gift-detail">
                    <p>{{orderDetailGift.name}}</p>
                </div>
                
                <div class="gift-detail">
                    <p>x{{orderDetailGift.quantity}}</p>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="productItem.privilegeDiscount !== null" >
        <div class="price-per-bundle">
            <span>{{productItem.unitAmount}}฿/ชุด</span>
        </div>

        <div class="item-group">
            <div>
                <span>รวม</span>
            </div>
    
            <div class="d-flex gap-1">
                <span class="discount-original-price" *ngIf="productItem.privilegeDiscount">{{
                    formatCurrency(productItem.amount) }}
                    <span class="currency">{{getSymbol()}}</span>
                </span>

                <div *ngIf="productItem.privilegeDiscount !== null">
                    <span [ngStyle]="{ color: productItem.privilegeDiscount ? '#F40009' : '' }" class="discount-price">
                        <span>{{ formatCurrency(productItem.privilegeDiscount ? productItem.amount -
                            productItem.privilegeDiscount
                            :
                            productItem.amount).split('.')[0] }}</span>
                        <span class="decimal">.{{ formatCurrency(productItem.privilegeDiscount ? productItem.amount -
                            productItem.privilegeDiscount : productItem.amount).split('.')[1] }}</span>
                        <span class="currency">{{ getSymbol() }}</span>
                    </span>
                </div>

                <div *ngIf="productItem.privilegeDiscount === null">
                    <span>
                        {{ productItem.amount }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
