<div *ngFor="let productItem of productList" class="main-container">
    <div class="container-title">
        <div class="d-flex align-items-center gap-3">
            <div class="layout-image">
                <img class="product-image" alt="productImage" width="60px" height="60px"
                    [src]="productItem.coverImagePathThumb" onerror="this.src='assets/images/image_error.jpg'" />
            </div>

            <p class="text-product-name">{{ currentLang === 'th' ? productItem.name : productItem.nameEN ?
                productItem.nameEN : productItem.name}}</p>
        </div>


        <p *ngIf="productItem.promotionTierId === null" class="text-bundle-quantity">x{{productItem.quantity}}</p>
    </div>

    <div>
        <div *ngFor="let orderDetail of productItem?.orderProducts" class="container-detail">
            <div class="d-flex justify-content-between">
                <div class="item-detail">
                    <p>{{ currentLang === 'th' ? orderDetail.name : orderDetail.nameEN ? orderDetail.nameEN :
                        orderDetail.name}}</p>
                </div>

                <div class="item-detail">
                    <p>x{{orderDetail.quantity}}</p>
                </div>
            </div>
        </div>

        <div *ngFor="let orderDetailGift of productItem?.privilegeGiveAways">
            <div *ngIf="productItem.privilegeDiscount === null" class="d-flex justify-content-between">
                <div class="gift-detail">
                    <p>{{ currentLang === 'th' ? orderDetailGift.name : orderDetailGift.nameEN ? orderDetailGift.nameEN
                        : orderDetailGift.name}}</p>
                </div>

                <div class="gift-detail" *ngIf="productItem.promotionTierId === null">
                    <p>x{{orderDetailGift.quantity}}</p>
                </div>
            </div>
        </div>
    </div>

    <div>
        <div class="price-per-bundle">
            <span>{{ productItem.unitAmount | currency : "THB" : "" }}฿/{{ (productItem.promotionBundleId === null ?
                'PRODUCT_PROMOTION_LIST.UNIT' : 'PRODUCT_PROMOTION_LIST.SET') | translate }}</span>
        </div>

        <div class="item-group">
            <div>
                <span>{{ 'PRODUCT_PROMOTION_LIST.SUM' | translate }}</span>
            </div>

            <div class="d-flex gap-1">
                <span class="discount-original-price" *ngIf="productItem.privilegeDiscount">
                    {{ productItem.amount | currency : "THB" : "" }}฿
                </span>

                <div *ngIf="productItem.privilegeDiscount !== null">
                    <span [ngStyle]="{ color: productItem.privilegeDiscount ? '#F40009' : '' }" class="discount-price">
                        <span>{{ formatCurrency(productItem.privilegeDiscount ? productItem.amount -
                            productItem.privilegeDiscount : productItem.amount)}}฿</span>
                    </span>
                </div>

                <div *ngIf="productItem.privilegeDiscount === null">
                    <span class="price-normal">
                        {{ productItem.amount | currency : "THB" : "" }}฿
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>