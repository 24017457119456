import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PromotionListResponse, PromotionParams } from '@core/models/stockist/promotion.model';
import { TabStatus } from '@core/models/stockist/stockist-enum';
import { PromotionService } from '@core/services/stockist/promotion.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-promotion-list',
  templateUrl: './promotion-list.component.html',
  styleUrls: ['./promotion-list.component.scss']
})
export class PromotionListComponent implements OnInit {

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private promotionService: PromotionService
  ) {
  }

  params: PromotionParams = {
    status: TabStatus.Active,
    limit: 10,
    page: 1,
  };
  isLoading = false;
  totalPromotionQuantity = 0;
  totalPage = 0;
  promotionList: PromotionListResponse[] = [];


  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.spinner.show('spinner');
    this.isLoading = true;

    this.promotionService.getPromotionList(this.params).subscribe({
      next: (res) => {
        this.totalPromotionQuantity = res.total
        this.totalPage = res.totalPage
        const promotions = res.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
            status: item.status,
            showStatus: this.checkIsPromotionValid(
              item.startDate,
              item.endDate,
              item.status,
            ),
            coverImagePathThumb: item.coverImagePathThumb,
          };
        })
        this.promotionList.push(...promotions);
        this.spinner.hide('spinner');
        this.isLoading = false;
      },
      error: () => this.isLoading = false,
    });
  }

  getStatusName(status: string) {
    return status === TabStatus.Active ? this.translate.instant('TAB_STATUS.ACTIVE') : this.translate.instant('TAB_STATUS.INACTIVE');
  }

  search(value: string): void {
    this.promotionList = [];
    this.params.keyword = value;
    this.params.page = 1;
    this.loadData();
  }

  onTabChange(value: string): void {
    this.promotionList = [];
    this.params.page = 1;
    this.params.status = value == "All" ? "" : value
    this.loadData();
  }

  onScroll() {
    if (
      this.params?.page && this.params?.page < this.totalPage
    ) {
      this.params.page++;
      this.loadData();
    }
  }

  onImageError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = 'assets/images/image_error.jpg';
  }

  navigate(path: string) {
    this.router.navigate([path]);
  }

  checkIsPromotionValid(
    startDate?: string,
    endDate?: string,
    status?: string,
  ) {
    if (!startDate || !endDate || !status) {
      return "";
    }
    const validFrom = new Date(startDate);
    const validTo = new Date(endDate);
    const today = new Date();

    if (status === "Active" && today < validFrom) {
      return this.translate.instant("PROMOTION_LIST.STATUS_WAITING");
    }
    if (status === "Active" && validFrom <= today && today < validTo) {
      return this.translate.instant("PROMOTION_LIST.STATUS_ACTIVE");
    }
    if (today >= validTo) {
      status === "Inactive";
      return this.translate.instant("PROMOTION_LIST.STATUS_EXPIRED");
    }
    if (status === "Inactive") {
      return this.translate.instant("PROMOTION_LIST.STATUS_INACTIVE");
    }
    return "";
  }
}
