import { Component, OnInit } from '@angular/core';

const mockData = {
  stockistId: 35,
  id: 1,
  status: "Pending",
  stockistName: "มดค้าส่ง &#x1f41c;",
  retailerName: "ฝนค้าปลีก &#x1f327;️",
  retailerAddress: "112",
  retailerProvince: "สุรินทร์",
  retailerDistrict: "เมืองสุรินทร์",
  retailerSubdistrict: "ในเมือง",
  retailerPostCode: "32000",
  billingAddress: "112",
  billingProvince: "สุรินทร์",
  billingDistrict: "เมืองสุรินทร์",
  billingSubdistrict: "ในเมือง",
  billingPostCode: "32000",
  createdAt: "2024-11-11T12:01:39.6322235",
  taxid: "9834657823649",
  mobilePhoneNumber: "0666676686",
  secondaryPhoneNumber: "",
  cancelRequest: false,
  refOrderId: null,
  deliveryDate: null,
  cancelBy: null,
  officeDays: "อ|พ|จ|พฤ|ศ|ส|อา",
  officeTime: "10/17/2024 06:00:00-10/17/2024 22:30:00",
  latitude: 15.017027,
  longitude: 103.72645,
  amount: 1000,
  promotionDisocunt: 200,
  totalAfterPromotionDiscount: 800,
  couponDiscount: 99,
  totalAfterCouponDiscount: 701,
  shippingCost: 50,
  netAmount: 751,
  items:  [{
      id: 1,
      productId: null,
      promotionBundleId: 1,
      promotionTierId: null,
      name: "โค้ก 1.25 ลิตร แพ็ค 12 ขวด และ แฟนต้า ทุกรสชาติ 330 มล. แพ็ค 12 ขวด",
      nameEn: "xxxx",
      coverImagePathThumb: "https://wabi2b.z23.web.core.windows.net/preview/Product/210932/CoverImage/c8477e97-a718-4d22-8e68-2b87a04510aa.PNG",
      quantity: 2,
      amount: 1840.00,
      unitAmount: 920.00,
      privilegeDiscount: 40.00,
      orderProducts: [
                  {
                      quantity: 1,
                      name: "โค้ก น้ำอัดลม 1.25 ลิตร แพ็ค 12 ขวด",
                      nameEn: "xxxx"
                      
                  },
                  {
                    quantity: 2,
                    name: "แฟนต้า น้ำอัดลม กลิ่นสตรอเบอร์รี่ 330 มล. แพ็ค 12 ขวด",
                    nameEn: "xxxx"
                    
                  },
                  {
                    quantity: 1,
                    name: "แฟนต้า น้ำอัดลม กลิ่นฟรุตพันช์ 330 มล. แพ็ค 12 ขวด",
                    nameEn: "xxxx"
                    
                  },
                  {
                    quantity: 1,
                    name: "แฟนต้า น้ำอัดลม กลิ่นส้ม 330 มล. แพ็ค 12 ขวด",
                    nameEn: "xxxx"
                    
                }
              ],
      privilegeGiveAways: [
                  {
                      quantity: 1,
                      name: "แถม! แก้วน้ำพรีเมี่ยมสีแดงๆ",
                      nameEn: "xxxx"
                      
                  }
                ],
    },
    {
        name: "แฟนต้า น้ำอัดลม กลิ่นสตรอเบอร์รี่ 330 มล. แพ็ค 12 ขวด",
        nameEn: "xxxx",
        coverImagePathThumb: "https://wabi2b.z23.web.core.windows.net/preview/Product/211363/CoverImage/d50f9f11-124f-4b07-a9d3-c49a335a38d2.PNG",
        quantity: 2,
        amount: 1840.00,
        id: 2,
        productId: null,
        promotionBundleId: null,
        promotionTierId: null,
        unitAmount: 920.00,
        privilegeDiscount: null,
        orderProducts: [
                ],
        privilegeGiveAways: [
                  ],
        
    }
  ] 
}

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit{
  productList?: any

  ngOnInit(): void {
    this.loadData()
  }

  loadData() {
    this.productList = mockData
  }

  formatCurrency(amount?: number) {
    amount = amount ?? 0

    const currencyAmount = new Intl.NumberFormat('th-TH', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
    return currencyAmount
  }

  getSymbol() {
    const currencyFormatter = new Intl.NumberFormat('en', {
      style: 'currency',
      currency: 'THB',
      currencyDisplay: 'narrowSymbol',
    });
    return currencyFormatter.formatToParts(1).find(part => part.type === 'currency')?.value || '';
  }
}
