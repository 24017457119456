/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.scss']
})
export class SelectDropdownComponent {
  @Input() options: any[] = [];
  @Input() bindValue: any;
  @Input() label = '';
  @Output() valueChange = new EventEmitter<any>();
}