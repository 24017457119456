/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserServices } from '@core/services/auth/user.service';
import { CartService } from '@core/services/retailer/cart.service';
import { NotificationService } from '@core/services/stockist/notification.service';
import { environment } from '@environments/environment';
import { Subscription } from 'rxjs';

declare global {
    interface Window {
        zE: any;
    }
}

@Component({
    selector: 'app-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit, OnDestroy {
    cartItemsCount = 0;
    typeUser = '';
    notificationCount = 0;
    private itemCountSubscription: Subscription;
    private cartChangedSubscription: Subscription;
    currentEnv = '';

    constructor(
        private router: Router,
        private authUserService: UserServices,
        private cartService: CartService,
        private notificationService: NotificationService
    ) {
        this.itemCountSubscription =
            this.cartService.currentItemCount.subscribe(
                (count) => (this.cartItemsCount = count)
            );
        this.cartChangedSubscription = this.cartService.cartChanged.subscribe(
            () => this.cartService.getCart().subscribe()
        );

        this.notificationService.getNotificationCount().subscribe({
            next: (res) => {
                this.notificationCount = res.unreadNotification;
            }
        })
    }

    ngOnInit() {
        this.currentEnv = environment.name
        this.getUserType();
    }

    ngOnDestroy() {
        this.itemCountSubscription.unsubscribe();
        this.cartChangedSubscription.unsubscribe();
    }

    getUserType() {
        const user = localStorage.getItem('user') || '';
        if (user) {
            this.typeUser = JSON.parse(user).profile.type;
        }
    }

    getDisplayedCartItemsCount(): number {
        return this.cartItemsCount > 999 ? 999 : this.cartItemsCount;
    }

    goToCart() {
        this.router.navigate(['/retailer/my-cart']);
    }

    goToNotification() {
        this.router.navigate(['/stockist/my-notification']);
    }


    imageLineLogo = 'assets/images/LINE_Brand_icon.png';

    // TODO: write test case
    logout() {
        this.authUserService.clearUser();
        this.router.navigate(['/auth']);
    }

    openChat() {
        const scriptId = 'ze-snippet';

        if (!document.getElementById(scriptId)) {
            const script = document.createElement('script');
            script.id = scriptId;
            script.src = `${environment.zendeskUrl}`;

            script.onload = function () {
                if (window.zE) {
                    window.zE('messenger', 'open');
                }
            };

            document.body.appendChild(script);
        } else if (window.zE) {
            window.zE('messenger', 'open');
        }
    }
}
