/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ButtonTag } from '@core/models/shares/enum';
import { CreateStockistProductPayload, StockistProductDetail } from '@core/models/stockist/stockist-product-shelf';
import { ProductShelfService } from '@core/services/stockist/product-shelf.service';
import { SetError } from '@core/utils/set-error.validator';
import { ModalMessageComponent } from '@modules/main/pages/stockist/components/modal-message/modal-message.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

interface shelfManagement {
  price: number;
  minimumUnit: number;
}
@Component({
  selector: 'app-modal-edit-shelf',
  templateUrl: './modal-edit-shelf.component.html',
  styleUrls: ['./modal-edit-shelf.component.scss']
})
export class ModalEditShelfComponent implements OnInit {
  @Input({ required: true }) productId!: number;
  @Input() isOpenModal!: boolean;
  @Output() isOpenModalChange = new EventEmitter<boolean>();
  price = '';
  minimumUnit = '';
  error = '';
  editShelfForm: FormGroup;
  @Output() modalClose = new EventEmitter<boolean>();
  @Input() isOpen!: boolean;
  Errors: Partial<shelfManagement> = {};
  data: StockistProductDetail | undefined;
  private modalRef: NgbModalRef | undefined;
  old_status = '';
  constructor(
    private translate: TranslateService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private productShelfService: ProductShelfService,
    private modalService: NgbModal,
  ) {
    this.editShelfForm = this.fb.group({
      statusCheckbox: [false],
      price: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      minimumUnit: ['', [Validators.required, Validators.pattern('^[0-9]+$'), Validators.min(1)]],
      status: ['Inactive']
    });

  }

  ngOnInit(): void {
    this.getStockistproductDetail()
    this.editShelfForm.get('status')?.valueChanges.subscribe((status) => {
      if (status === 'Active') {
        // If status is ACTIVE, check the checkbox
        this.editShelfForm.get('statusCheckbox')?.setValue(true, { emitEvent: false });
      } else {
        // Otherwise, uncheck the checkbox
        this.editShelfForm.get('statusCheckbox')?.setValue(false, { emitEvent: false });
      }
    });
    this.editShelfForm.get('statusCheckbox')!.valueChanges.subscribe((checked) => {

      if (checked === true) {
        this.editShelfForm.patchValue({
          status: 'Active',
        });
      } else {
        if (this.old_status === 'Active') {
          this.openModalMessage().then((result) => {
            if (result) {
              this.editShelfForm.patchValue({
                status: 'Inactive',
              });
              if (this.data) {
                const payload = {
                  productID: this.data?.product.id,
                  price: this.editShelfForm.value.price,
                  minimumUnit: this.editShelfForm.value.minimumUnit,
                  status: this.editShelfForm.value.status
                }

                this.productShelfService.updateStckistProductById(payload.productID, payload).subscribe({
                  next: () => {
                    this.isOpenModal = false;
                    this.isOpenModalChange.emit(this.isOpenModal);
                  },
                  error: (err) => {
                    console.error("เกิดข้อผิดพลาด:", err);
                  }
                });
              }
            } else {
              this.editShelfForm.patchValue({
                status: 'Active',
              });
            }
          })
        }
      }
    });

  }

  getStockistproductDetail() {
    this.productShelfService.getStockistproductDetail(this.productId).subscribe(
      (res) => {
        this.data = res;
        this.old_status = res.status;
        this.editShelfForm.patchValue({
          statusCheckbox: res.status == 'Active' ? true : false,
          price: res.price,
          minimumUnit: res.minimumUnit || '',
          status: res.status || 'Inactive'
        });
      }
    )
  }

  close() {
    this.isOpenModal = false;
    this.isOpenModalChange.emit(this.isOpenModal);
  }
  handleImageError(event: Event) {
    const target = event.target as HTMLImageElement;
    target.src = 'assets/images/image_error.jpg';
  }
  async onSubmit() {
    this.Errors = {}


    if (this.editShelfForm.invalid) {
      this.Errors = SetError(this.editShelfForm)
      return
    }
    let payload: CreateStockistProductPayload
    const confirm = true
    if (this.data) {
      if (confirm) {
        payload = {
          productID: this.data?.product.id,
          price: this.editShelfForm.value.price,
          minimumUnit: this.editShelfForm.value.minimumUnit,
          status: this.editShelfForm.value.status
        }
        if (this.data?.id == null) {
          this.productShelfService.createStockistProduct(payload).subscribe({
            next: () => {
              // window.location.reload();
              this.isOpenModal = false;
              this.isOpenModalChange.emit(this.isOpenModal);
            },
            error: (err) => {
              console.error("เกิดข้อผิดพลาด:", err);
            }
          });

        } else {
          this.productShelfService.updateStckistProductById(payload.productID, payload).subscribe({
            next: () => {
              // window.location.reload();
              this.isOpenModal = false;
              this.isOpenModalChange.emit(this.isOpenModal);
            },
            error: (err) => {
              console.error("เกิดข้อผิดพลาด:", err);
            }
          });

        }

        this.close()
      } else {
        return;
      }
    }

  }

  openModalMessage(): Promise<boolean> {
    this.modalRef = this.modalService.open(ModalMessageComponent, {
      centered: true,
      windowClass: 'slide-from-top'
    });
    this.modalRef.componentInstance.modalConfig = {
      colorButton: ButtonTag.Danger,
      title: this.translate.instant('MODAL_SHELF.TITLE'),
      message: this.translate.instant('MODAL_SHELF.MESSAGE'),
      confirmLabel: this.translate.instant('MODAL_SHELF.CONFIRM'),
      cancelLabel: this.translate.instant('MODAL_SHELF.CANCEL'),
    };
    return this.modalRef.result.then(
      (confirm) => {
        return confirm
      },
      (error) => {
        console.error(error)
        return false
      }
    );
  }
}
