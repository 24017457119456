<div class="main-content" style="padding-top: 1.5rem;">
    <nav class="navigate-bar">
        <div class="back-container" (click)="navigateToOrders()">
            <i class="fa-solid fa-arrow-left" aria-hidden="true"></i>
            <label for="backforward"> {{ "DASHBOARD.GO_BACK" | translate }}</label>
        </div>
    </nav>
    <ngx-spinner bdColor="rgb(0,0,0,0.07)" size="medium" color="#d1f0de" type="ball-clip-rotate" [fullScreen]="true" name="spinner" style="margin-top: 50px !important;"></ngx-spinner>
    <div class="text-title-day underlines pt-3">
        <label>{{ "DASHBOARD.TITLE_DAY" | translate }}</label>
    </div>
    <div class="pt-3">
        <div class="content view-laptop">
            <div class="content-list">
                <div class="list-start">
                    <h2>{{dailySummary?.totalOrder | number: '1.0-0'}}</h2>
                    <label class="text-list">{{ "DASHBOARD.ORDER_ALL" | translate }}</label>
                </div>
                <div class="list-body">
                    <h2>{{dailySummary?.pendingOrder | number: '1.0-0'}}</h2>
                    <label class="text-list">{{ "DASHBOARD.STATUS_PENDING" | translate }}</label>
                </div>
                <div class="list-body">
                    <h2>{{dailySummary?.confirmedOrder | number: '1.0-0'}}</h2>
                    <label class="text-list">{{ "DASHBOARD.STATUS_CONFIRMED" | translate }}</label>
                </div>
                <div class="list-body">
                    <h2>{{dailySummary?.cancelRequestedOrder | number: '1.0-0'}}</h2>
                    <label class="text-list">{{ "DASHBOARD.STATUS_CANCEL_REQUEST" | translate }}</label>
                </div>
                <div class="list-body">
                    <h2>{{dailySummary?.cancelledOrderByRetailer | number: '1.0-0'}}</h2>
                    <label class="text-list">{{ "DASHBOARD.STATUS_CANCEL_BY_RETAILER" | translate }}</label>
                </div>
                <div class="list-body">
                    <h2>{{dailySummary?.cancelledOrderByStockist | number: '1.0-0'}}</h2>
                    <label class="text-list">{{ "DASHBOARD.STATUS_CANCEL_BY_STOCKIST" | translate }}</label>
                </div>
                <div class="list-end">
                    <h2>{{dailySummary?.deliveredOrder | number: '1.0-0'}}</h2>
                    <label class="text-list">{{ "DASHBOARD.STATUS_DELIVERED" | translate }}</label>
                </div>
            </div>
        </div>
        <div class="content view-mobile">
            <div class="list-start">
                <h2>{{dailySummary?.totalOrder | number: '1.0-0'}}</h2>
                <label class="text-list">{{ "DASHBOARD.ORDER_ALL" | translate }}</label>
            </div>
            <div class="d-flex">
                <div class="list-body">
                    <div class="pb-5">
                        <h2>{{dailySummary?.pendingOrder | number: '1.0-0'}}</h2>
                        <label class="text-list">{{ "DASHBOARD.STATUS_PENDING" | translate }}</label>
                    </div>
                    <div class="pb-5">
                        <h2>{{dailySummary?.cancelRequestedOrder | number: '1.0-0'}}</h2>
                        <label class="text-list">{{ "DASHBOARD.STATUS_CANCEL_REQUEST" | translate }}</label>
                    </div>
                    <div>
                        <h2>{{dailySummary?.cancelledOrderByStockist | number: '1.0-0'}}</h2>
                        <label class="text-list">{{ "DASHBOARD.STATUS_CANCEL_BY_STOCKIST" | translate }}</label>
                    </div>
                </div>
                <div class="list-body-end">
                    <div class="pb-5">
                        <h2>{{dailySummary?.confirmedOrder | number: '1.0-0'}}</h2>
                        <label class="text-list">{{ "DASHBOARD.STATUS_CONFIRMED" | translate }}</label>
                    </div>
                    <div class="pb-5">
                        <h2>{{dailySummary?.cancelledOrderByRetailer | number: '1.0-0'}}</h2>
                        <label class="text-list">{{ "DASHBOARD.STATUS_CANCEL_BY_RETAILER" | translate }}</label>
                    </div>
                    <div>
                        <h2>{{dailySummary?.deliveredOrder | number: '1.0-0'}}</h2>
                        <label class="text-list">{{ "DASHBOARD.STATUS_DELIVERED" | translate }}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="text-title-month view-laptop">
        <div class="underlines" style="align-items: baseline;">
            <label>{{ "DASHBOARD.TITLE_MONTH" | translate }}</label>
            <div class="input-group validated" style="width: 350px;">
                <div class="input-group-append">
                  <ng-container>
                    <span  class="input-group-text" (click)="dp1.open()"
                      ><img id="btn-click" src="../../../../../../../assets/icons/calendar-black.svg">
                    </span>

                  </ng-container>
                </div>
                <input
                class="form-control"
                [matDatepicker]="dp1"
                (click)="dp1.open()"
                [formControl]="date"
                autocomplete="off"
                readonly
                />
                <mat-datepicker #dp1 startView="multi-year" (monthSelected)="setMonthAndYear($event, dp1)"
                    panelClass="example-month-picker">
                </mat-datepicker>
            </div>
        </div>
    </div>
    <div class="text-title-month view-mobile">
        <div class="underlines">
            <label>{{ "DASHBOARD.TITLE_MONTH" | translate }}</label>
        </div>
        <div class="input-group validated pt-3" style="width: 100%;">
            <div class="input-group-append">
              <ng-container>
                <span  class="input-group-text" (click)="dp2.open()"
                  ><img id="btn-click" src="../../../../../../../assets/icons/calendar-black.svg">
                </span>

              </ng-container>
            </div>
            <input
            class="form-control"
            [matDatepicker]="dp2"
            (click)="dp2.open()"
            [formControl]="date"
            readonly
            />
            <mat-datepicker #dp2 startView="multi-year" (monthSelected)="setMonthAndYear($event, dp2)"
                panelClass="example-month-picker">
            </mat-datepicker>
        </div>
    </div>

    <div class="pt-3">
        <div class="flex-container">
            <div *ngIf="!loading_data" class="left-panel">
                <div class="pb-3">
                    <div class="layout-list">
                        <label class="text-month-list">{{ "DASHBOARD.TOTAL_MONTHLY_AMOUNT" | translate }}</label>
                        <div class="left-panel-list">
                            <h2>{{(dataSummary.totalOrderAmount) | currency : "THB" : "symbol-narrow"}}</h2>
                            <div *ngIf="dataSummary.totalOrderAmountChangePercent!==0" class="cardPadding">
                                <label [ngStyle]="{'color': dataSummary.totalOrderAmountChangePercent<0 ? '#FF0202' : '#21C063'}">
                                {{ dataSummary.totalOrderAmountChangePercent<0 ? ('DASHBOARD.REDUCE' | translate) : ('DASHBOARD.PROGRESSIVE' | translate ) }}
                                {{ dataSummary.totalOrderAmountChangePercent >= 0 ? dataSummary.totalOrderAmountChangePercent.toFixed(2) + '%' : (dataSummary.totalOrderAmountChangePercent * -1).toFixed(2) + '%' }}
                                </label>
                            </div>
                            <div *ngIf="dataSummary.totalOrderAmountChangePercent===0" class="cardPadding">
                                <label style="color: #B5B5B5;">
                                    {{"DASHBOARD.UNALTERED" | translate}}
                                </label>
                            </div>
                        </div>
                        <div *ngFor="let showList of [
                            { text: ('DASHBOARD.TOTAL_ORDER' | translate), number: dataSummary.totalOrder, ChangePercent: dataSummary.totalOrderChangePercent },
                            { text: ('DASHBOARD.TOTAL_ORDER_DELIVERED' | translate), number: dataSummary.confirmedOrder, ChangePercent: dataSummary.confirmedOrderChangePercent },
                            { text: ('DASHBOARD.TOTAL_ORDER_CANCEL_BY_STOCKIST' | translate), number: dataSummary.cancelledOrderByStockist, ChangePercent: dataSummary.cancelledOrderByStockistChangePercent },
                        ]">
                        <label class="text-month-list">{{ showList.text }}</label>
                        <div class="left-panel-list">
                            <h2>{{ showList.number }}</h2>
                            <div *ngIf="showList.ChangePercent" class="cardPadding">
                                <label [ngStyle]="{'color': showList.ChangePercent<0 ? '#FF0202' : '#21C063'}">
                                    {{ showList.ChangePercent<0 ? ('DASHBOARD.REDUCE' | translate) : ('DASHBOARD.PROGRESSIVE' | translate ) }}
                                    {{ showList.ChangePercent >= 0 ? showList.ChangePercent.toFixed(2) + '%' : (showList.ChangePercent * -1).toFixed(2) + '%' }}
                                    </label>
                                </div>
                                <div *ngIf="showList.ChangePercent===0" class="cardPadding">
                                    <label style="color: #B5B5B5;">
                                        {{"DASHBOARD.UNALTERED" | translate}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <label class="text-month-list">{{"DASHBOARD.TOTAL_ORDER_CANCEL_BY_RETAILER" | translate}}</label>
                        <div class="d-flex gap-2">
                            <h2>{{dataSummary.cancelledOrderByRetailer}}</h2>
                            <div *ngIf="dataSummary.cancelledOrderByStockistChangePercent!==0" class="cardPadding">
                                <label [ngStyle]="{'color': dataSummary.cancelledOrderByStockistChangePercent<0 ? '#FF0202' : '#21C063'}">
                                {{ dataSummary.cancelledOrderByStockistChangePercent<0 ? ('DASHBOARD.REDUCE' | translate) : ('DASHBOARD.PROGRESSIVE' | translate ) }}
                                {{ dataSummary.cancelledOrderByStockistChangePercent >= 0 ? dataSummary.cancelledOrderByStockistChangePercent.toFixed(2) + '%' : (dataSummary.cancelledOrderByStockistChangePercent * -1).toFixed(2) + '%' }}
                                </label>
                            </div>
                            <div *ngIf="dataSummary.cancelledOrderByStockistChangePercent===0" class="cardPadding">
                                <label style="color: #B5B5B5;">
                                    {{"DASHBOARD.UNALTERED" | translate}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="layout-list">
                    <label class="text-month-list">{{"DASHBOARD.TOTAL_NEW_CUSTOMER" | translate}}</label>
                    <div class="left-panel-list">
                        <h2>{{dataSummary.newCustomer}}</h2>
                        <div *ngIf="dataSummary.newCustomerChangePercent!==0" class="cardPadding">
                            <label [ngStyle]="{'color': dataSummary.newCustomerChangePercent<0 ? '#FF0202' : '#21C063'}">
                            {{ dataSummary.newCustomerChangePercent<0 ? ('DASHBOARD.REDUCE' | translate) : ('DASHBOARD.PROGRESSIVE' | translate ) }}
                            {{ dataSummary.newCustomerChangePercent >= 0 ? dataSummary.newCustomerChangePercent.toFixed(2) + '%' : (dataSummary.newCustomerChangePercent * -1).toFixed(2) + '%' }}
                            </label>
                        </div>
                        <div *ngIf="dataSummary.newCustomerChangePercent===0" class="cardPadding">
                            <label style="color: #B5B5B5;">
                                {{"DASHBOARD.UNALTERED" | translate}}
                            </label>
                        </div>
                    </div>
                    <label class="text-month-list">{{"DASHBOARD.TOTAL_OLD_CUSTOMER" | translate}}</label>
                    <div class="d-flex gap-2">
                        <h2>{{dataSummary.returningCustomer}}</h2>
                        <div *ngIf="dataSummary.returningCustomerChangePercent!==0" class="cardPadding">
                            <label [ngStyle]="{'color': dataSummary.returningCustomerChangePercent<0 ? '#FF0202' : '#21C063'}">
                            {{ dataSummary.returningCustomerChangePercent<0 ? ('DASHBOARD.REDUCE' | translate) : ('DASHBOARD.PROGRESSIVE' | translate ) }}
                            {{ dataSummary.returningCustomerChangePercent >= 0 ? dataSummary.returningCustomerChangePercent.toFixed(2) + '%' : (dataSummary.returningCustomerChangePercent * -1).toFixed(2) + '%' }}
                        </label>
                        </div>
                        <div *ngIf="dataSummary.returningCustomerChangePercent===0" class="cardPadding">
                            <label style="color: #B5B5B5;">
                                {{"DASHBOARD.UNALTERED" | translate}}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="pt-3">
                    <div class="layout-list">
                        <label class="text-month-list pb-2">{{ "DASHBOARD.COUPON" | translate}}</label>
                        <h4 class="text-total">{{(dataSummary.totalCouponAmount) | currency : "THB" : "symbol-narrow"}}
                        </h4>
                    </div>
                </div>
            </div>
            <div class="right-panel">
                <div class="right-panel-coupon">
                    <div class="underline">
                        <h5>{{ "DASHBOARD.TITLE_ORDER" | translate }}</h5>
                        <div class="pt-2 pb-2">
                            <app-select-dropdown [options]="sortingOptions" [bindValue]="paramsMonthProduct.orderBy"
                                (valueChange)="onSortOptionChangeDatatable($event)">
                            </app-select-dropdown>
                        </div>
                    </div>
                    <div class="data-content margin-table pt-1">
                        <div class="margin-top">
                            <ng-container>
                                <app-datatable
                                *ngIf="monthlySummaryProduct"
                                [dataSource]="dataSource"
                                [dataColums]="dataColumn"
                                fieldImage="coverImagePathThump"
                                titleColumnImage="{{'DASHBOARD.PRODUCT_IMAGE' | translate}}"
                                [isEnabledColumnImage]="true"
                                [paginationOption]="true"
                                [disableColumnAction]="true"
                                ></app-datatable>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <ng-template #productNameTemplate let-data="data">
                    <div class="product-name-label">
                        <label>{{ currentLang === 'th' ? data.name : data.nameEN ? data.nameEN : data.name }}</label>
                        <span class="label-ean">EAN : {{ data.ean }}</span>
                    </div>
                </ng-template>
                <ng-template #unitAmountTemplate let-data="data">
                    <div class="product-name-label">
                        <label>{{ data.totalAmount | currency : "THB" : "symbol-narrow" }}</label>
                        <span class="label-ean">{{"DASHBOARD.QUANTITY" | translate}} : {{ data.totalUnit | number: '1.0-0' }}</span>
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="view-laptop">
            <div *ngIf="!loading_data" class="chart-containner d-flex gap-3">
                <div class="d-flex panel-chart-containner">
                    <div class="border-right" style="flex: 0 0 25%;">
                        <div class="pb-3">
                            <app-select-dropdown [options]="sortingOptionsByType" [bindValue]="selectedsortingOptionByType"
                                (valueChange)="onSortOptionTypeChange($event)">
                            </app-select-dropdown>
                        </div>
                        <div class="pb-3">
                            <app-select-dropdown [options]="sortingOptions" [bindValue]="selectedSortOption"
                                (valueChange)="onSortOptionChange($event)">
                            </app-select-dropdown>
                        </div>
                        <div *ngFor="let brand of brandData">
                            <div [style.background-color]="getBrandColor(brand)"
                                style="width: 12px; height: 12px; display: inline-block; margin-right: 5px;border-radius: 50%;gap: 12px;">
                            </div>
                            <span class="text-chart">{{ currentLang === 'th' ? brand.title : brand.titleEN }}</span>
                        </div>
                    </div>
                    <div class="w-100">
                        <app-charts [brandData]="brandData" [showByType]="showByType"></app-charts>
                    </div>
                </div>
            </div>
        </div>
        <div class="view-mobile">
            <div *ngIf="!loading_data" class="chart-containner d-flex gap-3">
                <div class="left-panel-chart">
                    <div class="pb-3">
                        <app-select-dropdown [options]="sortingOptionsByType" [bindValue]="selectedsortingOptionByType"
                            (valueChange)="onSortOptionTypeChange($event)">
                        </app-select-dropdown>
                    </div>
                    <div class="pb-3">
                        <app-select-dropdown [options]="sortingOptions" [bindValue]="selectedSortOption"
                            (valueChange)="onSortOptionChange($event)">
                        </app-select-dropdown>
                    </div>
                    <div class="category-content">
                        <div *ngFor="let brand of brandData">
                            <div [style.background-color]="getBrandColor(brand)"
                                style="width: 12px; height: 12px; display: inline-block; margin-right: 5px;border-radius: 50%;gap: 12px;">
                            </div>
                            <span class="text-chart">{{ currentLang === 'th' ? brand.title : brand.titleEN }}</span>
                        </div>
                    </div>
                </div>
                <div class="panel-chart-mobile" style="overflow-x: scroll;">
                    <app-charts [brandData]="brandData" [showByType]="showByType"></app-charts>
                </div>
            </div>
        </div>
    </div>
</div>
