<div class="additional-privilege">
    <button class="accordion" (click)="toggle()" [class.active]="activePanels">
        <span>สิทธิประโยชน์เพิ่มเติมจากการซื้อโปรโมชัน</span>
        <span class="symbol">
            <i class="fa-regular fa-angle-down down" [style.transform]="activePanels ? 'rotate(180deg)' : 'none'"></i>
        </span>
    </button>
    <div #panelContent class="panel" [ngStyle]="{ maxHeight: activePanels ? panelScrollHeight + 'px' : '0px' }">
        <div>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua.
        </div>
    </div>
</div>