import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PaymentInfo } from "@core/models/shares/modal.model";
import { StockistPurchaseOrderPromotionDetail } from "@core/models/stockist/stockist.model";
import { StockistService } from "@core/services/stockist/purchase.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-stockist-order-info-pending-new',
    templateUrl: './order-info-pending-new.component.html',
    styleUrls: ['./order-info-pending-new.component.scss'],
})
export class OrderInfoPendingNewComponent implements OnChanges  {

    orderNumber = "";
    @Input() stockistOrder!: StockistPurchaseOrderPromotionDetail ;
    isOpenReasonModal = false;
    expandDetails = false;

    iconMap = 'assets/images/map.png';
    walletIcon = 'assets/images/walllet-icon.png'
    detailIcon = 'assets/images/detail-icon.png'
    additionalDetails: { label: string; value: string }[] = [];

    orderIsHavePromotionGift = false

    constructor(
        private activeRoute: ActivatedRoute,
        private stockistService: StockistService,
        private router: Router,
        private modalService: NgbModal,
        private translate: TranslateService,
    ) {
    }

    // ngOnInit() {
        // this.stockistOrder.promotions.map((pr) => {
        //     pr.promotionType === "Gift"? this.orderIsHavePromotionGift = true : ""
        // })
    // }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["stockistOrder"] && changes["stockistOrder"].currentValue) {
            this.setAdditionalDetails();
        }
    }

    setAdditionalDetails() {
        this.additionalDetails = [
            { label: this.translate.instant('ORDER_INFO.PENDING.USER_NAME'), value: this.stockistOrder.retailerName },
            { label: this.translate.instant('ORDER_INFO.PENDING.TAX_ID'), value: this.stockistOrder.taxid },
            { label: this.translate.instant('ORDER_INFO.PENDING.PHONE_NUMBER'), value: this.stockistOrder.mobilePhoneNumber },
            { label: this.translate.instant('ORDER_INFO.PENDING.SECONDARY_NUMBER'), value: this.stockistOrder.secondaryPhoneNumber || "-" }
        ];
    }

    getTotalQuantity(): number {
        if (this.stockistOrder && this.stockistOrder.items) {
            const totalQuantity = this.stockistOrder.items.reduce(
                (total, item) => total + item.quantity,
                0
            );

            return totalQuantity;
        }

        return 0;
    }


    getTotalAmount(): number {
        if (this.stockistOrder && this.stockistOrder.items) {
            const totalQuantity = this.stockistOrder.items.reduce(
                (total, item) => total + item.amount,
                0
            );

            return totalQuantity;
        }

        return 0;
    }

    getNetAmount(): number {
        if (!this.stockistOrder) return 0
        const netAmount = this.stockistOrder.netAmount;
        return netAmount

    }

    getPaymentInfo(): PaymentInfo {
        return <PaymentInfo>{
            totalQuantity: this.getTotalQuantity(),
            totalAmount: this.getTotalAmount(),
            couponDiscount: this.stockistOrder.couponDiscount,
            promotionDiscount: this.stockistOrder.promotionDiscount,
            shippingCost: this.stockistOrder?.shippingCost,
            netAmount: this.getNetAmount(),
        };
    }

    navigateToConfirmOrder() {
        this.router.navigate(['/stockist/confirm-order', this.stockistOrder.id]);
    }

    navigateToCancellation() {
        // this.router.navigate(['/stockist/cancellation'], {
        //     queryParams: {
        //         purchaseOrderNumber: this.orderNumber,
        //         name: this.stockistOrder.retailerName,
        //     },
        // });
        this.isOpenReasonModal = true;
    }

    goBack(): void {
      const page = window.localStorage.getItem('page');
      if (page) {
        if (page === 'landing') {
          this.router.navigate(['/stockist/orders']);
        } else if (page === 'history') {
          this.router.navigate(['/stockist/order-history']);
        } else if (page === 'notification') {
          this.router.navigate(['/stockist/my-notification']);
        }
      } else {
        window.history.back();
        setTimeout(() => {
            window.location.reload()
        },100)
      }

    }
}
