<div class="promotion-container">
    <!-- navigate back section -->
    <nav class="navigate-bar">
        <div class="back-container" (click)="navigate('/stockist/orders')">
            <i class="fa-solid fa-arrow-left" aria-hidden="true"></i>
            <label for="backforward">{{ "ORDER_HISTORY.GO_BACK" | translate }}</label>
        </div>
    </nav>

    <!-- status bar and search section -->
    <div>
        <p class="title">{{ 'PROMOTION_LIST.TITLE' | translate}}</p>
        <app-status-bar (tabChange)="onTabChange($event)"></app-status-bar>
        <div class="promotion-search">
            <app-input-search placeholder="{{ 'ACTION.SEARCH' | translate }}"
                (searchChange)="search($event)"></app-input-search>
        </div>
    </div>

    <!-- promotion list section -->
    <div *ngIf="isLoading" class="overlay">
        <ngx-spinner bdColor="rgb(0,0,0,0)" size="medium" color="#21C063" type="ball-clip-rotate" [fullScreen]="false"
            name="spinner" style="margin-top: 0px !important;"></ngx-spinner>
    </div>
    <div *ngIf="!isLoading">
        <div class="px-3 px-md-4 total-quantity">{{totalPromotionQuantity}} {{ 'PROMOTION_LIST.PROMOTION_UNIT'
            |translate}}</div>
        <div class="mx-3 mt-2" infiniteScroll [infiniteScrollDistance]="0.1" [infiniteScrollThrottle]="10"
            (scrolled)="onScroll()">
            <div *ngFor="let promotion of promotionList" class="promotion-list-container">
                <div class="promotion-item">
                    <div class="img-promotion">
                        <img [src]="promotion.coverImagePathThumb" (error)="onImageError($event)">
                    </div>
                    <div class="promotion-text">
                        <span>{{ promotion.name }}</span>
                        <div class="d-flex justify-content-between mt-1">
                            <span
                                [class]="promotion.showStatus === 'ใช้ได้' ? 'promotion-available' : 'promotion-not-available'">{{promotion.showStatus}}</span>
                            <span [class]="promotion.status === 'Active' ? 'active-status' : 'inactive-status'">{{
                                getStatusName(promotion.status) }}</span>
                        </div>
                    </div>
                </div>
                <div class="btn icon-group">
                    <i ngClass="fa-solid fa-chevron-right"></i>
                </div>
            </div>
        </div>
    </div>

    <!-- add promotion button section -->
    <div class="fixed-bottom px-3 py-3">
        <button type="button" class="px-3 py-2 btn confirm-order-btn" (click)="navigate('/stockist/promotion/create')">
            {{ "PROMOTION_LIST.ADD" | translate }}
        </button>
    </div>
</div>