import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIResponse } from '@core/models/shares/http.model';
import { CancelOrderPayload } from '@core/models/stockist/confirm-order-cancellation.model';
import {
    IOrderDetail,
    IOrderResponse,
    OrderConfirm,
    OrderUpdateAndConfirm,
} from '@core/models/stockist/order.model';
import {
    ICheckManagedShelf,
    OrderAmount,
    StockistOrderStatusTotal,
    StockistPurchaseOrderDetail,
    StockistPurchaseOrderList,
    StockistPurchaseOrderParams,
    StockistPurchaseOrderPromotionDetail,
    ValidateOrderParams,
    ValidateOrderResponse,
} from '@core/models/stockist/stockist.model';
import { environment } from '@environments/environment';
import { ClearParams } from '@modules/shared/clear-params';
import { Observable } from 'rxjs';
import { ConfigService } from '../shared/config.service';

@Injectable({
    providedIn: 'root',
})
export class StockistService {
    baseURL = `${environment.apiUrl}/api/customer/stockist`;
    baseURLV2 = `${environment.apiUrl}/api/v2/customer/stockist`;


    constructor(private http: HttpClient,
        private configService: ConfigService
    ) { }

    getAmountMonth(): Observable<APIResponse<OrderAmount>> {
        return this.http.get<APIResponse<OrderAmount>>(
            `${this.baseURL}/orders/amount-month`
        );
    }

    getPurchaseOrders(
        params: StockistPurchaseOrderParams
    ): Observable<APIResponse<StockistPurchaseOrderList>> {
        const refreshToken = sessionStorage.getItem('refreshToken');
        return this.http.get<APIResponse<StockistPurchaseOrderList>>(
            `${environment.stockistAPI}/stockist/orders`,
            {
                headers: {
                    Authorization: `Bearer ${refreshToken}`,
                },
                params: ClearParams(params),
            }
        );
    }

    getPurchaseOrdersV2(
        params: StockistPurchaseOrderParams
    ): Observable<APIResponse<StockistPurchaseOrderList>> {
        const refreshToken = sessionStorage.getItem('refreshToken');
        return this.http.get<APIResponse<StockistPurchaseOrderList>>(
            `${environment.stockistAPIv2}/stockist/orders`,
            {
                headers: {
                    Authorization: `Bearer ${refreshToken}`,
                },
                params: ClearParams(params),
            }
        );
    }

    getStatusTotalOrder(): Observable<StockistOrderStatusTotal> {
        const refreshToken = sessionStorage.getItem('refreshToken');
        return this.http.get<StockistOrderStatusTotal>(
            `${environment.stockistAPI}/orders/status-total`,
            {
                headers: {
                    Authorization: `Bearer ${refreshToken}`,
                }
            }
        );
    }

    getStatusTotalOrderV2(): Observable<StockistOrderStatusTotal> {
        const refreshToken = sessionStorage.getItem('refreshToken');
        return this.http.get<StockistOrderStatusTotal>(
            `${environment.stockistAPIv2}/orders/status-total`,
            {
                headers: {
                    Authorization: `Bearer ${refreshToken}`,
                }
            }
        );
    }

    getPurchaseOrder(
        orderNumber: string
    ): Observable<StockistPurchaseOrderDetail> {
        return this.http.get<StockistPurchaseOrderDetail>(
            `${this.baseURL}/orders/${orderNumber}`
        );
    }

    getPurchaseOrderV2(
        orderNumber: string
    ): Observable<StockistPurchaseOrderPromotionDetail> {
        return this.http.get<StockistPurchaseOrderPromotionDetail>(
            `${this.baseURLV2}/orders/${orderNumber}`
        );
    }

    confirmOrder(
        orderNumber: string,
        data: OrderConfirm
    ): Observable<APIResponse> {
        return this.http.patch<APIResponse>(
            `${this.baseURL}/order/${orderNumber}/confirm`,
            data
        );
    }

    approveCancelOrder(orderNumber: string): Observable<APIResponse> {
        return this.http.patch<APIResponse>(
            `${this.baseURL}/order/${orderNumber}/approve`,
            null
        );
    }

    updateAndConfirmPurchaseOrder(
        orderNumber: string,
        orderConfirmItems: OrderUpdateAndConfirm
    ): Observable<IOrderResponse> {
        return this.http.patch<IOrderResponse>(
            `${this.baseURL}/order/${orderNumber}/edit`,
            orderConfirmItems
        );
    }

    rejectCancelOrder(orderNumber: string): Observable<void> {
        return this.http.patch<void>(
            `${this.baseURL}/order/${orderNumber}/reject`,
            null
        );
    }

    // --------------------------------------- New ----------------------------------------------- //

    getOrderDetail(id: string): Observable<IOrderDetail> {
        return this.http.get<IOrderDetail>(`${this.baseURL}/orders/${id}`);
    }

    updateStatusToDelivery(orderNumber: string): Observable<APIResponse> {
        return this.http.patch<APIResponse>(
            `${this.baseURL}/order/${orderNumber}/deliver`,
            null
        );
    }

    cancelOrder(
        orderNumber: string,
        cancelMessage: CancelOrderPayload
    ): Observable<APIResponse> {
        const url = this.configService.getApiUrl(`customer/stockist/order/${orderNumber}/cancel`)
        return this.http.patch<APIResponse>(
            url,
            cancelMessage
        );
    }

    checkManagedShelf(idList: number[]): Observable<ICheckManagedShelf[]> {
        return this.http.post<ICheckManagedShelf[]>(environment.apiUrl + '/product/stockist-products/managedshelf', idList)
    }

    validateOrder(params: ValidateOrderParams): Observable<ValidateOrderResponse> {
        return this.http.post<ValidateOrderResponse>(`${this.baseURLV2}/edit/orders/validate`, params);
    }
}
