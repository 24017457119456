import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-additional-privilege',
  templateUrl: './additional-privilege.component.html',
  styleUrls: ['./additional-privilege.component.scss']
})
export class AdditionalPrivilegeComponent {
  activePanels = false;
  panelScrollHeight = 0;

  // Get reference to the panel element
  @ViewChild('panelContent') panelContent!: ElementRef;

  toggle() {
    this.activePanels = !this.activePanels;

    if (this.activePanels) {
      // Set the scrollHeight dynamically when the panel is opened
      this.panelScrollHeight = this.panelContent.nativeElement.scrollHeight;
    }
  }

}
