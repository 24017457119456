import { Component, Input } from '@angular/core';
import { StockistPurchaseOrderDetail, StockistPurchaseOrderPromotionDetail } from '@core/models/stockist/stockist.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-payment-to-address',
  templateUrl: './payment-to-address.component.html',
  styleUrls: ['./payment-to-address.component.scss']
})
export class PaymentToAddressComponent {
  @Input() stockistOrder!: StockistPurchaseOrderDetail | StockistPurchaseOrderPromotionDetail;

  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang('th');
  }
  walletIcon = 'assets/images/walllet-icon.png'
}
