// USA
export const locale = {
    lang: 'en',
    data: {
        TRANSLATOR: {
            SELECT: 'Select your language',
        },

        LABEL_LIST: {
            NO_DATA: 'No data',
        },

        TAB_STATUS: {
            ALL: 'All',
            ACTIVE: 'Activate',
            INACTIVE: 'Inactivate'
        },

        PRODUCT: {
            PRODUCT: 'Product',
            BRAND_TITLE: 'Brand',
            PRODUCER_TITLE: 'Producer',
            CATEGORY_TITLE: 'Category',
            CREATE_PRODUCT: 'Create Product',
            CREATE_BRAND: 'Create Brand',
            CREATE_PRODUCER: 'Create Producer',
        },

        VALIDATOR: {
            REQUIRED: 'Please fill out the list',
            MIN: 'Please enter a larger value',
            MAX: 'Please enter a value less than this',
            EMAIL: 'Invalid email',
            MUST_MATCH: "Passwords don't match",
            PATTERN: 'Please specify in letters (a-z, A-Z, ก-ฮ) only.',
            MIN_LENGTH: 'Minimum number of characters',
            MAX_LENGTH: 'Maximum number of characters',
            LENGTH_VAR: 'Character',
            DUPLICATE: 'Duplicate Data',
            DUPLICATE_PHONE: 'This mobile number is already in use',
            DUPLICATE_TIME: 'Closing time is the same as opening time',
            MIN_LENGTH_PRODUCT: 'Minimum number of product',
            LENGTH_VAR_PRODUCT: 'Unit',
        },

        SIDE_BAR: {
            RETAILER: 'Retailer',
            STOCKIST: 'Wholesaler',
            PRODUCT: 'Product',
            ORDER: 'Order',
            COUPON: 'Coupon',
            PROMOTION: 'Promotion',
            LANGUAGE: 'language',
        },

        RETAILER_TITLE: {
            TITLE_ADD: 'Create New Retailer',
            TITLE_UPDATE: 'Retaile Details',
        },

        RETAILER: {
            PENDING: 'Pending',
            APPROVE: 'Confirmed',
            REJECT: 'Rejected',
            TITLE_REGISTRANT: 'Registrant Information',
            STATUS: 'Status',
            PERSION_IN_CHERT: 'Name of person in charge',
            INPUT_PERSION_IN_CHERT: 'Person in charge',
            NAME: 'Name',
            FIRST_NAME: 'First Name',
            INPUT_FIRST_NAME: 'Registered name',
            LAST_NAME: 'Last Name',
            TAX_ID: 'TAX ID',
            MOBILE_PHONE_NUMBER: 'Mobile Phone Number',
            SECONDARY_PHONE_NUMBER: 'Secondary Phone Number',
            EMAIL: 'Email',
            TITLE_STORE: 'Store Information',
            CUSTOMER_TYPE: 'Customer Type',
            STORE_TYPE: 'Store Type',
            OPEN_HOURS: 'Opening Hour',
            HOUSE: 'Hour',
            MIN: 'Min',
            TO: 'To',
            OPEN_DAY: 'Opening Date',
            TITLE_ADDRESS: 'Shipping Address',
            POST_CODE: 'Post Code',
            SUB_DISTRICT: 'Sub District',
            DISTRICT: 'District',
            PROVINCE: 'Province',
            ADDRESS: 'Address',
            INPUT_ADDRESS: 'Address',
            SEARCH_LOCATION: 'Search Location',
            CURRENT_LOCATION: 'Current Location',
            TITLE_BILLING_ADDRESS: 'Billing address',
            USE_ADDRESS: 'Use this address as a billing address',
            BILLING_POST_CODE: 'Billing Post Code',
            BILLING_SUB_DISTRICT: 'Billing Sub District',
            BILLING_DISTRICT: 'Billing District',
            BILLING_PROVINCE: 'Billing Province',
            BILLING_ADDRESS: 'Billing Address',
            INPUT_BILLING_ADDRESS: 'Billing Address',
            TITLE_DOCUMENT: 'Document',
            ID_CARD: 'ID Card (Front)',
            LIQUOR_LICENSE: 'Liquor License',
            TOBACCO_LICENSE: 'Tobacco License',
            STORE_PICTURE: 'Store Picture',
            POR_POR_20: 'POR POR 20',
            CREATE_DATE: 'Registration date',
            UPDATE_DATE: 'Latest update',
            REJECT_CATEGORY: 'Reject category',
            REJECT_SUBCATEGORY: 'Motivation',
            REJECT_REMARK: 'Remark',
        },

        LIST_RETAILER: {
            RETAILER: 'Retailer',
            ADD_RETAILER: 'Add Retailer',
            NAME: 'Name',
            ADDRESS: 'Address',
            MOBILEPHONENUMBER: 'Mobile Phone Number',
            EMAIL: 'Email',
            CREATE_DATE: 'Create Date',
            PERSON_CHARGE: 'Person Charge',
        },

        RETAILER_STATUS: {
            PENDING: 'Pending',
            CONFIRMED: 'Confirmed',
            REJECTED: 'Rejected',
            ALL: 'All',
            CANCELLED: 'Cancelled',
        },

        AUTH: {
            GENERAL: {
                OR: 'Or',
                SUBMIT_BUTTON: 'Submit',
                NO_ACCOUNT: "Don't have an account?",
                SIGNUP_BUTTON: 'Sign Up',
                FORGOT_BUTTON: 'Forgot Password',
                BACK_BUTTON: 'Back',
                PRIVACY: 'Privacy',
                LEGAL: 'Legal',
                CONTACT: 'Contact',
            },
            LOGIN: {
                TITLE: 'Login Account',
                BUTTON: 'Sign In',
            },
            FORGOT: {
                TITLE: 'Forgotten Password?',
                DESC: 'Enter your email to reset your password',
                SUCCESS: 'Your account has been successfully reset.',
            },
            REGISTER: {
                TITLE: 'Sign Up',
                DESC: 'Enter your details to create your account',
                SUCCESS: 'Your account has been successfuly registered.',
            },
            INPUT: {
                EMAIL: 'Email',
                FULLNAME: 'Fullname',
                PASSWORD: 'Password',
                CONFIRM_PASSWORD: 'Confirm Password',
                USERNAME: 'Username',
            },
            VALIDATION: {
                INVALID: '{{name}} is not valid',
                REQUIRED: '{{name}} is required',
                MIN_LENGTH: '{{name}} minimum length is {{min}}',
                AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
                NOT_FOUND: 'The requested {{name}} is not found',
                INVALID_LOGIN: 'The login detail is incorrect',
                REQUIRED_FIELD: 'Required field',
                MIN_LENGTH_FIELD: 'Minimum field length:',
                MAX_LENGTH_FIELD: 'Maximum field length:',
                INVALID_FIELD: 'Field is not valid',
            },
        },

        RETAILER_TAB: {
            STORE_INFORMATION: 'Information',
            STORE_DOCUMENT: 'Documents',
        },

        ACTION: {
            BACK_PAGE: 'Back to previous page',
            SUBMIT: 'Save',
            CANCEL: 'Cancel',
            PLEASE_SELECT: 'Please Select',
            BY: 'By',
            SEARCH: 'Search',
            CLEAR: 'Clear',
            CONFIRM: 'Confirm',
        },

        STOCKIST: {
            PENDING: 'Pending',
            APPROVE: 'Confirmed',
            REJECT: 'Rejected',
            TITLE_REGISTRANT: 'Registrant Information',
            STATUS: 'Status',
            NAME: 'Name',
            FIRST_NAME: 'First Name',
            INPUT_FIRST_NAME: 'Registered name',
            LAST_NAME: 'Last Name',
            TAX_ID: 'TAX ID',
            MOBILE_PHONE_NUMBER: 'Mobile Phone Number',
            SECONDARY_PHONE_NUMBER: 'Secondary Phone Number',
            EMAIL: 'Email',
            TITLE_STORE: 'Store Information',
            TITLE_ADDRESS: 'Shipping Address',
            POST_CODE: 'Post Code',
            SUB_DISTRICT: 'Sub District',
            DISTRICT: 'District',
            PROVINCE: 'Province',
            ADDRESS: 'Address',
            INPUT_ADDRESS: 'Address',
            SEARCH_LOCATION: 'Search Location',
            CURRENT_LOCATION: 'Current Location',
            TITLE_BILLING_ADDRESS: 'Billing address',
            USE_ADDRESS: 'Use this address as a billing address',
            BILLING_POST_CODE: 'Billing Post Code',
            BILLING_SUB_DISTRICT: 'Billing Sub District',
            BILLING_DISTRICT: 'Billing District',
            BILLING_PROVINCE: 'Billing Province',
            BILLING_ADDRESS: 'Billing Address',
            INPUT_BILLING_ADDRESS: 'Billing Address',
            TITLE_DOCUMENT: 'Document',
            ID_CARD: 'ID Card (Front)',
            LIQUOR_LICENSE: 'Liquor License',
            TOBACCO_LICENSE: 'Tobacco License',
            STORE_PICTURE: 'Store Picture',
            POR_POR_20: 'POR POR 20',
            CREATE_DATE: 'Registration date',
            UPDATE_DATE: 'Latest update',
        },

        NOTIFICATION: {
            ORDER_NUMBER: 'Order Number',
        },

        ORDERS: {
            ORDER_ALL: 'View all orders',
            TOTAL_MONTHLY_AMOUNT: 'Sales this month',
            TOTAL_ORDER: 'Order quantity',
            TOTAL_NEW_CUSTOMER: 'New customer',
            VIEW_DASHBOARD: 'See all analysis',
            VIEW_ALL: 'View all',
            ORDER_ID: 'Order ID',
            ORDER_AT: 'Order at',
            STATUS_PENDING: 'Pending',
            STATUS_CONFIRMED: 'Confirm',
            STATUS_CANCEL_REQUESTED: 'Cancel Request',
            STATUS_CANCELLED: 'Cancel',
            STATUS_DELIVERED: 'Delivered',
            PRODUCT_QUANTITY: 'Product quantity',
            ITEM: 'Item',
            DELIVERY_FEE: 'Delivery fee',
            DELIVERED_DATE: 'Delivered date',
            EMPTY_ORDER: 'No order',
            PRODUCT_TITLE: 'Products'
        },

        DASHBOARD: {
            GO_BACK: 'Back to previous page',
            TITLE_DAY: 'Day',
            TITLE_MONTH: 'Month',
            TITLE_ORDER: 'Order',
            ORDER_ALL: 'Order all',
            STATUS_PENDING: 'Pending',
            STATUS_CONFIRMED: 'Confirm',
            STATUS_CANCEL_REQUEST: 'Cancel Request',
            STATUS_CANCEL_BY_RETAILER: 'Cancel By Retailer',
            STATUS_CANCEL_BY_STOCKIST: 'Cancel By Wholesaler',
            STATUS_DELIVERED: 'Delivered',
            TOTAL_MONTHLY_AMOUNT: 'Sales this month',
            TOTAL_ORDER: 'Total order',
            TOTAL_ORDER_DELIVERED: 'Delivered Order',
            TOTAL_ORDER_CANCEL_BY_STOCKIST:
                'Total order cancelled by wholesaler',
            TOTAL_ORDER_CANCEL_BY_RETAILER:
                'Total order cancelled by retailer',
            TOTAL_NEW_CUSTOMER: 'New customer',
            TOTAL_OLD_CUSTOMER: 'Old customer',
            PROGRESSIVE: 'Progressive',
            REDUCE: 'Reduce',
            UNALTERED: 'Unchanged',
            QUANTITY: 'Quantity',
            PRODUCT_IMAGE: 'Image',
            PRODUCT_NAME: 'Product name',
            BY_BRAND: 'Brand',
            BY_CATEGORY: 'Category',
            UNIT_DESC: 'Sort by quantity : more - less',
            AMOUNT_DESC: 'Sort by amount : more - less',
            UNIT_ASC: 'Sort by quantity : less - more',
            AMOUNT_ASC: 'Sort by amount : less - more',
            COUPON: 'Coupon redemption ',
            PURCHASE_PROMOTION: {
                TITLE: 'Promotion',
                IMAGE_HEADER: 'Image',
                NAME_HEADER: 'Name',
                QUANTITY_HEADER: 'Quantity'
            }
        },

        CHARTS: {
            UNIT: 'Unit',
            AMOUNT: 'Amount',
            QUANTITY: 'Quantity',
        },

        ORDER_HISTORY: {
            GO_BACK: 'Back to previous page',
            TOTAL_ORDER: 'Total order',
            TOTAL_ORDER_PENDING: 'Total order pending',
            TOTAL_ORDER_CONFIRMED: 'Total order confirmed',
            TOTAL_ORDER_CANCEL_REQUESTED: 'Total order cancel requested',
            TOTAL_ORDER_CANCELLED: 'Total order cancelled',
            TOTAL_ORDER_DELIVERED: 'Total order delivered',
            ORDER_ID: 'Order ID',
            ORDER_AT: 'Order at',
            STATUS_PENDING: 'Pending',
            STATUS_CONFIRMED: 'Confirm',
            STATUS_CANCEL_REQUESTED: 'Cancel Request',
            STATUS_CANCELLED: 'Cancel',
            STATUS_DELIVERED: 'Delivered',
            PRODUCT_QUANTITY: 'Product quantity',
            STATUS_CANCEL_BY_RETAILER: 'Cancel By Retailer',
            STATUS_CANCEL_BY_STOCKIST: 'Cancel By Wholesaler',
            ITEM: 'Item',
            DELIVERY_FEE: 'Delivery fee',
            DELIVERED_DATE: 'Delivered date',
            EMPTY_ORDER: 'No order',
        },

        ORDER_INFO: {
            GO_BACK: 'Back to previous page',
            PENDING: {
                ORDER_ID: 'Order ID',
                STATUS_PENDING: 'Pending',
                ORDER_AT: 'Order at',
                ADDITIONAL_DETAILS: 'Additional details',
                PRODUCT_LIST: 'Product list',
                GIFT: 'Gift',
                CHECK_ORDER: 'Check order',
                USER_NAME: 'User name',
                TAX_ID: 'Tax ID',
                PHONE_NUMBER: 'Phone number',
                SECONDARY_NUMBER: 'Secondary number',
            },

            CONFIRMED: {
                ORDER_ID: 'Order ID',
                STATUS_CONFIRMED: 'Confirm',
                CONFIRMED_AT: 'Confirm at',
                NO_APPROVED: 'No approved',
                ADDITIONAL_DETAILS: 'Additional details',
                PRODUCT_LIST: 'Product list',
                GIFT: 'Gift',
                ORDER_CHANGED: 'Order changed',
                VIEW_OLD_ORDER: 'View old order',
                DELIVERED: 'Delivered',
                CANCEL_ORDER: 'Cancel order',
                USER_NAME: 'User name',
                TAX_ID: 'Tax ID',
                PHONE_NUMBER: 'Phone number',
                SECONDARY_NUMBER: 'Secondary number',
                MODAL: {
                    TITLE: 'Order has been changed',
                    MESSAGE: 'You have edited some items,\norder id will be changed after you confirm',
                    CONFIRM: 'Confirm',
                    CANCEL: 'Cancel',
                }
            },

            CANCEL_REQUESTED: {
                ORDER_ID: 'Order ID',
                STATUS_CANCEL_REQUESTED: 'Cancel Request',
                CANCEL_REQUESTED_AT: 'Cancel Requested at',
                ADDITIONAL_DETAILS: 'Additional details',
                PRODUCT_LIST: 'Product list',
                GIFT: 'Gift',
                ORDER_CHANGED: 'Order changed',
                VIEW_OLD_ORDER: 'View old order',
                APPROVED_REQUEST: 'Approved Request',
                NOT_APPROVED: 'Not Approved',
                USER_NAME: 'User name',
                TAX_ID: 'Tax ID',
                PHONE_NUMBER: 'Phone number',
                SECONDARY_NUMBER: 'Secondary number',
            },

            CANCELLED: {
                ORDER_ID: 'Order ID',
                STATUS_CANCELLED: 'Cancel',
                CANCELLED_AT: 'Cancelled at',
                BY_STOCKIST: 'By Wholesaler',
                BY_RETAILER: 'By Retailer',
                PRODUCT_LIST: 'Product list',
                GIFT: 'Gift',
                ORDER_CHANGED: 'Order changed',
                VIEW_OLD_ORDER: 'View old order',
            },

            DELIVERED: {
                ORDER_ID: 'Order ID',
                STATUS_DELIVERED: 'Delivered',
                DELIVERED_AT: 'Delivered at',
                PRODUCT_LIST: 'Product list',
                GIFT: 'Gift',
                ORDER_CHANGED: 'Order changed',
                VIEW_OLD_ORDER: 'View old order',
            },

            ADDITIONAL: {
                TITLE: 'Additional privilege from promotion',
                TITLE_ERROR: 'No additional benefits due to an adjustment.',
                GIVEAWAY_TITLE: 'Giveaway',
                DISCOUNT_TITLE: 'Discount',
                FROM_PROMOTION: 'from ',
                BUNDLE: 'Bundle',
                GIVEAWAY: 'Get giveaways as',
                FREE: 'Free!',
                ORDER_TITLE: 'Purchasing',
                DISCOUNT: 'Get additional discount',
                PER_UNIT: 'per unit',
            }
        },

        SHIP_TO_ADDRESS: {
            DELIVERY_TO: 'Delivery to',
            VIEW_MAP: 'View map',
        },

        PAYMENT_TO_ADDRESS: {
            BILLING_ADDRESS: 'Billing address',
        },

        PAYMENT: {
            TOTAL_PRODUCT_PRICE: 'Total product price',
            ITEM: 'Item',
            PROMOTION_DISCOUNT: 'Promotion discount',
            COUPON_DISCOUNT: 'Coupon discount',
            DELIVERY_FEE: 'Delivery fee',
            TOTAL: 'Total',
        },

        CONFIRM_ORDER: {
            GO_BACK: 'Back to previous page',
            CHECK_ORDER: 'Check order',
            WARNING:
                'If you are unable to ship all the items, you can edit the quantity of the items. (The order number will be changed after confirmation.)',
            PRODUCT_LIST: 'Product list',
            RESTORE_ORDER: 'Restore order',
            STOCKIST_OPEN: 'Store opening date',
            EXPECTED_DELIVERY_ORDER: 'Expected delivery order',
            EXPECTED_DELIVERY: 'Expected delivery',
            VALIDATE: 'Please ship the item as soon as possible, no more than 3 business days.',
            CONFIRM_ORDER: 'Confirm order',
            CANCEL_ORDER: 'Cancel order',
        },

        PROCESSED_CONFIRMED: {
            CONFIRM_ORDER: 'Confirm order',
            ORDER_ID: 'Order ID',
            CONFIRMED_AT: 'Confirmed at',
            EXPECTED_DELIVERY: 'Expected delivery',
            GIFT: 'Gift',
            VIEW_ORDER_DETAIL: 'View order details',
            BACK_TO_HOME: 'Back to home',
        },

        PROCESSED_CANCELLED: {
            CANCELLED_ORDER: 'Cancelled order',
            ORDER_ID: 'Order ID',
            CANCELLED_AT: 'Cancelled at',
            GIFT: 'Gift',
            VIEW_ORDER_DETAIL: 'View order details',
            BACK_TO_HOME: 'Back to home',
        },

        PROCESSED_REJECTED: {
            REJECT_CANCEL_REQUEST: 'Reject cancel request',
            ORDER_ID: 'Order ID',
            REJECT_CANCEL_REQUEST_AT: 'Reject cancel request at',
            GIFT: 'Gift',
            VIEW_ORDER_DETAIL: 'View order details',
            BACK_TO_HOME: 'Back to home',
        },

        DELIVERED_DATE: {
            EXPECTED_DELIVERY: 'Expected delivery',
            VALIDATE:
                'The order has been delayed from the expected delivery date. Please make delivery.',
        },

        MY_NOTIFICATION: {
            GO_BACK: 'Back to previous page',
            ORDER_ID: 'Order ID',
            NO_NOTIFICATION: 'No notification',
            NEW_NOTIFICATION:
                'There will be interesting news updates here soon! New',
        },

        NEW_SIDE_BAR: {
            PROFILE: 'Profile',
            CONTACT: 'Contact',
            CHAT: 'Chat with customer service',
            CALL: 'Call customer service',
            BUSINESS: 'Business hours 8:00 - 20:00',
            LOGOUT: 'Logout',
        },

        LOGIN: {
            GO_BACK: 'Back to previous page',
            TITLE: 'Welcome to',
            TITLE_SUB: 'Log in and order products now.',
            COUNTY: 'County',
            ENTER_PHONE: 'Enter your mobile phone number.',
            PHONE: 'mobile phone number.',
            ERROR_NETWORK: 'Please check your internet connection.',
            ERROR_PHONE: 'No user account found.',
            ERROR_PHONE_1: 'Mobile phone number is invalid.',
            ERROR_PHONE_2: 'Please enter your phone number.',
            FOOTER: 'Don t have an account yet with',
            FOOTER_1: 'yes or no?',
            REGISTER: 'register',
            TITLE_PASSWORD: 'Enter password',
            TITLE_PASSWORD_2: 'Enter your password for login.',
            PASSWORD: 'Password',
            INPUT_PASSWORD: 'Please enter password',
            ERROR_PASSWORD_1: 'Please enter your password.',
            ERROR_PASSWORD_2:
                'The password is incorrect. Please check your password again.',
            NEXT: 'Next',
            FORGET_PASSWORD: 'Forgot your password?',
            RESET_PASSWORD: 'Reset password',
            OTP_TITLE_1: 'Send OTP code to mobile number',
            OTP_TITLE_2: 'OTP will expire within 5 minutes.',
            ENTER: 'Enter OTP',
            ERROR_OTP: 'Not receive the code?',
            ERROR_OTP_1: 'Please enter OTP',
            ERROR_OTP_2: 'OTP is invalid',
            SEND_OTP: 'Send OTP again',
            ERROR_SET_PASSWORD_1: 'Please enter your password and confirm it.',
            ERROR_SET_PASSWORD_2: 'Please enter your password to confirm.',
            ERROR_SET_PASSWORD_3: 'Password and Confirm Password do not match.',
            ERROR_SET_PASSWORD_4:
                'Please enter a password of 8 characters or more.',
            ERROR_SET_PASSWORD_5:
                'Password must be in English and be at least 8 characters long, one uppercase letter, one lowercase letter. and one number',
            ERROR_SET_PASSWORD_6: 'Something went wrong Please try again.',
            ERROR_SET_PASSWORD_7: 'Password must be in English or numbers, at least 8 characters.',
            CONFIRM_PASSWORD: 'Confirm password',
            SET_PASSWORD: 'Set password',
            SET_PASSWORD_LOGIN: 'Set a password for login.',
            CONFIRM: 'Confirm',
            SET_NEW_PASSWORD: 'Set new password',
        },

        PROFILE: {
            GO_BACK: 'Back to previous page',
            ADDRESS: 'Address',
            BIND_ACCOUNT: 'Bind account',
            CANCEL_BIND_ACCOUNT: 'Cancel bind account',
            SUBDISTRICT: 'Subdistrict',
            DISTRICT: 'District',
            TITLE: 'Want to cancel account binding?',
            MESSAGE: 'Unbind an account will result in dismissal of Line notification of activities on Wabi2b-TH for example order notify or others in\nLine communication channel\nConfirm account unbinding?',
            CONFIRM: 'Confirm',
            CANCEL: 'Cancel',
        },

        REASON_CANCELLATION: {
            TITLE: 'Cancellation reason',
            REASON: 'Please enter the reason',
            SPECIFY: 'Please specify',
            ADDITIONAL: 'Additional reason',
            SPECIFY_ADDITIONAL: 'Reason',
            VALIDATE: 'If other is selected, you must specify additional reason',
            CONFIRM_CANCEL: 'Confirm cancellation',
            LC1: 'Out of stock',
            LC2: 'I want to change the order',
            LC3: 'Price have been changed',
            LC4: 'Unable to deliver',
            LC5: 'Others',
        },
        SHELF_MANAGEMENT: {
            UNIT_PRICE: 'Price per unit',
            MINIMUM_UNIT: 'Minimum',
            MAXIMUM_UNIT: 'Maximum',
            DOWNLOAD_FILE: 'Download file',
            UPLOAD_FILE: 'Upload file',
            UPLOAD_FILE_SUCCESS: 'File uploaded successfully',
            TITLE: 'Edit product details',
            VIEW_TITLE: 'Product details',
            ADD_FILE: 'Add file',
            FILE_DETAIL: 'Please upload file xls,xlsx size not exceeding 100 MB',
            ERROR_FILE_TYPE: 'Incorrect file format, please try again.',
            ERROR_FILE_SIZE: 'File size exceeds limit, please try again.',
            ERROR_UPLOAD: 'Unable to upload file. Please recheck and try again.',
            ERROR_EAN: 'Duplicate EAN occurred. Please recheck and try again.',
            LIST_TITLE: 'Shelf Management',
            PRODUCT_UNIT: 'Unit',
            FILTER_TITLE: 'Filter',
            TAB_STATUS: {
                ALL: 'All',
                ACTIVE: 'Activate',
                INACTIVE: 'Inactivate'
            },
            EMPTY_SHELF_TITLE: 'The shelf is empty',
            EMPTY_SHELF_DESCRIPTION: 'You haven’t added a product into a shelf yet'
        },

        FILTER: {
            FILTER: 'Filter',
            CATEGORY: 'category',
            BRAND: 'brand',
            SORT_BY: 'Sort by',
            NEW: 'product : new-old',
            OLD: 'product : old-new',
            LOW: 'price : low-high',
            HIGH: 'price : high-low'
        },
        LIST_SHELF_MANAGEMENT: {
            PRODUCT: 'Product',
            ADD_PRODUCT: 'Add product',
            NAME: 'Name',
            ADDRESS: 'Address',
            MOBILEPHONENUMBER: 'Mobile phone number',
            EMAIL: 'Email',
            DELIVERY_ZONE: 'Delivery zone',
            TAX_ID: 'Tax id',
            ACTIVATE: 'Active',
            DEACTIVATE: 'Inactive',
            ALL: 'All',
            COVERIMAGE: 'Cover image',
            BRANDNAME: 'Brand',
            CATEGORY: 'Category',
            UNIT_PRICE: 'Price per unit',
            MODAL_TITTLE: 'Confirm the inactivation of the product',
            MODAL_MESSAGE: 'Do you want to inactivate this product ?',
            MODAL_CONFIRM_LABEL: 'Inactive',
            MODAL_CANCEL_LABEL: 'Cancel'
        },

        MODAL_SHELF: {
            TITLE: 'Confirm deactivation',
            MESSAGE: 'Do you want to temporarily disable this product ?',
            CONFIRM: 'Disable',
            CANCEL: 'Cancel'
        },

        PRODUCT_PROMOTION_LIST: {
          SUM: 'Sum',
          UNIT: 'Unit',
          SET: 'Set',
          FREE: 'Free'
        },

        PROMOTION: {
            BUNDLE: 'Bundle',
            TIER: 'Tier',
            SUM: 'Sum',
            UNIT: 'Unit',
            SET: 'Set',
            FREE: 'Free'
        },

        PROMOTION_LIST: {
            TITLE: 'Promotion',
            PROMOTION_UNIT: 'Unit',
            STATUS_ACTIVE: 'Active',
            STATUS_EXPIRED: 'Expired',
            STATUS_WAITING: 'Upcoming',
            STATUS_INACTIVE: 'Inactive',
            ADD: '+ Create Promotion',
        },
    },
};
