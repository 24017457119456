import { Component, EventEmitter, Output } from '@angular/core';
import { TabStatus } from '@core/models/stockist/stockist-enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss']
})
export class StatusBarComponent {
  @Output() tabChange = new EventEmitter<string>();

  constructor(
    private translate: TranslateService,
  ) {
  }
  tabSelected = TabStatus.All;
  statuses = [
    { value: TabStatus.All, name: this.translate.instant('TAB_STATUS.ALL') },
    { value: TabStatus.Active, name: this.translate.instant('TAB_STATUS.ACTIVE') },
    { value: TabStatus.InActive, name: this.translate.instant('TAB_STATUS.INACTIVE') }
  ];

  onChangeTab(value: TabStatus) {
    this.tabSelected = value
    this.tabChange.emit(value);
  }
}
