<div *ngIf="stockistOrder" style="padding-top: 1.5rem;">
    <nav class="navigate-bar">
        <div class="back-container" (click)="goBack()">
            <i class="fa-solid fa-arrow-left" aria-hidden="true"></i>
            <label for="backforward">{{ "ORDER_INFO.GO_BACK" | translate }}</label>
        </div>
    </nav>
    <div *ngIf="isLoading" class="overlay">
        <ngx-spinner bdColor="rgb(0,0,0,0.07)" size="medium" color="#21C063" type="ball-clip-rotate" [fullScreen]="false" name="spinner" style="margin-top: 0px !important;"></ngx-spinner>
    </div>
    <div class="header-section mt-2">
        <div class="px-3 mb-1 d-flex justify-content-between align-items-center">
            <div class="row">
                <label class="fs-sm order-title">{{ "ORDER_INFO.CONFIRMED.ORDER_ID" | translate }}</label>
                <label class="fw-bold">{{stockistOrder.id}}</label>
            </div>
            <div class="fs-sm fw-semibold status">
                {{ "ORDER_INFO.CONFIRMED.STATUS_CONFIRMED" | translate }}
            </div>
        </div>
        <div class="pb-3 px-3 text-base font-black" *ngIf="!stockistOrder.cancelRejectedDate">
            {{ "ORDER_INFO.CONFIRMED.CONFIRMED_AT" | translate }} {{stockistOrder.confirmedDate| displayDate}}
        </div>
        <div class="pb-3 px-3 text-base font-black" *ngIf="stockistOrder.cancelRejectedDate">
            {{ "ORDER_INFO.CONFIRMED.NO_APPROVED" | translate }} {{stockistOrder.cancelRejectedDate | displayDate}}
        </div>
    </div>
    <div class="d-flex flex-column mx-3 pb-2">
        <app-delivered-date [deliveredDate]="stockistOrder.deliveryDate"
            [officeTime]="stockistOrder.officeTime"></app-delivered-date>
        <app-ship-to-address [stockistOrder]="stockistOrder" [isShowLocation]="true" />
        <app-payment-to-address [stockistOrder]="stockistOrder" />
        <div class="d-flex" [class.more-detail]="!expandDetails" [class.more-detail-active]="expandDetails"
            (click)="expandDetails = !expandDetails">
            <div class="d-flex justify-content-between align-items-center">
                <div>
                    <img class="detail-icon" [src]="detailIcon" alt="wallet-icon" />
                    {{ "ORDER_INFO.CONFIRMED.ADDITIONAL_DETAILS" | translate }}
                </div>
                <i class="fa-regular fa-angle-down down"
                    [style.transform]="expandDetails ? 'rotate(180deg)' : 'none'"></i>
            </div>
            <div *ngIf="expandDetails" class="additional">
                <div *ngFor="let detail of additionalDetails" class="additional-item">
                    <div class="text-xs">{{ detail.label }}</div>
                    <div style="font-weight: 500;">{{ detail.value }}</div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="fw-semibold align-items-end mx-3">
            <div class="border-line-top"></div>
            <p>{{ "ORDER_INFO.CONFIRMED.PRODUCT_LIST" | translate }}</p>
            <app-product-list [products]="stockistOrder.items"></app-product-list>
        </div>
        <div *ngIf="stockistOrder.additionalDiscounts.length > 0 || stockistOrder.additionalGiveAways.length > 0">
            <app-additional-privilege [additionalDiscounts]="stockistOrder.additionalDiscounts" [additionalGiveAways]="stockistOrder.additionalGiveAways"></app-additional-privilege>
        </div>
        <div class="mx-3">
            <app-payment [paymentInfo]="getPaymentInfo()"></app-payment>
        </div>
        <div *ngIf="stockistOrder.refOrderId"
            class="my-3 change-order-tab d-flex justify-content-between align-items-center p-2">
            <div class="d-flex align-items-center gap-2">
                <i class="fa-solid fa-circle-exclamation"></i>
                <span class="fs-xs" style="color: #505050;">{{ "ORDER_INFO.CONFIRMED.ORDER_CHANGED" | translate }}</span>
            </div>
            <button class="btn-old-order fs-sm fw-md" (click)="navigateToOldOrder()">{{ "ORDER_INFO.CONFIRMED.VIEW_OLD_ORDER" | translate }} <i
                    class="fa-solid fa-arrow-right" aria-hidden="true"></i></button>
        </div>
    </div>
    <div class="d-flex flex-column m-3 mb-4 gap-3">
        <button type="button" class="btn confirm-order-btn" data-cy="confirm-order-btn"
            (click)="updateStatusToDelivery()">
            {{ "ORDER_INFO.CONFIRMED.DELIVERED" | translate }}
        </button>
        <button type="button" class="btn btn-outline-danger" data-cy="cancel-order-btn"
            (click)="onClickOpenCancelModal()">
            {{ "ORDER_INFO.CONFIRMED.CANCEL_ORDER" | translate }}
        </button>
        <app-reason-cancellation-modal *ngIf="openCancelModal" [isOpenModal]="openCancelModal"
            [orderNumber]="stockistOrder.id" (isOpenModalChange)="onClickCloseModal()" />
    </div>
</div>
