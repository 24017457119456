<nav class="navbar bg-body-tertiary">
    <div class="container-fluid" style="cursor: pointer;" (click)="navigateToOrders()">
        <i class="fa fa-arrow-left"></i>
        <div>{{ "CONFIRM_ORDER.GO_BACK" | translate }}</div>
    </div>
    <div class="container-fluid">
        <div class="fs-5 navbar-brand d-flex justify-content-center justify-content-lg-center">{{ "CONFIRM_ORDER.CHECK_ORDER" | translate }}</div>
    </div>
</nav>
<div *ngIf="isLoading" class="overlay">
    <ngx-spinner bdColor="rgb(0,0,0,0.07)" size="medium" color="#21C063" type="ball-clip-rotate" [fullScreen]="false" name="spinner" style="margin-top: 0px !important;"></ngx-spinner>
</div>
<div class="aura-container-custom">
    <div class="page">

        <div class="note" data-cy="label-warning-when-update-quantity">
            <div class="tab"></div>
            <div class="icon"><img src="../../../../../../../assets/icons/clipboard.svg"></div>
            <p class="text">{{ "CONFIRM_ORDER.WARNING" | translate }}</p>
        </div>

        <div class="layout">
            <div class="heading" [style.color]="isResetActive ? '#21c063' : '#B3E3C7'">
                <div class="title">{{ "CONFIRM_ORDER.PRODUCT_LIST" | translate }}</div>
                <div class="reset" [ngStyle]="{'cursor': isResetActive ? 'pointer' : 'default'}">
                    <i class="fas fa-rotate-left me-2"></i>
                    <p class="reset-wording" data-cy="reset-button" (click)="isResetActive ? resetToDefault() : null"
                        aria-hidden="true">
                        {{ "CONFIRM_ORDER.RESTORE_ORDER" | translate }}
                    </p>
                </div>
            </div>




            <div *ngFor="let item of cloneOrder; let i = index">
                <div *ngIf="checkDisplay(item)">
                    <div class="product-pane mb-2">
                        <div class="row px-2 ">
                            <!-- <div class="col border border-primary"> -->
                                <img  (error)="handleImageError($event)" [attr.data-cy]="item.id + '-image'"
                                    src="{{item.coverImagePathThump}}" alt="product image" class="img-fit col-2 col-lg-1 d-block mx-auto"width="52px" height="90px">
                            <!-- </div> -->
                            <div class="col-10 col-lg-11">
                                <p class="mt-2" [attr.data-cy]="item.id + '-name'">
                                    {{item.productName}}</p>
                                <div class="col flex justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <button class="btn btn-icon" (click)="reduceQuantity(i)"
                                            [disabled]="currentQuantity.value==='0'">
                                            <img src="assets/icons/MinusIcon.svg" />
                                        </button>
                                        <input #currentQuantity class="amount" data-cy="item-quantity" type="number"
                                            [value]="item.quantity"
                                            oninput="this.value = this.value.replace(/(?![0-9])|[^0-9]|^0+(?=[0-9])/g, '');"
                                            (input)="resolveValue($event,i);checkOrderIsChange()">
                                        <button class="btn btn-icon" (click)="increaseQuantity(i)"
                                            [disabled]="currentQuantity.value===stockistOrder.items[i].quantity.toString()">
                                            <img src="assets/icons/PlusIcon.svg" />
                                        </button>
                                    </div>
                                    <span>
                                        <p class="fw-bold" style="margin-top: 0.6rem;"
                                            [attr.data-cy]="item.id+'-sum-amount-product'">
                                            {{pricePerUnit[i]* item.quantity|
                                            currency:'THB':'symbol-narrow'}}
                                        </p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <app-payment [paymentInfo]="getPaymentInfo()"></app-payment>
        </div>

        <div class="layout2">
            <div class="deliver-information">
                <div data-cy="label-open-day" class="gap-3 mb-2 fw-semibold">{{ "CONFIRM_ORDER.STOCKIST_OPEN" | translate }}</div>
                <div class="open-day">
                    <div *ngFor="let day of days">
                        <span [ngStyle]="{'color': isDayActive(day, stockistOrder.officeDays) ? colors[1] : 'black'}">{{
                            day }}</span>
                    </div>
                </div>
                <div data-cy="label-estimate-date-delivery" class="gap-3 mb-2 fw-semibold mt-3">
                    {{ "CONFIRM_ORDER.EXPECTED_DELIVERY_ORDER" | translate }}
                </div>
                <div class="flex mb-3" style="color: #F43A29;">
                    <i class="fa-solid fa-triangle-exclamation"></i>
                    <span data-cy="label-pls-delivery"
                        class="space-block gap-3 mb-3 fw-light ms-2">{{ "CONFIRM_ORDER.VALIDATE" | translate }}
                    </span>

                </div>


                <!-- <ng-template #inputContent> -->
                  <div class="input-group validated">
                    <div class="input-group-append">
                      <ng-container>
                        <span  class="input-group-text " (click)="picker.open()"
                          ><img id="btn-click" src="../../../../../../../assets/icons/calendar.svg">
                        </span>

                      </ng-container>
                    </div>
                    <input
                    class="form-control"
                    [matDatepicker]="picker"
                    (click)="picker.open()"
                    placeholder="{{'CONFIRM_ORDER.EXPECTED_DELIVERY' | translate }}"
                    [(ngModel)]="model"
                    autocomplete="off"
                    [readonly]="true"
                    [min]="currentDate"
                    (dateChange)="dateSelect($event)"
                    />
                    <mat-datepicker #picker></mat-datepicker>
                  </div>
                <!-- </ng-template> -->

                <div class="gap-3 mb-3 mt-3">
                    <button data-cy="estimate-time-delivery'" type="button" class="btn office-time icon-color">
                        <img style="margin-left: 5px;" src="../../../../../../../assets/icons/clock.svg">
                        <span style="margin-left: 15px;" class="placeholder-text">{{this.formatTime(this.stockistOrder.officeTime)}}</span>
                    </button>
                </div>

            </div>
            <div class="d-flex flex-column gap-3 mb-3">
                <button data-cy="confirm-order-button" type="button" class="btn btn-block confirm-order-btn"
                    (click)="navigateToOrderConfirm()" [disabled]="isConfirmButtonDisabled()">
                    {{ "CONFIRM_ORDER.CONFIRM_ORDER" | translate }}
                </button>
            </div>
            <div class="d-flex flex-column gap-3 mb-3">
                <button data-cy="cancel-order-button" type="button" class="btn btn-block cancel-order-btn"
                    (click)="onClickOpenCancelModal()">
                    {{ "CONFIRM_ORDER.CANCEL_ORDER" | translate }}
                </button>
            </div>
        </div>
    </div>
    <app-reason-cancellation-modal *ngIf="openCancelModal" [isOpenModal]="openCancelModal" [orderNumber]="orderNumber"
        (isOpenModalChange)="onClickCloseModal()" />
</div>
