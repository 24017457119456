import { Component, Input, OnInit } from '@angular/core';
import { IOrderItemPromotionDetail } from '@core/models/stockist/order.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-product-list',
    templateUrl: './product-list.component.html',
    styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {
    @Input() products: IOrderItemPromotionDetail[] = [];

    productList?: IOrderItemPromotionDetail[] = []
    currentLang = 'th';

    constructor(
        private translate: TranslateService
    ) { }

    ngOnInit(): void {
        this.loadData()
        this.currentLang = this.translate.currentLang
    }

    loadData() {
        this.productList = this.products
    }

    formatCurrency(amount?: number) {
        amount = amount ?? 0

        const currencyAmount = new Intl.NumberFormat('th-TH', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(amount);
        return currencyAmount
    }

}
