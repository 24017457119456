<div perfectScrollbar [ngStyle]="{ 'max-height': 'auto', position: 'relative' }" class="mat-table__wrapper">
    <table mat-table class="lmat-elevation-z8" [dataSource]="sourses" matSort matSortDisableClear>
        <ng-container [matColumnDef]="column.id" *ngFor="let column of dataColums">
            <th [ngClass]="column.headClass" mat-header-cell *matHeaderCellDef
                [mat-sort-header]="column.sortdisable ? column.id : ''"
                [disabled]="column.sortdisable || column.sortDisabled" style="font-size: 14px; padding: 0">
                <ng-container *ngIf="column.headTemplate">
                    <ng-template [ngTemplateOutletContext]="{ data: column }" [ngTemplateOutlet]="column.headTemplate">
                    </ng-template>
                </ng-container>

                <ng-container *ngIf="!column.headTemplate">
                    {{ column.title }}
                </ng-container>
            </th>
            <td mat-cell *matCellDef="let row" class="mat-column-vinCode" [ngClass]="column.columnClass">
                <ng-container *ngIf="column.cellTemplate">
                    <ng-template [ngTemplateOutletContext]="{ data: row }" [ngTemplateOutlet]="column.cellTemplate">
                    </ng-template>
                </ng-container>

                <ng-container *ngIf="!column.cellTemplate">
                    <ng-container *ngIf="!row.product">
                        <ng-container *ngIf="column.mask">
                            {{ column.label ? row[column.label] : row[column.id] }}
                        </ng-container>
                        <ng-container *ngIf="!column.mask">
                            {{ column.label ? row[column.label] : row[column.id] }}
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="row.product">
                        <ng-container *ngIf="column.mask">
                            <ng-container *ngIf="column.id !== 'price'">
                                {{ column.label ? row[column.label] : row.product[column.id] }}
                            </ng-container>
                            <ng-container *ngIf="column.id === 'price'">
                                {{ column.label ? row[column.label] : row[column.id] }}
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!column.mask">
                            <ng-container *ngIf="column.id !== 'price'">
                                {{ column.label ? row[column.label] : row.product[column.id] }}
                            </ng-container>
                            <ng-container *ngIf="column.id === 'price'">
                                {{ column.label ? row[column.label] : row[column.id] }}
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="column.id === '_action'">
                    <ng-template [ngTemplateOutletContext]="{ data: row }" [ngTemplateOutlet]="actionTemplate">
                    </ng-template>
                </ng-container>

                <ng-container *ngIf="column.id === '_image'">
                    <ng-template [ngTemplateOutletContext]="{ data: row }" [ngTemplateOutlet]="imageTemplate">
                    </ng-template>
                </ng-container>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
            [ngClass]="{ 'custom-height-for-image': isEnabledColumnImage }"
            [ngStyle]="{ 'background-color': i % 2 === 0 ? '#ffffff' : '#ffffff' }">
        </tr>
    </table>

    <div class="mat-table__message message-center" *ngIf="sourses.length === 0">
        {{ 'No Data' }}
    </div>
</div>
<div class="mat-table__bottom mat-table__bottom--padding" [hidden]="paginationOption">
    <mat-spinner [diameter]="20" *ngIf="pageLoading"></mat-spinner>
    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="pageTotal"
        [showFirstLastButtons]="true"></mat-paginator>
</div>

<ng-template #actionTemplate let-data="data">

    <div class="action-wrapper w-100 justify-content-end py-4 pr-4">
        <button *ngIf="!disabledEdit" class="btn-shelf" (click)="onEdit(data)">
            <img data-testid="category-create" class="image-add mr-3" width="20" height="20"
                src="assets/icons/SVG/edit.svg" />
        </button>
        <button class="btn-action ml-1" [hidden]="disableDelete" (click)="onDelete(data)">
            <i class="fa-regular fa-trash"></i>
        </button>
        <button class="btn-shelf" [hidden]="disableShelf" (click)="onShelf(data)">
            <img style="width: 24px; height: 24px" src="assets/icons/SVG/IconShelf.svg"
                *ngIf="onCheckManagedShelf(data.id)" />
            <img style="width: 24px; height: 24px" src="assets/icons/SVG/EmptyShelf.svg"
                *ngIf="!onCheckManagedShelf(data.id)" />
        </button>
        <button class="btn-action w-100 mr-2 btn-view-detail" *ngIf="!disableViewDetail" (click)="onViewDetail(data)"
            style="white-space: nowrap;">
            ดูรายละเอียด
        </button>

        <app-toggle-button *ngIf="isShelfManagement === 'false'" [checked]="data[statusPropertyPath] === 'Active'"
            [id]="data.id" (Toggle)="toggle($event)" [hidden]="disableToggle" class="toggle-style"></app-toggle-button>

        <app-toggle-button *ngIf="isShelfManagement === 'true'" [checked]="data[statusPropertyPath] === 'Active'"
            [id]="data" (Toggle)="toggle($event)" [hidden]="disableToggle" class="toggle-style"></app-toggle-button>

    </div>
</ng-template>

<ng-template #imageTemplate let-data="data">
    <ng-container *ngIf="!data.product">
      <div class="form-img-inner">
        <img [src]="data.logoUrlThump" alt="assets/images/image_error.jpg" class="image-thumbnail"
            (error)="handleImageError($event)" *ngIf="data.logoUrlThump" />
        <img [src]="data.coverImagePathThump" alt="assets/images/image_error.jpg" class="image-thumbnail"
            (error)="handleImageError($event)" *ngIf="data.coverImagePathThump" />

        <img src="assets/images/image_error.jpg" class="image-thumbnail" (error)="handleImageError($event)"
            *ngIf="!data.coverImagePathThump && !data.logoUrlThump" />
      </div>
    </ng-container>
    <ng-container *ngIf="data.product">
      <div class="form-img-inner">
        <img [src]="data.product.logoUrlThump" alt="assets/images/image_error.jpg" class="image-thumbnail"
            (error)="handleImageError($event)" *ngIf="data.product.logoUrlThump" />
        <img [src]="data.product.coverImagePathThump" alt="assets/images/image_error.jpg" class="image-thumbnail"
            (error)="handleImageError($event)" *ngIf="data.product.coverImagePathThump" />

        <img src="assets/images/image_error.jpg" class="image-thumbnail" (error)="handleImageError($event)"
            *ngIf="!data.product.coverImagePathThump && !data.product.logoUrlThump" />
      </div>
    </ng-container>

</ng-template>
