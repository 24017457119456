import {
    FormGroup,
    FormArray,
    AbstractControl,
    FormControl,
} from '@angular/forms';

interface ErrorObject {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}
export function SetError(form: FormGroup, apiError?: ErrorObject) {
    const error: ErrorObject = {};
    const errorArray: ErrorObject = {};

    if (apiError) {
        Object.keys(apiError).forEach((k) => {
            error[k] = apiError[k][0];
        });
    } else {
        Object.keys(form.value).forEach((k) => {
            const object = form.controls[k];
            if (object instanceof FormArray) {
                error[k] = SetErrorArray(object);
                errorArray[k] = QueryErrorArray(object);
            } else {
                if (object.errors) {
                    error[k] = object.errors;
                }
            }
        });
    }
    // Scroll to error element
    ScrollToError(error, errorArray);

    return error;
}

export function SetErrorSingle(
    form: FormGroup,
    key: string,
    oldError?: ErrorObject,
    oldErrorArray?: ErrorObject
) {
    let error: ErrorObject = {};
    let errorArray: ErrorObject = {};

    if (oldError) {
        error = oldError;
    }
    if (oldErrorArray) {
        errorArray = oldErrorArray;
    }

    const object = form.controls[key];
    if (object instanceof FormArray) {
        error[key] = SetErrorArray(object);
        errorArray[key] = QueryErrorArray(object);
    } else {
        if (object.errors) {
            error[key] = object.errors;
        } else {
            error[key] = undefined;
        }
    }

    // Scroll to error element
    ScrollToError(error, errorArray);

    return error;
}

export function SetErrorArray(form: FormArray) {
    const errors: ErrorObject[] = [];

    form.controls.forEach((control: AbstractControl) => {
        if (control instanceof FormGroup) {
            const error: ErrorObject = {};
            Object.keys(control.value).forEach((key) => {
                const childControl = control.get(key);

                if (childControl instanceof FormArray) {
                    error[key] = SetErrorArray(childControl);
                } else if (
                    childControl instanceof FormControl &&
                    childControl.errors
                ) {
                    error[key] = childControl.errors;
                }
            });

            errors.push(error);
        }
    });

    return errors;
}

function QueryErrorArray(form: FormArray) {
    const errors: ErrorObject[] = [];

    form.controls.forEach((control: AbstractControl) => {
        if (control instanceof FormGroup) {
            Object.keys(control.value).forEach((key) => {
                if (control.controls[key].errors) {
                    const error: ErrorObject = {};
                    error[key] = control.controls[key].errors;
                    errors.push(error);
                }
            });
        }
    });

    return errors;
}

export function ScrollToError(error: ErrorObject, errorArray: ErrorObject) {
    let el: Element | null = null;
    Object.keys(errorArray).forEach((k) => {
        if (errorArray[k].length === 0) {
            delete errorArray[k];
        }
    });

    const key = Object.keys(error)[0];
    if (!Array.isArray(error[key])) {
        el = document.querySelector('[name=' + Object.keys(error)[0] + ']');
    } else {
        if (Object.keys(errorArray).length !== 0) {
            const k = Object.keys(errorArray)[0];
            for (let i = 0; i <= error[k].length; i++) {
                if (error[key][i]) {
                    if (Object.keys(error[key][i]).length > 0) {
                        el = document.querySelector(
                            '[name=' +
                                k +
                                '_' +
                                Object.keys(error[k])[i] +
                                '_' +
                                Object.keys(error[k][i])[0] +
                                ']'
                        );
                        // if (!el) {
                        //   el = document.querySelector('#' + k + '_' + Object.keys(error[k])[i] + '_' + Object.keys(error[k][i])[0]);
                        // }
                        break;
                    }
                }
            }
        }
    }

    if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
}

