import { StockistOrderStatusTotal } from './../../../../../../core/models/stockist/stockist.model';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    AnalyticParams,
    MonthlySummary,
} from '@core/models/stockist/monthly-summary.model';
import { StockistOrderStatus } from '@core/models/stockist/stockist-enum';
import {
    IStockistPurchaseOrder,
    OrderAmount,
    StockistProfile,
    StockistPurchaseOrderList,
} from '@core/models/stockist/stockist.model';
import { AzureBlobService } from '@core/services/stockist/azure-blob.service';
import { ProductShelfService } from '@core/services/stockist/product-shelf.service';
import { StockistService } from '@core/services/stockist/purchase.service';
import { ReportService } from '@core/services/stockist/report.service';
import { UserService } from '@core/services/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
    constructor(
        private router: Router,
        private userService: UserService,
        private stockistService: StockistService,
        private reportService: ReportService,
        private azureBlobService: AzureBlobService,
        private spinner: NgxSpinnerService,
        private translate: TranslateService,
        private productShelfService: ProductShelfService
    ) { }
    pending: StockistPurchaseOrderList = [];
    confirmed: StockistPurchaseOrderList = [];
    cancelled: StockistPurchaseOrderList = [];
    cancelrequested: StockistPurchaseOrderList = [];
    delivered: StockistPurchaseOrderList = [];
    deprecated: StockistPurchaseOrderList = [];
    stockistOrderStatusTotal: StockistOrderStatusTotal = {
        totalConfirmOrder: '-',
        totalPendingOrder: '-',
        totalCancelRequestedOrder: '-',
    };
    stockistProfile: StockistProfile = { id: -1, name: 'ร้าน', logoPath: '' };
    dataSummary: MonthlySummary = {
        totalOrderAmount: 0,
        totalOrderAmountChangePercent: 0,
        totalOrder: 0,
        totalOrderChangePercent: 0,
        confirmedOrder: 0,
        confirmedOrderChangePercent: 0,
        cancelledOrderByRetailer: 0,
        cancelledOrderByRetailerChangePercent: 0,
        cancelledOrderByStockist: 0,
        cancelledOrderByStockistChangePercent: 0,
        newCustomer: 0,
        newCustomerChangePercent: 0,
        returningCustomer: 0,
        returningCustomerChangePercent: 0,
        totalFeeAmount: 0,
        totalCouponAmount: 0,
    };
    percentChange = false;
    orderAmount: OrderAmount = { amount: 0 };
    stockistPurchaseOrders: StockistPurchaseOrderList = [];
    days: string[] = []
    thDays: string[] = ['จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส', 'อา'];
    enDays: string[] = [
        'M',
        'T',
        'W',
        'Th',
        'F',
        'S',
        'Su',
    ]
    colors: string[] = [
        '#21C063',
        '#21C063',
        '#21C063',
        '#21C063',
        '#21C063',
        '#21C063',
        '#21C063',
    ];

    imagePath = '';
    loading_data = true;

    isLoading = true;
    stockistProductCount = 0;

    ngOnInit(): void {
        const isThai = this.translate.currentLang === 'th';
        this.days = isThai ? this.thDays : this.enDays
        this.getStockistInfo();
        this.getPurchaseOrders();
        this.getMonthlySummary();
        this.getStockistProductCount();
    }

    // getDateOrder(date: string): string {
    //     return APP_DATE_FORMATS_TH(date);
    // }

    getStockistInfo() {
        this.userService.getStockistProfile().subscribe({
            next: (res) => {
                this.stockistProfile = res;

                this.ImageurlToFile(this.stockistProfile.logoPath);
            },
            error: () => {
                this.userService.clearSession();
                this.router.navigate(['/auth']);

            }
        }
        );
    }

    getStockistProductCount() {
        this.productShelfService.getStockistProductCount().subscribe((res) => {
            this.stockistProductCount = res.stockistProduct;
        });
    }

    getPurchaseOrders() {
        if (this.stockistPurchaseOrders.length == 0) {
            this.showSpiner(true);
        }
        this.stockistService
            .getPurchaseOrders({
                limit: 3,
                page: 1,
                status: StockistOrderStatus.PENDING,
                orderBy: 'CreatedAt_desc',
            })
            .subscribe({
                next: (res) => {
                    this.pending = res.data;
                },
            });
        this.stockistService
            .getPurchaseOrders({
                limit: 3,
                page: 1,
                status: StockistOrderStatus.CANCELREQUESTED,
                orderBy: 'CreatedAt_desc',
            })
            .subscribe({
                next: (res) => {
                    this.cancelrequested = res.data;
                },
            });
        this.stockistService
            .getPurchaseOrders({
                limit: 3,
                page: 1,
                status: StockistOrderStatus.CONFIRMED,
                orderBy: 'CreatedAt_desc',
            })
            .subscribe({
                next: (res) => {
                    this.confirmed = res.data;
                },
            });
        this.getStatusTotalOrder();
    }

    getStatusTotalOrder() {
        this.stockistService.getStatusTotalOrder().subscribe((res) => {
            this.stockistOrderStatusTotal = res;
            this.showSpiner(false);
        });
    }

    getStatusLabel(status: string): string {
        switch (status.toUpperCase()) {
            case StockistOrderStatus.PENDING:
                return 'รอยืนยัน';
            case StockistOrderStatus.CONFIRMED:
                return 'ยืนยันแล้ว';
            case StockistOrderStatus.CANCELREQUESTED:
                return 'ขอยกเลิก';
            case StockistOrderStatus.CANCELLED:
                return 'ยกเลิก';
            case StockistOrderStatus.DELIVERED:
                return 'จัดส่งสำเร็จ';
            case StockistOrderStatus.DEPRECATED:
                return 'ยกเลิก';
            default:
                return 'Unknown status';
        }
    }

    // dayColor(arr: any[]) {
    //   case
    //   console.log("Array processed:", arr);
    // }

    isDayActive(day: string, officeDays: string | undefined): boolean {

        if (!officeDays) {
            return false;
        }
        let d = ''
        if (day === 'Su' || day === 'อา') {
            d = 'อา'
        }
        if (day === 'M' || day === 'จ') {
            d = 'จ'
        }
        if (day === 'T' || day === 'อ') {
            d = 'อ'
        }
        if (day === 'W' || day === 'พ') {
            d = 'พ'
        }
        if (day === 'Th' || day === 'พฤ') {
            d = 'พฤ'
        }
        if (day === 'F' || day === 'ศ') {
            d = 'ศ'
        }
        if (day === 'S' || day === 'ส') {
            d = 'ส'
        }
        return new Set(officeDays.split('|')).has(d);
    }

    navigateToOrder(order: IStockistPurchaseOrder) {
        window.localStorage.setItem('page', 'landing');
        this.router.navigate(['/stockist/order', order.id]);
    }

    async ImageurlToFile(filePath: string) {
        if (!filePath) return;
        if (filePath.includes('http')) {
            this.imagePath = filePath;
            return;
        }
        this.azureBlobService
            .getLogoImageStockists(filePath)
            .subscribe((res: File) => {
                const customFile: File = res as File;
                this.displayImage(customFile);
            });
    }

    displayImage(file: File) {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.result) {
                const arrayBuffer = reader.result as ArrayBuffer;
                const valueToAdd = arrayBuffer || '';
                this.imagePath = valueToAdd.toString();
            }
        };
        reader.readAsDataURL(file);
    }

    getMonthlySummary() {
        const now = new Date();
        const params: AnalyticParams = {
            month: now.getMonth() + 1,
            year: now.getFullYear(),
        };
        this.reportService.getMonthlySummary(params).subscribe({
            next: (res) => {
                this.dataSummary = res;
                this.isLoading = false;
            },
            error: (error) => {
                this.isLoading = false;
                console.error(error);
            },
        });
    }

    showSpiner(show: boolean) {
        this.loading_data = show;
        if (show) {
            this.spinner.show('spinner');
            return;
        }
        this.spinner.hide('spinner');
    }

}
