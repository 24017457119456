import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PaymentInfo } from "@core/models/shares/modal.model";
import { StockistPurchaseOrderPromotionDetail } from "@core/models/stockist/stockist.model";
import { StockistService } from "@core/services/stockist/purchase.service";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
    selector: 'app-stockist-order-info-cancel-requested-new',
    templateUrl: './order-info-cancel-requested-new.component.html',
    styleUrls: ['./order-info-cancel-requested-new.component.scss'],
})
export class OrderInfoCancelRequestedNewComponent implements OnChanges {

    @Input() stockistOrder!: StockistPurchaseOrderPromotionDetail;

    modalOpened = false;
    expandDetails = false;

    iconMap = 'assets/images/map.png';
    walletIcon = 'assets/images/walllet-icon.png'
    detailIcon = 'assets/images/detail-icon.png'
    additionalDetails: { label: string; value: string }[] = [];

    orderIsHavePromotionGift = false;
    isLoading = false;

    constructor(
        private activeRoute: ActivatedRoute,
        private stockistService: StockistService,
        private router: Router,
        private translate: TranslateService,
        private spinner: NgxSpinnerService
    ) {

    }
    // ngOnInit(){
    //     this.orderNumber = this.stockistOrder?.id || '';
    //     this.stockistOrder.promotions.map((pr) => {
    //         pr.promotionType === "Gift"? this.orderIsHavePromotionGift = true : ""
    //     })
    // }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["stockistOrder"] && changes["stockistOrder"].currentValue) {
            this.setAdditionalDetails();
        }
    }

    setAdditionalDetails() {
        this.additionalDetails = [
            { label: this.translate.instant('ORDER_INFO.CANCEL_REQUESTED.USER_NAME'), value: this.stockistOrder.retailerName },
            { label: this.translate.instant('ORDER_INFO.CANCEL_REQUESTED.TAX_ID'), value: this.stockistOrder.taxid },
            { label: this.translate.instant('ORDER_INFO.CANCEL_REQUESTED.PHONE_NUMBER'), value: this.stockistOrder.mobilePhoneNumber },
            { label: this.translate.instant('ORDER_INFO.CANCEL_REQUESTED.SECONDARY_NUMBER'), value: this.stockistOrder.secondaryPhoneNumber || "-" }
        ];
    }

    // getStockistOrderDetail() {
    //     this.stockistService.getPurchaseOrder(this.orderNumber).subscribe({
    //         next: (res) => {
    //             this.stockistOrder = res;

    //             this.additionalDetails = [
    //               { label: 'ชื่อผู้ซื้อ', value: this.stockistOrder.retailerName },
    //               { label: 'เลขประจำผู้เสียภาษีอากร', value: this.stockistOrder.taxid },
    //               { label: 'หมายเลขโทรศัพท์มือถือ', value: 'your@email.com' },
    //               { label: 'หมายเลขโทรศัพท์ร้านค้า'', value: this.stockistOrder.mobilePhoneNumber }
    //             ];
    //         },
    //         error: (e) => console.error(e),
    //     });
    // }

    getTotalQuantity(): number {
        if (this.stockistOrder && this.stockistOrder.items) {
            const totalQuantity = this.stockistOrder.items.reduce(
                (total, item) => total + item.quantity,
                0
            );

            return totalQuantity;
        }

        return 0;
    }

    getTotalAmount(): number {
        if (this.stockistOrder && this.stockistOrder.items) {
            const totalQuantity = this.stockistOrder.items.reduce(
                (total, item) => total + item.amount,
                0
            );

            return totalQuantity;
        }

        return 0;
    }

    getNetAmount(): number {
        if (!this.stockistOrder) return 0
        const netAmount = this.stockistOrder.netAmount;

        return netAmount;
    }

    getPaymentInfo(): PaymentInfo {
        return <PaymentInfo>{
            totalQuantity: this.getTotalQuantity(),
            totalAmount: this.getTotalAmount(),
            couponDiscount: this.stockistOrder?.couponDiscount,
            promotionDiscount: this.stockistOrder?.promotionDiscount,
            shippingCost: this.stockistOrder?.shippingCost,
            netAmount: this.getNetAmount(),
        };
    }

    approveCancelOrder() {
        this.isLoading = true;
        this.spinner.show('spinner');
        this.stockistService.approveCancelOrder(this.stockistOrder.id).subscribe({
            next: () => {
                this.navigateToCancellationSuccess();
                this.isLoading = false;
                this.spinner.hide('spinner');
            },
            error: (err) => {
                console.error('Error approving order cancellation', err);
                this.isLoading = false;
                this.spinner.hide('spinner');
            },
        });
    }

    rejectCancelOrder() {
        this.isLoading = true;
        this.spinner.show('spinner');
        this.stockistService.rejectCancelOrder(this.stockistOrder.id).subscribe({
            next: () => {
                this.navigateToCancelReject()
                this.isLoading = false;
                this.spinner.hide('spinner');
            },
            error: (err) => {
                console.error('Error reject order cancellation', err);
                this.isLoading = false;
                this.spinner.hide('spinner');
            },
        });
    }

    navigateToCancellationSuccess() {
        this.router.navigate([
            `stockist/order/${this.stockistOrder.id}/processed/cancelled`
        ]);
    }

    navigateToCancelReject() {
        this.router.navigate([
            `/stockist/order/${this.stockistOrder.id}/processed/reject-cancellation`]);
    }

    goBack(): void {
      const page = window.localStorage.getItem('page');
      if (page) {
        if (page === 'landing') {
          this.router.navigate(['/stockist/orders']);
        } else if (page === 'history') {
          this.router.navigate(['/stockist/order-history']);
        } else if (page === 'notification') {
          this.router.navigate(['/stockist/my-notification']);
        } else if (page === 'oldOrder') {
          window.history.back();
          setTimeout(() => {
              window.location.reload()
          },100)
        }
      } else {
        window.history.back();
        setTimeout(() => {
            window.location.reload()
        },100)
      }

    }

    navigateToOldOrder() {
        window.localStorage.setItem('page', 'oldOrder');
        this.router.navigateByUrl(`stockist/order/${this.stockistOrder.refOrderId}`).then(() => window.location.reload())
    }
}
