<div class="px-3 py-4">
    <p>ยกเลิก</p>
    <div class="d-flex justify-content-center align-items-center w-100" style="margin-top: -2rem;">
        <h1>เพิ่มโปรโมชัน</h1>
    </div>
    <div class="d-flex justify-content-between align-items-center mt-3">
        <app-header-word [currentStep]="step" [step]="0" [word]="'รายละเอียด'" />
        <app-header-word [currentStep]="step" [step]="1" [word]="'เงื่อนไขโปรโมชั่น'" />
        <app-header-word [currentStep]="step" [step]="2" [word]="'สิทธิประโยชน์'" />
        <!-- <div class="row justify-content-center align-items-center">
            <p [ngClass]="step >= 0 ? 'current-progress': 'upcoming-progress'" class="text-center"
                style="background-color: transparent !important;">รายละเอียด</p>
            <div [ngClass]="step >= 0 ? 'current-progress': 'upcoming-progress'" class="progress-bar-border"></div>
        </div>
        <div class="row justify-content-center align-items-center">
            <p [ngClass]="step >=1 ? 'current-progress': 'upcoming-progress'" class="text-center"
                style="background-color: transparent !important;">เงื่อนไขโปรโมชั่น</p>
            <div [ngClass]="step >=1 ? 'current-progress': 'upcoming-progress'" class="progress-bar-border"></div>
        </div>
        <div class="row justify-content-center align-items-center">
            <p [ngClass]="step >=2 ? 'current-progress': 'upcoming-progress'" class="text-center"
                style="background-color: transparent !important;">สิทธิประโยชน์</p>
            <div [ngClass]="step >=2 ? 'current-progress': 'upcoming-progress'" class="progress-bar-border"></div>
        </div> -->
    </div>

</div>