<div *ngIf="orderDetail">
    <app-stockist-order-info-pending [stockistOrder]="orderDetail" *ngIf="orderDetail.status === 'Pending'" />
    <app-stockist-order-info-cancel-requested [stockistOrder]="orderDetail"
        *ngIf="orderDetail.status === 'CancelRequested'" />
    <app-stockist-order-info-cancelled [stockistOrder]="orderDetail"
        *ngIf="orderDetail.status === 'Cancelled' || orderDetail.status === 'Deprecated'" />
    <app-stockist-order-info-confirmed [stockistOrder]="orderDetail" *ngIf="orderDetail.status === 'Confirmed'" />
    <app-stockist-order-info-delivered [stockistOrder]="orderDetail" *ngIf="orderDetail.status === 'Delivered'" />

    <!-- <app-stockist-order-info-deprecated [stockistOrder]="orderDetail" *ngIf="orderDetail.status === 'Deprecated'" /> -->
    <!-- <app-stockist-order-info-confirmed-message *ngIf="orderDetail.status === 'CancelRequested'"/>-->
</div>

<div *ngIf="orderDetailv2">
  <app-stockist-order-info-pending-new [stockistOrder]="orderDetailv2" *ngIf="orderDetailv2.status === 'Pending'" />
  <app-stockist-order-info-cancel-requested-new [stockistOrder]="orderDetailv2"
        *ngIf="orderDetailv2.status === 'CancelRequested'" />
  <app-stockist-order-info-cancelled-new [stockistOrder]="orderDetailv2"
        *ngIf="orderDetailv2.status === 'Cancelled' || orderDetailv2.status === 'Deprecated'" />
  <app-stockist-order-info-confirmed-new [stockistOrder]="orderDetailv2" *ngIf="orderDetailv2.status === 'Confirmed'" />
  <app-stockist-order-info-delivered-new [stockistOrder]="orderDetailv2" *ngIf="orderDetailv2.status === 'Delivered'" />
</div>
