import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'displayDeliveryDate',
})
export class DisplayDateDelivedPipe implements PipeTransform {
    constructor( private translate: TranslateService) {}
    transform(value: string, { isDateOnly = false } = {}): string {

        if (!this.translate) {
          return '';
        }
        const local = this.translate.currentLang;
        const datePipe = new DatePipe(local, 'GMT+7');
        let formattedDate: string | null;

        try {
            formattedDate = datePipe.transform(
                value,
                'E dd MMM yy HH:mm',
                'GMT+7'
            );
        } catch {
            return '';
        }

        if (!formattedDate) {
            return '';
        }

        const thaiDays = [
            'อา',
            'จ',
            'อ',
            'พ',
            'พฤ',
            'ศ',
            'ส'
        ];

        const thaiMonths = [
            'ม.ค.',
            'ก.พ.',
            'มี.ค.',
            'เม.ย.',
            'พ.ค.',
            'มิ.ย.',
            'ก.ค.',
            'ส.ค.',
            'ก.ย.',
            'ต.ค.',
            'พ.ย.',
            'ธ.ค.',
        ];

        const EngDays = [
          'Sun',
          'Mon',
          'Tue',
          'Wed',
          'Thu',
          'Fri',
          'Sat',
        ]

        const EngMonths = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        const isThai = this.translate.currentLang === 'th';
        const parts = formattedDate.split(' ');
        const day = new Date(value);
        const dayOfWeek = day.getDay();
        const DayName = isThai ? thaiDays[dayOfWeek] : EngDays[dayOfWeek];
        const thaiYear = isThai ? (+parts[3] + 43).toString() : (+parts[3]).toString();
        const thaiMonth = isThai ? thaiMonths[new Date(value).getMonth()] : EngMonths[new Date(value).getMonth()];
        const date = DayName + ' ' + parts[1] + ' ' + thaiMonth + ' ' + thaiYear;

        return isDateOnly ? date : date;
    }
}
