/* eslint-disable @typescript-eslint/no-explicit-any */
import { ValidationErrors } from '@angular/forms';

export class ErrorMessage {
  getErrorMessage(error: ValidationErrors | any): string {
    const errorKey = Object.keys(error)[0];
    switch (errorKey) {
      case 'required':
        return 'VALIDATOR.REQUIRED';
      case 'minlength':
        return 'VALIDATOR.MIN_LENGTH' + error.minlength.requiredLength + 'VALIDATOR.LENGTH_VAR';
      case 'maxlength':
        return 'VALIDATOR.MAX_LENGTH' + error.maxlength.requiredLength + 'VALIDATOR.LENGTH_VAR';
      case 'email':
        return 'VALIDATOR.EMAIL';
      case 'mustMatch':
        return 'VALIDATOR.MUST_MATCH';
      case 'pattern':
        return 'VALIDATOR.PATTERN';
      default:
        return this.getServerErrorMessage(error);
    }
  }

  getServerErrorMessage(value: any) {
    if (Array.isArray(value)) {
      const error = this.getErrorMessage(value[0]);
      if (!Array.isArray(error)) {
        return error;
      }
    } else {
      return value;
    }
  }
}
