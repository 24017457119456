import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, map } from 'rxjs';

@Injectable({
providedIn: 'root'
})
export class AzureBlobService {

    constructor(private http: HttpClient) { }

    getImageThumbnail(fileName: string, imageId: number, retailerId: number): Observable<File> {
        return this.http.get(`${environment.apiUrl}/customer/retailers/${retailerId}/preview/img/${imageId}/thumbnail`, {
            responseType: 'arraybuffer'
        }).pipe(
            map((response: ArrayBuffer) => {
                const fileNameParts: string[] = fileName.split('.');
                let fileType = ''
                if (fileNameParts[1] === 'png') {
                    fileType = 'image/png'
                } else if (fileNameParts[1] === 'jpg' || fileNameParts[1] === 'jpeg') {
                    fileType = 'image/jpg'
                }

                const blob = new Blob([response]);
                return new File([blob], fileName, { type: fileType });
            })
        );
    }

    getImagePreview(fileName: string, imageId: number, retailerId: number): Observable<File> {
        return this.http.get(`${environment.apiUrl}/customer/retailers/${retailerId}/preview/img/${imageId}/preview`, {
            responseType: 'arraybuffer'
        }).pipe(
            map((response: ArrayBuffer) => {
                const fileNameParts: string[] = fileName.split('.');
                let fileType = ''
                if (fileNameParts[1] === 'png') {
                    fileType = 'image/png'
                } else if (fileNameParts[1] === 'jpg' || fileNameParts[1] === 'jpeg') {
                    fileType = 'image/jpg'
                }

                const blob = new Blob([response]);
                return new File([blob], fileName, { type: fileType });
            })
        );
    }

    getImageThumbnailStockist(fileName: string, imageId: number, stockistId: number): Observable<File> {
        return this.http.get(`${environment.apiUrl}/customer/stockists/${stockistId}/preview/img/${imageId}/thumbnail`, {
            responseType: 'arraybuffer'
        }).pipe(
            map((response: ArrayBuffer) => {
                const fileNameParts: string[] = fileName.split('.');
                let fileType = ''
                if (fileNameParts[1] === 'png') {
                    fileType = 'image/png'
                } else if (fileNameParts[1] === 'jpg' || fileNameParts[1] === 'jpeg') {
                    fileType = 'image/jpg'
                }

                const blob = new Blob([response]);
                return new File([blob], fileName, { type: fileType });
            })
        );
    }

    getImagePreviewStockist(fileName: string, imageId: number, stockistId: number): Observable<File> {
        return this.http.get(`${environment.apiUrl}/customer/stockists/${stockistId}/preview/img/${imageId}/preview`, {
            responseType: 'arraybuffer'
        }).pipe(
            map((response: ArrayBuffer) => {
                const fileNameParts: string[] = fileName.split('.');
                let fileType = ''
                if (fileNameParts[1] === 'png') {
                    fileType = 'image/png'
                } else if (fileNameParts[1] === 'jpg' || fileNameParts[1] === 'jpeg') {
                    fileType = 'image/jpg'
                }

                const blob = new Blob([response]);
                return new File([blob], fileName, { type: fileType });
            })
        );
    }

    getLogoImageStockists(filePath:string) {
        return this.http.post(`${environment.apiAdminUrl}/stockists/original/img/logo`, { filePath }, {responseType: 'arraybuffer'}).pipe(
            map((response) => {
                const fileNameParts: string[] = filePath.split('.');
                let fileType = ''
                if (fileNameParts[1] === 'png') {
                    fileType = 'image/png'
                } else if (fileNameParts[1] === 'jpg' || fileNameParts[1] === 'jpeg') {
                    fileType = 'image/jpg'
                }

                const blob = new Blob([response]);
                return new File([blob], filePath, { type: fileType });
            })
        );
    }


}
