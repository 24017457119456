import { Component, Input, OnInit } from '@angular/core';
import { IOrderItemPromotionDetail } from '@core/models/stockist/order.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-show-product-promotion-list',
  templateUrl: './show-product-promotion-list.component.html',
  styleUrls: ['./show-product-promotion-list.component.scss'],
})
export class ShowProductPromotionListComponent implements OnInit {
  @Input() products: IOrderItemPromotionDetail[] = [];
  currentLang = 'th'
  imagePromotion = ''

  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    // this.products.forEach(element => {
    //   this.checkHavePromotion(element)
    // });
    this.currentLang = this.translate.currentLang
  }

  // checkHavePromotion(item: IOrderItemPromotionDetail) {
    // if (item.type === 'Bundle' && item.privilegeType === 'Discount') {
    //   item.imagePromotion = 'assets/icons/bundle_discount.png'
    // } else if (item.type === 'Bundle' && item.privilegeType === 'Gift') {
    //   item.imagePromotion = 'assets/icons/bundle_gift.png'
    // } else if (item.type === 'Tier' && item.privilegeType === 'Discount') {
    //   item.imagePromotion = 'assets/icons/tier_discount.png'
    // } else if (item.type === 'Tier' && item.privilegeType === 'Gift') {
    //   item.imagePromotion = 'assets/icons/tier_gift.png'
    // } else {
    //   item.imagePromotion = ''
    // }
  // }

  getFormattedCurrency(amount: number | undefined) {
    return amount ? `${amount.toFixed(2)}฿`: ''
  }
}
