import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrdersComponent } from './components/orders/orders.component';
import { OrderInfoConfirmedMessageComponent } from './components/order-info-confirmed-message/order-info-confirmed-message.component';
import { SuccessfulOrderCancellationComponent } from './components/successful-order-cancellation/successful-order-cancellation.component';
import { CancellationComponent } from './components/cancellation/cancellation.component';
import { ViewAllOrdersComponent } from './components/view-all-orders/view-all-orders.component';
import { OrderHistoryComponent } from './components/order-history/order-history.component';
import { CancelRejectComponent } from './components/cancel-reject/cancel-reject.component';
import { OrderProcessedCancelledComponent } from './components/order-processed-cancelled/order-processed-cancelled.component';
import { OrderProcessedConfirmedComponent } from './components/order-processed-confirmed/order-processed-confirmed.component';
import { OrderInfoMainComponent } from './components/order-info-main/order-info-main.component';
import { OrderProcessedRejectCancelRequestComponent } from './components/order-processed-reject-cancel-request/order-processed-reject-cancel-request.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MyNotificationComponent } from './components/my-notification/my-notification.component';
import { ProfileComponent } from './components/profile/profile.component';
import { LanguagePageComponent } from './components/language-page/language-page.component';
import { ProductShelfListComponent } from './components/product-shelf-list/product-shelf-list.component';
import { ConfigService } from '@core/services/shared/config.service';
import { OrderProcessedCancelledNewComponent } from './components/order-processed-cancelled-new/order-processed-cancelled-new.component';
import { OrderProcessedRejectCancelRequestNewComponent } from './components/order-processed-reject-cancel-request-new/order-processed-reject-cancel-request-new.component';
import { OrderProcessedConfirmedNewComponent } from './components/order-processed-confirmed-new/order-processed-confirmed-new.component';

const configService = new ConfigService();
const appVersion = configService.getAppVersion();
import { ConfirmOrderNewComponent } from './components/confirm-order-new/confirm-order-new.component';
import { PromotionListComponent } from './components/promotion-list/promotion-list.component';
import { AddPromotionMainFormComponent } from './components/promotion/add-promotion-main-form/add-promotion-main-form.component';

const routes: Routes = [
    {
        path: 'order-confirmed/:order-number',
        component: OrderInfoConfirmedMessageComponent,
    },
    {
        path: 'confirm-order/:order-number',
        component: ConfirmOrderNewComponent,
    },
    {
        path: 'orders',
        component: OrdersComponent,
    },
    {
        path: 'order-history',
        component: OrderHistoryComponent,
    },
    {
        path: 'successful-order-cancellation/:order-number',
        component: SuccessfulOrderCancellationComponent,
    },
    {
        path: 'cancellation',
        component: CancellationComponent,
    },
    {
        path: 'view-all-orders',
        component: ViewAllOrdersComponent,
    },
    {
        path: 'order/:order-number',
        component: OrderInfoMainComponent,
    },
    {
        path: 'orders/:order-number/reject-cancellation',
        component: CancelRejectComponent,
    },
    {
        path: 'order/:order-number/processed/confirmed',
        component: appVersion === 'v1' ? OrderProcessedConfirmedComponent : OrderProcessedConfirmedNewComponent,
    },
    {
        path: 'order/:order-number/processed/cancelled',
        component: appVersion === 'v1' ? OrderProcessedCancelledComponent : OrderProcessedCancelledNewComponent,
    },
    {
        path: 'order/:order-number/processed/reject-cancellation',
        component: appVersion === 'v1' ? OrderProcessedRejectCancelRequestComponent : OrderProcessedRejectCancelRequestNewComponent,
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
    },
    {
        path: 'my-notification',
        component: MyNotificationComponent,
    },
    {
        path: 'profile',
        component: ProfileComponent,
    },
    {
        path: 'language',
        component: LanguagePageComponent,
    },
    {
        path: 'product-shelf',
        component: ProductShelfListComponent,
    },
    {
        path: 'promotion',
        component: PromotionListComponent
    },
    {
        path: 'promotion/create',
        component: AddPromotionMainFormComponent
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'orders',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class StockistRoutingModule { }
